import React, { Fragment } from "react";

export default function DiscardButton({onClick}) {
  return (
    <Fragment>
      <button className="flex justify-center items-center rounded-lg px-3 py-2 shadow hover:bg-gray-100 bg-white text-sm text-gray-900 border border-gray-300 font-semibold gap-2" onClick={(e) => onClick(e)}>
        Discard Changes
      </button>
    </Fragment>
  )
}
import React, { Fragment, useState } from 'react';
import multiMonthPlugin from '@fullcalendar/multimonth'
import FullCalendar from '@fullcalendar/react';
import YearViewEventComponent from './yearViewEventComponent';
import DayEventsPopup from './dayEventsPopup';
import interactionPlugin from '@fullcalendar/interaction';

const currentYear = new Date().getFullYear();

export default function YearViewCalendarComponent(props){
  const { events, calendarRef, currentDate, handleEventClick, hanldeClickOnErrorEvent, selectedInstructors, selectedClasses, selectedLocations, statusFilter, path, myRequestsTab, sentStatuses, availableStatuses, selectedSkills } = props;
  const [ clickDate, setClickDate ] = useState(null)
  const [ showEventsPopup, setShowEventsPopup ] = useState(false)

  function getAbbreviatedWeekDay(arg) {
    const weekDayAbbreviations = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    return weekDayAbbreviations[arg.date.getDay()];
  }

  function formatDayNumber(arg) {
    const dayNumberText = arg.dayNumberText.toString().padStart(2, '0');
    return `${dayNumberText}`;
  }

  const closeEventsPopup = (data) => {
    setClickDate(null)
    setShowEventsPopup(data)
  }

  const handleDateClick = (info) => {
    setClickDate(info.date)
    setShowEventsPopup(true)
  }

  return(
    <Fragment>
      {showEventsPopup &&
        <DayEventsPopup 
          clickDate={clickDate} 
          showEventsPopup={showEventsPopup} 
          closeEventsPopup={closeEventsPopup}
          hanldeClickOnErrorEvent={hanldeClickOnErrorEvent}
          selectedInstructors={selectedInstructors}
          selectedClasses={selectedClasses}
          selectedLocations={selectedLocations}
          statusFilter={statusFilter}
          path={path}
          myRequestsTab={myRequestsTab}
          sentStatuses={sentStatuses}
          availableStatuses={availableStatuses}
          selectedSkills={selectedSkills}
        />
      }
      <FullCalendar
        ref={calendarRef}
        height = {'auto'}
        plugins={[interactionPlugin, multiMonthPlugin]}
        initialView="multiMonthYear"
        initialDate={currentDate}
        events={events}
        eventContent={YearViewEventComponent}
        eventTimeFormat= { {
          hour12: true,
          hour: 'numeric',
          minute: 'numeric',
          meridiem: false
        } }
        fixedWeekCount={false}
        dayHeaderContent= {(arg) => {
          return getAbbreviatedWeekDay(arg);
        }}
        firstDay={1}
        showNonCurrentDates={true}
        dayCellContent={(arg) => {
          return formatDayNumber(arg);
        }}
        multiMonthTitleFormat={{
          month: 'long', year: 'numeric'
        }}
        multiMonthMaxColumns={4}
        dateClick={(info) => {handleDateClick(info)}}
      />
    </Fragment>
  )
}
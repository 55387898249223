import React from "react";

export default function ViewButtonIcon({classNames}) {

  return(
    <svg className={classNames} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_160_22675)">
        <path d="M0.583496 7.00016C0.583496 7.00016 2.91683 2.3335 7.00016 2.3335C11.0835 2.3335 13.4168 7.00016 13.4168 7.00016C13.4168 7.00016 11.0835 11.6668 7.00016 11.6668C2.91683 11.6668 0.583496 7.00016 0.583496 7.00016Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.00016 8.75016C7.96666 8.75016 8.75016 7.96666 8.75016 7.00016C8.75016 6.03366 7.96666 5.25016 7.00016 5.25016C6.03366 5.25016 5.25016 6.03366 5.25016 7.00016C5.25016 7.96666 6.03366 8.75016 7.00016 8.75016Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_160_22675">
          <rect width="14" height="14" fill="white" transform="translate(0.000244141)"/>
        </clipPath>
      </defs>
    </svg>

  )
}
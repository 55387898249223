import React, { Fragment, useState } from 'react';
import CustomPopupComponent from './../customPopupComponent/customPopupComponent';
import { axiosRequest } from './../../../helpers/helpers'

export default function ReOpenEventComponent(props) {
  const { eventId, approvedUser, showReopenPopup, closeReopenPopup, user, handleSetAlert, processFetchEventData, type, sentBucket, calendarEvent, handleUpdateCalendarEvent, fetchInviteMorePopupData } = props
  const [ disableContinueBtn, setDisableContinueBtn ] = useState(false)
  const handleContinueReopen = () => {
    setDisableContinueBtn(true)
    let params = {
      inst_id: approvedUser?.id,
      reason: '1',
      preview: type !== 'detail',
      bucket_type: type !== 'detail' ? sentBucket : '',
      calendar_event: calendarEvent
    }
    let url = ''
    if(type == 'detail'){
      url = `/api/v3/business_events/${eventId}/perform_more_actions`;
      params = { ...params, key: 'reopen_class' }
    }else{
      url = '/api/v2/reopen_sub_request'
      params = {
        ...params,
        event_id: eventId,
        gql_event_details: true,
      }
    }
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if((type == 'detail' && result?.status == 200) || !Object.keys(result).includes('error')){
        setDisableContinueBtn(false);
        handleSetAlert(true, result?.success, 'success');
        if(calendarEvent) handleUpdateCalendarEvent(result?.calendar_event, 'updated');
        if(result?.invite_more_display){
          fetchInviteMorePopupData(result)
        }else{
          processFetchEventData(result?.event);
        }
        closeReopenPopup(false);
      }else{
        setDisableContinueBtn(false);
        handleSetAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleSetAlert(false, '', ''); 
      }, 3000)
    })
  }

  const requestingInstructorName = () => {
		return user?.id == approvedUser?.id ? 'You' : approvedUser?.full_name
	}

  return(
    <Fragment>
      <CustomPopupComponent show={showReopenPopup} close={closeReopenPopup} title={'Reopen Sub Request'}>
        <div className="px-4 pt-3 font-open-sans">
          <div className="">
            <Fragment>
              <div className='text-sm px-2 text-gray-700 mt-3'>
                <p>{`Are you sure you want to reopen this sub request? By confirming, ${requestingInstructorName()} will now be the requesting instructor.`}</p>
                <ul className="list-disc pl-6">
                  <li>All instructors who accepted previously will be moved to 'No Response' state. We want to ensure they are still free to sub.</li>
                  <li>Instructors who did not respond initially will be notified again.</li>
                  <li>Instructors who declined will not receive another notification.</li>
                  <li>To invite more people, head to the not yet invited section.</li>
                </ul>
              </div>
            </Fragment>
          </div>
          <div className="mt-5 flex gap-3 flex-row-reverse">
            <button 
              className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-32 py-2 text-sm ${disableContinueBtn ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleContinueReopen()}
              disabled={disableContinueBtn}
            >
              Confirm
            </button>
            <button 
              className={`bg-white border rounded-xl text-gray-800 flex items-center justify-center w-32 py-2 text-sm hover:bg-gray-100 shadow`}
              onClick={() => closeReopenPopup(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomPopupComponent>
    </Fragment>
  )
}
import React, { Fragment, useState } from "react";
import { UserIcon } from './../../../../subRequests/assets';
import { axiosRequest } from './../../../../subRequests/helpers/helpers';

export default function SendInviteButtonComponent(props){
  const { sub, eventData, handleAlert, handleUpdateEvent, type } = props
  const [ disableInviteButton, setDisableInviteButton ] = useState(false)

  const handleSendInviteToUser = (userId) => {
    let invited_instructors = userId ? [userId] : []
    if(invited_instructors.length === 0){
      return
    }
    setDisableInviteButton(true)
    let params = {
      id: eventData?.id,
      invited_instructors: invited_instructors,
      gql_event_details: true, 
      preview: false
    }
    let url = `/api/v2/invite_more_instructors`
    axiosRequest(url, 'POST', params, 'data').then((res) => {
      if(Object.keys(res).includes('status') && res?.status === 200){
        handleAlert(true, 'Boom! Your sub request has been sent', 'success');
      }else{
        handleAlert(true, res?.error, 'danger');
      }
      setDisableInviteButton(false)
      setTimeout(() => {
        handleAlert(false, '', ''); 
        handleUpdateEvent(res?.event);
      }, 3000)
    })
  }

  return(
    <Fragment>
      <button className={`hidden lg:flex items-center px-3 py-2 rounded-2xl text-sm font-semibold h-9 gap-2 ${type == 'unavilable' ? 'border-neutral-500-custom text-neutral-500' : 'border-1-c pending-text-color'} ${(disableInviteButton || eventData?.deleted) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleSendInviteToUser(sub?.id)} disabled={disableInviteButton || eventData?.deleted}>
        <UserIcon classNames={`${type == 'unavilable' ? 'text-neutral-500' : 'pending-text-color'} w-4 h-4`}/>
        Send invite
      </button>
      <button className={`flex lg:hidden items-center justify-center rounded-full h-6 w-6 gap-2 ${type == 'unavilable' ? 'border-neutral-500-custom text-neutral-500' : 'border-1-c pending-text-color'} ${(disableInviteButton || eventData?.deleted) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleSendInviteToUser(sub?.id)} disabled={disableInviteButton || eventData?.deleted}>
        <UserIcon classNames={`${type == 'unavilable' ? 'text-neutral-500' : 'pending-text-color'} w-3.5 h-3.5`}/>
      </button>
    </Fragment>
  )
}
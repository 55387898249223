import React, { Fragment } from "react";
import ActionButton from "./tableActionButton";
import { ApproveButtonIcon } from '../../../subRequests/assets';
import Pagination from "react-js-pagination";
import EmptyCategories from "./emptyCategories";

export default function CategoryTable(props) {
  const { user, permissions, categories, dataLoaded, pageSize, page, totalCount, setPageNumber, handleDeleteCategory, handleEditCategory } = props

  return(
    <Fragment>
      <div className='overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4'>
        <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
          {!dataLoaded &&
            <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
              <img className = "w-9" src="/assets/business/loading.gif" />
            </div>
          }
          {dataLoaded &&
            <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    <th scope="col" className="hidden lg:table-cell pl-4 pr-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10">#</th>
                    <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                    <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider  md:w-52">Expiration Date Required</th>
                    <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10"></th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {categories.map((category, i) => 
                    <tr key={i} className='bg-white'>
                      <td className='hidden lg:table-cell pl-4 pr-3 py-4 text-sm font-medium text-gray-900'>
                        <div className='text-sm text-gray-500'>{i + 1}</div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm font-medium text-gray-900'>{category.name}</div>
                      </td>
                      <td className='px-3 py-4'>
                        <div className='text-sm font-medium text-gray-900'>
                          {category?.expiration_date_required ?
                            <ApproveButtonIcon classNames={`w-5 h-5 text-gray-900`}/>
                            :
                            ''
                          }
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                        {(permissions?.documentations?.write || user?.role_display === "Netgym Employee") &&
                          <div className="justify-end">
                            <div className='flex gap-3 items-center'>
                              <ActionButton setAction={handleEditCategory} data={category} type={'edit'} tooltipId={`edit-category-${i}-${category?.id}`} tooltipMessage={`Edit category`} disabledButton={false} pageType={'categories'}/>
                              <ActionButton setAction={handleDeleteCategory} data={category} type={'delete'} tooltipId={`delete-category-${i}-${category?.id}`} tooltipMessage={`Delete category`} disabledButton={false} pageType={'categories'}/>
                            </div>
                          </div>
                        }
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {categories.length == 0 &&
                <EmptyCategories type={'categories'}/>
              }
            </div>
          }
        </div>
      </div>
      {totalCount > pageSize &&
        <div className="flex justify-center w-full flex-wrap mt-4 pb-2">
          <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0 mr-7 list-view-pagination">
            <Pagination
              activePage={page}
              itemsCountPerPage={pageSize}
              totalItemsCount={totalCount}
              pageRangeDisplayed={9}
              onChange={setPageNumber}
              itemClassPrev='previous'
              itemClassNext='next'
              itemClassFirst='first'
              itemClassLast='last'
              hideDisabled={true}
              activeClass={'pagination-active-tab'}
            />
          </div>
        </div>
      }
    </Fragment>
  )
}
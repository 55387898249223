import React, { Fragment, useEffect, useState } from "react";
import { InfoIcon } from './../../../assets';
import { Switch } from '@headlessui/react'
import ReactTooltip from "react-tooltip";
import { axiosRequest } from './../../../../subRequests/helpers/helpers';

export default function PublicCommentToggleComponent(props){
  const { eventData, handleSetAlert, handleUpdateEvents } = props
  const [ disableSwitch, setDisableSwitch ] = useState(false)
  const [ showPrivateComments, setShowPrivateComments ] = useState(eventData?.show_private_comments)

  const handleSwitch = (e, name) => {
    setShowPrivateComments(e)
    handleProcessSwitch(e)
  }

  const handleProcessSwitch = (val) => {
    setDisableSwitch(true)
    let params = {
      show_private_comments: val,
      preview: false
    }
    let url = `/api/v3/business_events/${eventData?.id}/update_show_private_comments`
    axiosRequest(url, 'PATCH', params, 'data').then(result => {
      if(result.status == 200){
        handleSetAlert(true, result?.success, 'success')
        handleUpdateEvents(result?.event)
      }else{
        setShowPrivateComments(!val)
        handleSetAlert(true, result?.error, 'danger')
      }
      setDisableSwitch(false)
      window.setTimeout(() => {
        handleSetAlert(false, '', '')
      }, 3000)
    })

  };

  return(
    <Fragment>
      <div className="flex items-center gap-1.5">
        <Switch
          id={`show_private_comments-1`}
          checked={showPrivateComments}
          name="show_private_comments"
          onChange={(e) => handleSwitch (e, "show_private_comments")}
          className={`${showPrivateComments ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${(disableSwitch || eventData?.deleted) ? 'cursor-not-allowed opacity-50' : ''}`}
          disabled={disableSwitch || eventData?.deleted}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${showPrivateComments ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
          />
        </Switch>
        <div data-tip data-for={eventData?.id?.toString()+'public_comment_toggle'}>
          <InfoIcon classNames={'h-4 w-4'} customColour={''}/>
        </div>
        <ReactTooltip className='max-w-xs' id={eventData.id.toString()+'public_comment_toggle'} place="top" effect="solid">
          {showPrivateComments ? 'Show: Selecting show will keep comments on the manager board public. Once approved, these comments will display on the sub request publicly.' : 'Hide: Selecting hide will keep comments on the manager board private. Once approved, these comments will no longer display. Select this option for personal commentary.'}
        </ReactTooltip>
      </div>
    </Fragment>
  )
}
import React, { Fragment, useState, useEffect } from "react";
import AlertPopup from '../../../../messenger/pages/chatComponents/assets/alertPopup'
import { saveSubRequestInLocalStorage, setUrlByPageNo } from './../../../helpers';
import { useParams, Redirect } from 'react-router-dom';
import { axiosRequest } from '../../../../subRequests/helpers/helpers';

export default function NextStepButtonComponent(props) {
  const { user, business, integratedBusiness, newSubRequest, setStep, permissions } = props
  const { id } = useParams();
  const [ nextButtonDisabled, setNextButtonDisabled ] = useState(false)
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertType, setAlertType ] = useState('')
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ redirect, setRedirect ] = useState(false)
  const [ redirectUrl, setRedirectUrl ] = useState('')

  const checkButtonDisabled = () => {
    if(!user?.nysc_gym && !business?.request_reason_optional){
      if(newSubRequest?.reason?.reason == ''){
        setNextButtonDisabled(true)
      }else if(newSubRequest?.reason?.otherReason == '' && newSubRequest?.reason?.reason == 'Other'){
        setNextButtonDisabled(true)
      }else{
        setNextButtonDisabled(false)
      }
    } else if(!integratedBusiness){
      if(user?.role_display == 'Netgym Employee' && user?.id == newSubRequest?.requestingInstructor?.id){
        setNextButtonDisabled(true)
        return
      }
      if(newSubRequest?.schedules?.some(s => s?.business_class_id == '' || s?.location_id == '' || s?.start_time == '' || s?.end_time == '' || (s?.start_time !== '' && s?.blockBeforeHours > 0 && s?.minDate !== '' && (new Date(s?.start_time.substring(0, 19))) <= s?.minDate)) || newSubRequest?.schedules?.length == 0) {
        setNextButtonDisabled(true)
      } else {
        setNextButtonDisabled(false)
      }
    }else{
      setNextButtonDisabled(false)
    }
  }

  const checkTheNextButtonDisabled = () => {
    if(nextButtonDisabled) return true
    if(newSubRequest?.requestingInstructor?.id == '') return true
    if(!user?.nysc_gym && !business?.request_reason_optional){
      if(newSubRequest?.reason?.reason == ''){
        return true
      }else if(newSubRequest?.reason?.otherReason == '' && newSubRequest?.reason?.reason == 'Other'){
        return true
      }
    }
    if(!integratedBusiness){
      if(user?.role_display == 'Netgym Employee' && user?.id == newSubRequest?.requestingInstructor?.id){
        return true
      }
      if(newSubRequest?.schedules?.some(s => s?.business_class_id == '' || s?.location_id == '' || s?.start_time == '' || s?.end_time == '' || (s?.start_time !== '' && s?.blockBeforeHours > 0 && s?.minDate !== '' && (new Date(s?.start_time.substring(0, 19))) <= s?.minDate) || (user?.role_display == 'Fitness Instructor' && s?.blackoutDates?.length > 0 && s?.blackoutDates?.some(d => {let dDate = new Date(d); let sDate = new Date(s?.start_time); return (dDate.getFullYear() == sDate.getFullYear() && dDate.getMonth() == sDate.getMonth() && dDate.getDate() == sDate.getDate())}))) || newSubRequest?.schedules?.length == 0) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    // checkButtonDisabled()
  }, [newSubRequest])

  const handleClickOnNext = () => {
    if(newSubRequest?.schedules?.length == 0){
      setAlertType('danger')
      setAlertMessage('Please select atleast one class.')
      setShowAlert(true)
      window.setTimeout(() => {
        setShowAlert(false)
        setAlertType('')
        setAlertMessage('')
      }, 5000)
      return
    }
    if(!integratedBusiness){
      let currentDate = new Date();
      let today = currentDate.setHours(0,0,0,0);
      let firstSchedule = newSubRequest?.schedules[0]
      let requestDate = new Date(firstSchedule.date);
      let requestDay = requestDate.setHours(0,0,0,0);
      let requestTime = new Date(firstSchedule.start_time)
      let startDate = user?.start_date
      let startTime = user?.start_time
      let formatedRequestDate = `${(requestDate.getMonth() + 1).toString().padStart(2, '0')}/${requestDate.getDate().toString().padStart(2, '0')}/${requestDate.getFullYear()}`
      let formatedRequestTime = `${requestTime.getHours().toString().padStart(2, '0')}:${requestTime.getMinutes().toString().padStart(2, '0')} ${requestTime.getHours() < 12 ? 'AM' : 'PM'}`
      let CheckEndTime = newSubRequest?.schedules.some(item => {return (new Date(item?.start_time.substring(0, 19)) >= new Date(item?.end_time.substring(0, 19)))})
      if(user?.role_display == 'Fitness Instructor' && formatedRequestDate == startDate && startTime > formatedRequestTime){
        setAlertType('danger')
        setAlertMessage('Unable to send this request because it is not within the time conditions allowed. Please reach out to your manager for help.')
        setShowAlert(true)
        window.setTimeout(() => {
          setShowAlert(false)
          setAlertType('')
          setAlertMessage('')
        }, 5000)
        return
      }else{
        if(CheckEndTime){
          setAlertType('danger')
          setAlertMessage(`End Time can't be greater than or equal to start time.`)
          setShowAlert(true)
          window.setTimeout(() => {
            setShowAlert(false)
            setAlertType('')
            setAlertMessage('')
          }, 5000)
          return
        }else if(((requestDay < today) || (formatedRequestDate == startDate && startTime > formatedRequestTime)) && newSubRequest?.approvedUser?.id == '' && !newSubRequest?.editSubRequest){
          setAlertMessage('Please select the approved instructor.')
          setAlertType('danger')
          setShowAlert(true)
          window.setTimeout(() => {
            setShowAlert(false)
            setAlertType('')
            setAlertMessage('')
          }, 5000)
          return
        }else if(newSubRequest?.editSubRequest && ((requestDay < today) || (formatedRequestDate == startDate && startTime > formatedRequestTime)) && (user?.role_display !== 'Netgym Employee' && !permissions?.sub_request?.past_write)){
          setAlertMessage("Please select a time in the future.")
          setAlertType('danger')
          setShowAlert(true)
          window.setTimeout(() => {
            setShowAlert(false)
            setAlertType('')
            setAlertMessage('')
          }, 5000)
          return
        }
      }
    }else if(newSubRequest?.previousWeek){
      let CheckEndTime = newSubRequest?.schedules.some(item => {return (new Date(item?.start_time.substring(0, 19)) < new Date())})
      if(CheckEndTime && newSubRequest?.approvedUser?.id == ''){
        setAlertType('danger')
        setAlertMessage('Please select the approved instructor.')
        setShowAlert(true)
        window.setTimeout(() => {
          setShowAlert(false)
          setAlertType('')
          setAlertMessage('')
        }, 5000)
        return
      }
    }
    if(newSubRequest?.editSubRequest){
      processUpdateSubRequest()
    }else{
      if(newSubRequest?.approvedUser?.id !== ''){
        saveSubRequestInLocalStorage(newSubRequest, 3, business?.id, user?.id)
        setStep(3)
        setUrlByPageNo(3)
        return
      }
      saveSubRequestInLocalStorage(newSubRequest, 2, business?.id, user?.id)
      setStep(2)
      setUrlByPageNo(2)
    }
  }

  const closeAlert = (data) => {
    setShowAlert(data)
    setAlertType('')
    setAlertMessage('')
  }

  const redirectBack = () => {
    setRedirectUrl(`/sub-request-details/${id}`)
    setRedirect(true)
  }

  const processUpdateSubRequest = () => {
    setNextButtonDisabled(true)
    let schedule = newSubRequest?.schedules[0]
    if (!schedule) return;
    let url = `/api/v2/sub_requests/${id}`
    let params = {
      "sub_request": {
        "business_class_id": schedule?.business_class_id, 
        "business_location_id": schedule?.location_id, 
        "date": schedule?.date, 
        "description": newSubRequest?.comment || '', 
        "duration": schedule?.durationInMin, 
        "other_reason": newSubRequest?.reason?.otherReason || '', 
        "reason": newSubRequest?.reason?.reason || '', 
        "time": schedule?.formatted_start_time
      }
    }
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(result?.status === 200){
        setAlertMessage(result?.success)
        setAlertType('success')
        setShowAlert(true)
        setTimeout(()=>{
          setShowAlert(false)
          setNextButtonDisabled(false)
          redirectBack()
        },3000)
      }else{
        setNextButtonDisabled(false)
        setAlertMessage(result?.error)
        setAlertType('danger')
        setShowAlert(true)
        setTimeout(()=>{
          setShowAlert(false)
        },3000)
      }
    })
  }

  return(
    <Fragment>
      {redirect && <Redirect to={`${redirectUrl}`} />}
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      <div className="flex justify-center items-center">
        <button className={`flex justify-center items-center rounded-2xl px-4 py-3 h-11 w-48 bg-black text-sm text-white font-semibold ${checkTheNextButtonDisabled() ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleClickOnNext()} disabled={checkTheNextButtonDisabled()}>
          Next
        </button>
      </div>
    </Fragment>
  )
}
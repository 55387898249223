import React, { Fragment } from "react";

export default function DeleteTimeFrameComponent({handleClick, disabled}){

  return(
    <Fragment>
      <div className="flex justify-end">
        <button className={`border rounded-md border-gray-300 shadow-sm text-gray-700 hover:bg-gray-100 text-sm py-2 px-4 block cursor-pointer ${disabled ? 'cursor-not-allowed opacity-50' : ''}`} onClick={(e) => handleClick(e)} disabled={disabled}>
          Delete Timeframe
        </button>
      </div>
    </Fragment>
  )
}
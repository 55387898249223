import React, { Fragment } from "react";

export default function YearViewEventComponent(eventInfo){
  return(
    <Fragment>
      <div className="flex justify-center w-full px-1 gap-1">
        {eventInfo?.event?._def?.extendedProps?.statuses?.map((status, i) =>
          <Fragment key={i}>
            {['pending', 'accepted'].includes(status) ?
              <div key={i+'pending-event'} className="year-event-dot bg-pending-event border-pending-event shrink-0"></div>
            :
            status == 'approved' ?
              <div key={i+'approve-event'} className="year-event-dot bg-green-custom border-green-custom shrink-0"></div>
            :
            ['cancelled', 'declined'].includes(status) ?
              <div key={i+'cancelled-event'} className="year-event-dot bg-cancelled-event border-cancelled-event shrink-0"></div>
            :
            status == 'manager' ?
              <div key={i+'mgr-event'} className="year-event-dot bg-mgr-event border-mgr-event shrink-0"></div>
            :
            status == 'open' ?
              <div key={i+'open-event'} className="year-event-dot bg-open-event border-open-event shrink-0"></div>
            :
            status == 'no_show' ?
              <div key={i+'no-show-event'} className="year-event-dot bg-no-show-event border-no-show-event shrink-0"></div>
            :
            status == 'deleted' &&
              <div key={i+'deleted-event'} className="year-event-dot bg-deleted-event border-deleted-event shrink-0"></div>
            }
          </Fragment> 
        )}
      </div>
    </Fragment>
  )
}
import React, { Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import StatusBubble from '../../assets/statusBubble';

export default function CertificationCard(props) {
  const { certification, setCertification, formEditable, pageType } = props

  const handleCertChange = (name, val) => {
    setCertification( prevertification => ({
      ...prevertification,
      [name]: val
    }))
  }

  return(
    <Fragment>
      <div className="w-full p-4 bg-white shadow border border-gray-100 rounded-lg">
        <div className="flex items-center justify-between pb-2">
          <p className="text-lg font-bold break-all">
            {certification?.category?.name}
          </p>
          {['renew'].includes(pageType) ?
            <div></div>
          :
            <StatusBubble status={certification?.certification_status}/>
          }
        </div>
        <div className="flex items-center flex-wrap pt-4 pb-2 w-full lg:gap-2">
          <label htmlFor="Cert-name" className="block text-left text-sm font-medium text-gray-500 w-full">Document Name*</label>
          <input
            type="text"
            className={`border rounded-md px-2 py-1.5 shadow-sm text-sm h-10 w-full lg:w-3/5 mt-2 lg:mt-0 focus:outline-none bg-white ${!formEditable ? 'cursor-not-allowed' : ''}`}
            value={certification?.name}
            onChange={(e) => handleCertChange('name', e?.target?.value)}
            name="name"
            placeholder="Enter Document Name"
            disabled={!formEditable}
          />
        </div>
        <div className="flex items-center flex-wrap py-4 w-full lg:gap-2">
          <label htmlFor="Cert-expireDate" className="block text-left text-sm font-medium text-gray-500 w-full">Expiration Date{certification?.category?.expiration_date_required ? '*' : ''}</label>
          <div className="w-full lg:w-3/5 mt-2 lg:mt-0 relative">
            <DatePicker
              selected={certification?.expiration_date}
              className={`h-10 w-full rounded-md border shadow-sm px-2 py-1.5 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 relative z-10 bg-transparent ${!formEditable ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              autoFocus={false}
              minDate={new Date()}
              dateFormat="M/d/yyyy"
              onChange={(e) => handleCertChange('expiration_date', e ? e : '')} 
              calendarType="US"
              disabled={!formEditable}
            />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 absolute top-2 right-2.5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
            </svg>
          </div>
        </div>
        {pageType !== 'renew' &&
          <Fragment>
            {certification?.status == 'submitted' &&
              <div className="w-full mt-4">
                <div className="flex items-center flex-wrap pb-2 w-full flex-wrap gap-2">
                  <label htmlFor="Cert-name" className="block text-left text-sm font-semibold text-gray-900 w-26">Submitted By:</label>
                  <label htmlFor="Cert-name" className="block text-left text-sm font-semibold text-gray-500">{certification?.submitted_by}</label>
                </div>
                <div className="flex items-center flex-wrap pb-2 w-full flex-wrap gap-2">
                  <label htmlFor="Cert-name" className="block text-left text-sm font-semibold text-gray-900 w-26">Submitted On:</label>
                  <label htmlFor="Cert-name" className="block text-left text-sm font-semibold text-gray-500">{certification?.submitted_at}</label>
                </div>
              </div>
            }
          </Fragment>
        }
      </div>
    </Fragment>
  )
}
import React, { Fragment, useState, useEffect } from "react";
import CertificationsTable from './certificationsTable';
import AlertPopup from './../../../messenger/pages/chatComponents/assets/alertPopup';
import FilterComponents from './filterComponents';
import { LoadingSpinner, checkFiltersState, checkCategories, allStatuses, getCertificationId, checkLocations } from '../../helpers';
import { axiosGetRequest, checkShowRegions, seprateOutLocations } from './../../../subRequests/helpers/helpers';
import { Redirect } from "react-router-dom";

export default function InstructorCertifications(props) {
  const { user, permissions, savedInstructors, savedStatus, savedCategories, selectedFilterLocations, selectedFilterRegions } = props
  const [ certifications, setCertifications ] = useState([])
  const [ dataLoaded, setDataLoaded ] = useState(false)
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')
  const pageSize = 30
  const [ page, setPage ] = useState(1)
  const [ totalCount, setTotalCount ] = useState(1)
  const [ sortType, setSortType ] = useState('instructor_name')
  const [ sortReverse, setSortReverse ] = useState(false)
  const [ selectedInstructors, setSelectedInstructors ] = useState(savedInstructors || [])
  const [ selectedStatus, setSelectedStatus ] = useState(savedStatus || allStatuses)
  const [ selectedCategories, setSelectedCategories ] = useState(savedCategories || [])
  const [ categories, setCategories ] = useState([])
  const [ loaded, setLoaded ] = useState(false)
  const [ filterDataLoaded, setFilterDataLoaded ] = useState(false)
  const [ statusFilterCounts, setStatusFilterCounts ] = useState({
    'submitted': 0,
    'not_submitted': 0,
    'expired': 0,
    'expiring': 0
  })
  const [ redirect, setRedirect ] = useState(false)
  const [ redirectUrl, setRedirectUrl ] = useState('')
  const [ regionLocationsList, setRegionLocationsList ] = useState([])
  const [ showRegions, setShowRegions ] = useState(false)
  const [ selectedLocations, setSelectedLocations ] = useState(selectedFilterLocations || [])
  const [ selectedRegions, setSelectedRegions ] = useState(selectedFilterRegions || [])

  useEffect(() => {
    fetchCategories()
  }, []);

  const fetchCategories = () => {
    axiosGetRequest(`/api/v3/documentation_categories?initialize=${true}`).then(result => {
      if(result.status === 200){
        setCategories(result.certification_categories)
        setDefaultCategories(result.certification_categories, result?.assigned_locations)
        setRegionLocationsList(result?.assigned_locations || [])
        setShowRegions(checkShowRegions(result?.assigned_locations))
        
        setLoaded(true)
      }
    })
  }

  const setDefaultCategories = (allCategoryes, assignedLocations) => {
    let categoryIds = allCategoryes.map(function(a) {return a?.id});
    let categoriesToBeSelected = checkFiltersState(user?.id, 'categories') == 'all_selected' ? categoryIds : checkCategories(categoryIds, selectedCategories, user.id)
    setSelectedCategories(categoriesToBeSelected)

    let regions = assignedLocations.map(function(a) {return a.region?.name;});
    let locations = seprateOutLocations(assignedLocations).map(function(a) {return a.id;});
    let locationState = checkFiltersState(user?.id, 'location')
    let [locationsToBeSelected, regionsToBeSelected] = locationState == 'all_selected' ? [locations, regions] : checkLocations(locations, regions, selectedLocations, assignedLocations, user?.id)
    setSelectedLocations(locationsToBeSelected)
    setSelectedRegions(regionsToBeSelected)

    fethCertifications(selectedInstructors, categoriesToBeSelected, selectedStatus, sortType, sortReverse, page, locationsToBeSelected)
  }

  const closeAlert = (data) => {
    setShowAlert(data)
    setAlertType('')
    setAlertMessage('')
  }

  const setPageNumber = (page) => {
    setPage(page)
    setFilterDataLoaded(false)
    fethCertifications(selectedInstructors, selectedCategories, selectedStatus, sortType, sortReverse, page, selectedLocations)
  }

  const setSort = (e, name, order) => {
    setSortType(name)
    setSortReverse(order)
    setFilterDataLoaded(false)
    fethCertifications(selectedInstructors, selectedCategories, selectedStatus, name, order, page, selectedLocations)
  }

  const fethCertifications = (selectedInstructors, selectedCategories, selectedStatus, sortType, sortReverse, page, selectedLocations) => {
    let selectedInstructorIds = selectedInstructors.map(function(a) {return a?.id});
    let url = `/api/v3/documentations?my_certifications=${false}&instructor_ids=${selectedInstructorIds}&category_ids=${selectedCategories}&statuses=${selectedStatus}&sort_type=${sortType}&sort_reverse=${sortReverse}&page=${page}&page_size=${pageSize}&location_ids=${selectedLocations}`
    axiosGetRequest(url).then(result => {
      if(result.status === 200){
        setCertifications(result?.results)
        setTotalCount(result?.total_count)
        setStatusFilterCounts({
          'submitted': result?.submitted_count,
          'not_submitted': result?.not_submitted_count,
          'expired': result?.expired_count,
          'expiring': result?.expiring_count
        })
        setDataLoaded(true)
        setFilterDataLoaded(true)
      }
    })
  }

  const handleSelectInstructors = (data) => {
    setSelectedInstructors(data)
    setFilterDataLoaded(false)
    setPage(1)
    fethCertifications(data, selectedCategories, selectedStatus, sortType, sortReverse, 1, selectedLocations)
  }

  const handleSelectedStatus = (data) => {
    setSelectedStatus(data)
    setFilterDataLoaded(false)
    setPage(1)
    fethCertifications(selectedInstructors, selectedCategories, data, sortType, sortReverse, 1, selectedLocations)
  }

  const handleSelectedCategories = (data) => {
    setSelectedCategories(data)
    setFilterDataLoaded(false)
    setPage(1)
    fethCertifications(selectedInstructors, data, selectedStatus, sortType, sortReverse, 1, selectedLocations)
  }

  const handleEditCertificate = (data, type) => {
    setRedirectUrl(`${location?.pathname}/submit-documentation/${data}?page-type=${type || 'view'}`)
    setRedirect(true)
  }

  const handleSetAlert = (show, message, type) => {
    setAlertMessage(message)
    setAlertType(type)
    setShowAlert(show)
  }

  const hanldleSelectLocations = (regions, locations) => {
    setSelectedLocations(locations)
    setSelectedRegions(regions)
    setFilterDataLoaded(false)
    setPage(1)
    fethCertifications(selectedInstructors, selectedCategories, selectedStatus, sortType, sortReverse, 1, locations)
  }

  return (
    <Fragment>
      {redirect &&
        <Redirect push to={`${redirectUrl}`} />
      }
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      {!loaded &&
        <LoadingSpinner />
      }
      {loaded &&
        <div className="relative">
          <FilterComponents
            selectedInstructors={selectedInstructors}
            handleSelectInstructors={handleSelectInstructors}
            user={user}
            selectedStatus={selectedStatus}
            handleSelectedStatus={handleSelectedStatus}
            selectedCategories={selectedCategories}
            handleSelectedCategories={handleSelectedCategories}
            categories={categories}
            statusFilterCounts={statusFilterCounts}
            regionLocationsList={regionLocationsList}
            showRegions={showRegions}
            selectedLocations={selectedLocations}
            selectedRegions={selectedRegions}
            hanldleSelectLocations={hanldleSelectLocations}
          />
          <CertificationsTable 
            user={user}
            permissions={permissions}
            certifications={certifications}
            dataLoaded={dataLoaded}
            page={page}
            totalCount={totalCount}
            pageSize={pageSize}
            setPageNumber={setPageNumber}
            sortType={sortType}
            sortReverse={sortReverse}
            setSort={setSort}
            filterDataLoaded={filterDataLoaded}
            pageType={'instructor-documentation'}
            handleEditCertificate={handleEditCertificate}
            handleSetAlert={handleSetAlert}
          />
        </div>
      }
    </Fragment>
  )
}
import React, { Fragment, useState } from "react";
import PdfView from './pdfView';
import { getFileType } from '../../helpers';
import { axiosRequest } from "../../../subRequests/helpers/helpers";
import RemoveFileConfirmation from './removeFileConfirmation';

export default function ContentViewer(props) {
  const { certification, setCertification, handleShowAlert, pageType, setCertificationCopy } = props
  const fileType = getFileType(certification?.key_url);
  const [ disabledRemove, setDisabledRemove ] = useState(false)
  const [ showConfirmation, setShowConfirmation ] = useState(false)

  const renderContent = () => {
    switch (fileType) {
      case "image":
        return (
          <div className="detail-img flex items-center justify-center">
            <img src={certification?.key_url} />
          </div>
        );
      case "pdf":
        return (<PdfView docUrl={certification?.key_url}/>);
      default:
        return <div>Unsupported content type or CORS issue: Unable to load the resource.</div>; 
    }
  };

  const handleClose = (data) => {
    if (disabledRemove) return;
    setShowConfirmation(data)
  }

  const processRemove = () => {
    setDisabledRemove(true)
    axiosRequest(`/api/v3/documentations/remove_file`, 'PATCH', {id: certification?.id}, 'data').then((response) => {
      if(response.status === 200){
        handleShowAlert(true, response?.message, 'success')
        setTimeout(() => {
          handleShowAlert(false, '', '')
          setDisabledRemove(false)
          setCertification((prevCertification) => ({ ...prevCertification, key_url: '' }))
          setCertificationCopy((prevCertification) => ({ ...prevCertification, key_url: '' }))
        }, 3000)
      }else{
        handleShowAlert(true, response?.error, 'danger')
        setTimeout(() => {
          handleShowAlert(false, '', '')
          setDisabledRemove(false)
        }, 3000)
      }
    })
  }

  return(
    <Fragment>
      {showConfirmation && (
        <RemoveFileConfirmation showConfirmation={showConfirmation} handleClose={handleClose} processRemove={processRemove} disabledRemove={disabledRemove}/>
      )}
      {renderContent()}
      <div className="w-full flex py-2 justify-center items-center mt-2">
        <button className={`flex bg-dark-blue hover:bg-gray-800 sm:text-base text-sm text-white inline-block px-3 py-2 border shadow rounded-lg relative gap-2 items-center ${(disabledRemove || pageType == 'view') ? 'cursor-not-allowed opacity-50' : ''}`} onClick={(e) => setShowConfirmation(true)} disabled={disabledRemove || pageType == 'view'}>
          <div className='text-white text-base'>Remove File</div>
        </button>
      </div>
    </Fragment>
  )
}
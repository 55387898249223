import React, { Component } from 'react'
import { getSudomain, setTitle, timeZones } from './../../helpers'
import Cookies from 'js-cookie';
import DatePicker from 'react-date-picker';
import { updateBusiness, exportFuture, getFormatedStartDate } from './helpers'
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default class NetgymSettings extends Component {
  constructor(props){
    super(props);
    this.state = {
      business: {...props.business, start_date: props.business?.start_date || getFormatedStartDate(new Date())},
      show: false,
      alert_type: '',
      alert_message: '',
      time_zones: timeZones(),
      future_request_start: new Date(),
      future_request_end: new Date(),
      show_start_date: props.business?.start_date || getFormatedStartDate(new Date()),
      time_from: "",
      time_to: "",
      start_to_time: "00:00",
      end_from_time: "23:59",
      hour_range: [],
      to_hour_range: [],
      updateButtonDisabled: false,
      showStreamSecret: false,
      block_time_from: "",
      block_time_to: "",
      block_start_time: "",
      block_end_time: "",
      block_check_time: "",
      block_hour_range: "",
      block_to_hour_range: "",
      daysList: [{key: 'monday', label: 'Monday'}, {key: 'tuesday', label: 'Tuesday'}, {key: 'wednesday', label: 'Wednesday'}, {key: 'thursday', label: 'Thursday'}, {key: 'friday', label: 'Friday'}, {key: 'saturday', label: 'Saturday'}, {key: 'sunday', label: 'Sunday'}],
      blockDayIndex: [],
      selectedBlockDay: 'monday',
      loaded: false
    }
  }

  componentDidMount = () => {
    setTitle('account_info')
    // this.getFromToTime(this.props.business.business_hours)
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if (Object.keys(nextProps.business).length !== 0){
      this.getFromToTime(nextProps.business.business_hours)
      this.setState({
        loaded: true
      })
    }
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  getFromToTime = (business_hours) => {
    if(business_hours && business_hours !== "null"){
      let content = business_hours//JSON.parse(business_hours)
      this.setState({
        time_from: business_hours.time_from,
        time_to: business_hours.time_to
      })
      this.getBusinessRange(business_hours)
      this.getBusinessHour(business_hours)
    }  
  }

  getBusinessRange = (business_hours) => {
    let start_time = this.state.start_time || "12:00 AM"
    let end_time = this.state.end_time || "11:45 PM"
    let start_time_ampm = start_time.split(" ")
    let end_time_ampm = end_time.split(" ")
    let start_time_hours = start_time_ampm[0]
    let end_time_hours = end_time_ampm[0]
    let large_start_time_hours = ""
    let large_end_time_hours = ""
    if(start_time_ampm[1] === "PM"){
      if(start_time_hours.split(":")[0] !== "12"){
        large_start_time_hours = (Number(start_time_hours.split(":")[0]) + 12).toString()+":"+start_time_hours.split(":")[1]
      }
      else{
        large_start_time_hours = start_time_hours
      }
    }
    else{
      if(start_time_hours.split(":")[0] === "12"){
        large_start_time_hours = "0:"+start_time_hours.split(":")[1]
      }
      else{
        large_start_time_hours = start_time_hours
      }
    }

    if(end_time_ampm[1] === "PM"){
      if(end_time_hours.split(":")[0] !== "12"){
        large_end_time_hours = (Number(end_time_hours.split(":")[0]) + 12).toString()+":"+end_time_hours.split(":")[1]
      }
      else{
        if(end_time_hours.split(":")[0] === "12"){
          large_end_time_hours = "0:"+end_time_hours.split(":")[1]
        }
        else{
          large_end_time_hours = end_time_hours
        }
      }
    }
    let start_hour_min = large_start_time_hours.split(":")
    let end_hour_min = large_end_time_hours.split(":")
    let start_hour = Number(large_start_time_hours.split(":")[0])
    let end_hour = Number(large_end_time_hours.split(":")[0])
    let row_range = []



    for (;start_hour <= end_hour; start_hour++){
      if(start_hour < 12){
        if(start_hour === 0){
          row_range.push("12:00 AM")
          row_range.push("12:15 AM")
          row_range.push("12:30 AM")
          row_range.push("12:45 AM")
        }
        else{
          row_range.push(start_hour.toString()+":00 AM")
          row_range.push(start_hour.toString()+":15 AM")
          row_range.push(start_hour.toString()+":30 AM")
          row_range.push(start_hour.toString()+":45 AM")
        }
      }
      else{
        if(start_hour > 12){
          row_range.push((start_hour-12).toString()+":00 PM")
          row_range.push((start_hour-12).toString()+":15 PM")
          row_range.push((start_hour-12).toString()+":30 PM")
          row_range.push((start_hour-12).toString()+":45 PM")
        }
        else{
          row_range.push(start_hour.toString()+":00 PM")
          row_range.push(start_hour.toString()+":15 PM")
          row_range.push(start_hour.toString()+":30 PM")
          row_range.push(start_hour.toString()+":45 PM")
        }
      }
    }
    for(let i = 0; i < 4; i++){
      if(start_time !== row_range[0]){
        row_range.shift();
      }else{
        break;
      }
    }
    for(let i = 0; i < 4; i++){
      if(end_time !== row_range[row_range?.length-1]){
        row_range.pop();
      }else{
        break;
      }
    }

    let range = []
    for (let i = 0;i < row_range.length -1; i++){
      range.push(row_range[i])
    }

    let from_time_index = row_range.indexOf(business_hours?.time_from)
    let check = []
    for (let i = from_time_index +1;i < row_range.length; i++){
      check.push(row_range[i])
    } 

    this.setState({
      help_range: row_range,
      hour_range: range,
      to_hour_range: check
    });
  }

  handleDateChange = (e) => {
    var startDate = new Date(e);
    // var day = 60 * 60 * 24 * 1000;
    // let data = new Date(startDate.getTime() + day);
    let month = ("0" + (startDate.getMonth() + 1)).slice(-2)
    let day = ("0" + startDate.getDate()).slice(-2)
    let year = startDate.getFullYear().toString()
    let data = month+"/"+day+"/"+year
    this.setState({
      business: {
        ...this.state.business,
        start_date: data
      },
      show_start_date: e
    })
  }

  handleChange = (e) => {
    const { name, value } = e.target
    this.setState({
      business: {
        ...this.state.business,
        [name]: value
      }
    })
  }

  submit = (e) => {
    e.preventDefault()
    this.setState({updateButtonDisabled: true})
    let business = this.state.business
    business["block_availability_days"] = this.state.blockDayIndex
    
    updateBusiness(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), business).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          show: true,
          alert_message: (result?.success && result?.success != '' ) ? result?.success : 'Business Successfully Updated',
          alert_type: 'success',
        })
        setTimeout(function(){ window.location.reload() }, 1500);        
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
        setTimeout(function(){ window.location.reload() }, 3000);
      }
    })
  }

  handleDateChange1 = (date) => {
    this.setState({
      future_request_start: date
    })
  }

  handleDateChange2 = (date) => {
    this.setState({
      future_request_end: date
    })
  }

  download = (e) => {
    e.preventDefault()
    exportFuture(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.future_request_start, this.state.future_request_end).then(
      response => response.blob()
      .then(result => {
        const url = window.URL.createObjectURL(result)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download',"sample_future_requests.csv")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)        
      })
    )
  }

  handleStart = (e) => {
    e.preventDefault()
    this.setState({
      time_from: e.target.value
    })
    let to_time_value = this.state.time_to
    let from_time_index = this.state.help_range.indexOf(e.target.value)
    let check = []
    for (let i = from_time_index +1;i < this.state.help_range?.length; i++){
      check.push(this.state.help_range[i])
    } 

    if(!check.includes(to_time_value)){
      to_time_value = check[0]
      this.setState({
        time_to: to_time_value,
      })
    }
    
    this.setState({
      to_hour_range: check,
      business: {
        ...this.state.business,
        business_hours: {
          time_from: e.target.value,
          time_to: to_time_value
        }
      }
    });
    
    this.getBusinessHour({time_from: e.target.value, time_to: to_time_value})
  }

  handleEnd = (e) => {
    e.preventDefault()
    this.setState({
      time_to: e.target.value,
      business: {
        ...this.state.business,
        business_hours: {
          time_from: this.state.time_from,
          time_to: e.target.value
        }
      }
    })

    this.getBusinessHour({time_from: this.state.time_from, time_to: e.target.value})
  }

  handleSwitch = (e, name) => {
    this.setState({
      business: {
        ...this.state.business,
        [name]: !this.state.business?.[name]
      }
    })
  }

  handleIntegrationSwitch = (e, name) => {
    if(name === 'mbo_integration'){
      if(e){
        let business = this.state.business
        business.abc_integration = false
        business.crunch_integration = false
        business.marianatek_integration = false
        business.fisikal_integration = false
        business.mbo_integration = !this.state.business?.mbo_integration
        business.clubready_integration = false
        this.setState({
          business: business
        })
      }else{
        this.setState({
          business: {
            ...this.state.business,
            [name]: !this.state.business?.[name]
          }
        })
      }
      
    }else if(name === 'abc_integration'){
      if(e){
        let business = this.state.business
        business.abc_integration = !this.state.business?.abc_integration
        business.crunch_integration = false
        business.marianatek_integration = false
        business.mbo_integration = false
        business.fisikal_integration = false
        business.clubready_integration = false
        this.setState({
          business: business
        })
      }else{
        this.setState({
          business: {
            ...this.state.business,
            [name]: !this.state.business?.[name]
          }
        })
      }
    }
    else if(name === 'marianatek_integration'){
      if(e){
        let business = this.state.business
        business.abc_integration = false
        business.crunch_integration = false
        business.marianatek_integration = !this.state.business?.marianatek_integration
        business.mbo_integration = false
        business.fisikal_integration = false
        business.clubready_integration = false
        this.setState({
          business: business
        })
      }else{
        this.setState({
          business: {
            ...this.state.business,
            [name]: !this.state.business?.[name]
          }
        })
      }
    }
    else if(name === 'crunch_integration'){
      if(e){
        let business = this.state.business
        business.abc_integration = false
        business.crunch_integration = !this.state.business?.crunch_integration
        business.marianatek_integration = false
        business.mbo_integration = false
        business.fisikal_integration = false
        business.clubready_integration = false
        this.setState({
          business: business
        })
      }else{
        this.setState({
          business: {
            ...this.state.business,
            [name]: !this.state.business?.[name]
          }
        })
      }
    }else if(name === 'fisikal_integration'){
      if(e){
        let business = this.state.business
        business.abc_integration = false
        business.crunch_integration = false
        business.marianatek_integration = false
        business.mbo_integration = false
        business.clubready_integration = false
        business.fisikal_integration = !this.state.business?.fisikal_integration
        this.setState({
          business: business
        })
      }else{
        this.setState({
          business: {
            ...this.state.business,
            [name]: !this.state.business?.[name]
          }
        })
      }
    }
    else if(name === 'clubready_integration'){
      if(e){
        let business = this.state.business
        business.abc_integration = false
        business.crunch_integration = false
        business.marianatek_integration = false
        business.mbo_integration = false
        business.fisikal_integration = false
        business.clubready_integration = !this.state.business?.crunch_integration
        this.setState({
          business: business
        })
      }else{
        this.setState({
          business: {
            ...this.state.business,
            [name]: !this.state.business?.[name]
          }
        })
      }
    }
    else{
      this.setState({
        business: {
          ...this.state.business,
          [name]: !this.state.business?.[name]
        }
      })
    }
    
  }

  handleOnBlur = (e) => {
    const { name, value } = e.target
    if(name === "days_in_advance" && value === ''){
      this.setState({
        business: {
          ...this.state.business,
          [name]: 0
        }
      })
    }else if(name === "time_before_class" && value === ''){
      this.setState({
        business: {
          ...this.state.business,
          [name]: 0
        }
      })
    }else if(name === "sms_notifications_restriction_days" && value === ''){
      this.setState({
        business: {
          ...this.state.business,
          [name]: 0
        }
      })
    }else if(name === "no_response_invites_count" && value === ''){
      this.setState({
        business: {
          ...this.state.business,
          [name]: 0
        }
      })
    }
  }

  getBusinessHour = (business_hours) => {
    if(business_hours && business_hours !== "null"){
      let business_hour_content = business_hours
      let businessHour = business_hour_content//JSON.parse(business_hour_content)
      let time_from = businessHour?.time_from.split(" ")
      let time_to = businessHour?.time_to.split(" ")

      let start_time = ""
      let end_time = ""
      if(time_from[1] === "AM"){
        let time_from_split_hour_min = time_from[0].split(":")
        let time_from_hour = time_from_split_hour_min[0]
        if(Number(time_from_hour) == 12){
          start_time = "00:"+time_from_split_hour_min[1]
        }
        else{
          start_time = time_from[0]
        }
      }
      else if(time_from[1] === "PM"){
        let time_from_full_time = time_from[0].split(":")
        if(time_from_full_time[0] < 12){
          let time_from_large_hour = Number(time_from_full_time[0]) + 12
          start_time = time_from_large_hour.toString()+":"+time_from_full_time[1]
        }
      }
      if(time_to[1] === "AM"){
        end_time = time_to[0]
      }
      else if(time_to[1] === "PM"){
        let time_to_full_time = time_to[0].split(":")
        if(time_to_full_time[0] < 12){
          let time_to_large_hour = Number(time_to_full_time[0]) + 12
          end_time = time_to_large_hour.toString()+":"+time_to_full_time[1]
        }
      }
      this.setState({
        block_time_from: businessHour?.time_from,
        block_time_to: businessHour?.time_to,
        block_start_time: start_time,
        block_end_time: end_time,
        block_check_time: end_time,
      })
      this.getBlockBusinessRange(businessHour)
    }
  }

  getBlockBusinessRange = (businessHour) => {
    let start_time = businessHour?.time_from
    let end_time = businessHour?.time_to
    let start_time_ampm = start_time.split(" ")
    let end_time_ampm = end_time.split(" ")
    let start_time_hours = start_time_ampm[0]
    let end_time_hours = end_time_ampm[0]
    let large_start_time_hours = ""
    let large_end_time_hours = ""
    if(start_time_ampm[1] === "PM"){
      if(start_time_hours.split(":")[0] !== "12"){
        large_start_time_hours = (Number(start_time_hours.split(":")[0]) + 12).toString()+":"+start_time_hours.split(":")[1]
      }
      else{
        large_start_time_hours = start_time_hours
      }
    }
    else{
      if(start_time_hours.split(":")[0] === "12"){
        large_start_time_hours = "0:"+start_time_hours.split(":")[1]
      }
      else{
        large_start_time_hours = start_time_hours
      }
    }

    if(end_time_ampm[1] === "PM"){
      if(end_time_hours.split(":")[0] !== "12"){
        large_end_time_hours = (Number(end_time_hours.split(":")[0]) + 12).toString()+":"+end_time_hours.split(":")[1]
      }
      else{
          large_end_time_hours = end_time_hours
      }
    }
    else{
      if(end_time_hours.split(":")[0] === "12"){
        large_end_time_hours = "0:"+end_time_hours.split(":")[1]
      }
      else{
        large_end_time_hours = end_time_hours
      }
    }

    let start_hour_min = large_start_time_hours.split(":")
    let end_hour_min = large_end_time_hours.split(":")
    let start_hour = Number(large_start_time_hours.split(":")[0])
    let end_hour = Number(large_end_time_hours.split(":")[0])
    let row_range = []
    for (;start_hour <= end_hour; start_hour++){
      if(start_hour < 12){
        if(start_hour === 0){
          row_range.push("12:00 AM")
          row_range.push("12:15 AM")
          row_range.push("12:30 AM")
          row_range.push("12:45 AM")
        }
        else{
          row_range.push(start_hour.toString()+":00 AM")
          row_range.push(start_hour.toString()+":15 AM")
          row_range.push(start_hour.toString()+":30 AM")
          row_range.push(start_hour.toString()+":45 AM")
        }
      }
      else{
        if(start_hour > 12){
          row_range.push((start_hour-12).toString()+":00 PM")
          row_range.push((start_hour-12).toString()+":15 PM")
          row_range.push((start_hour-12).toString()+":30 PM")
          row_range.push((start_hour-12).toString()+":45 PM")
        }
        else{
          row_range.push(start_hour.toString()+":00 PM")
          row_range.push(start_hour.toString()+":15 PM")
          row_range.push(start_hour.toString()+":30 PM")
          row_range.push(start_hour.toString()+":45 PM")
        }
      }
    }
    for(let i = 0; i < 4; i++){
      if(start_time !== row_range[0]){
        row_range.shift();
      }else{
        break;
      }
    }
    for(let i = 0; i < 4; i++){
      if(end_time !== row_range[row_range?.length-1]){
        row_range.pop();
      }else{
        break;
      }
    }
    this.setState({
      block_hour_range: row_range,
      block_to_hour_range: row_range
    })
    setTimeout(() => {
      this.getDays(this.props.business?.block_availability_days)
    }, 100);
  }

  getDays = (data) => {
    let content = data
    for(let i = 0; i < content?.length; i++){
      for(let j = 0; j < content[i].availability_slots_attributes?.length; ){
        if(j === 0){
          if(j === content[i].availability_slots_attributes?.length-1){
            if(this.sortFromRange(this.state.block_time_from, content[i].availability_slots_attributes[j].time_to).includes(content[i].availability_slots_attributes[j].time_from) && this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.block_time_to).includes(content[i].availability_slots_attributes[j].time_to)){
              content[i].availability_slots_attributes[j] = Object.assign({
                id: content[i].availability_slots_attributes[j]?.id,
                time_from: content[i].availability_slots_attributes[j].time_from,
                time_to: content[i].availability_slots_attributes[j].time_to,
                from_range: this.sortFromRange(this.state.block_time_from, this.state.block_time_to),
                to_range: this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.block_time_to)
              })
            }else{
              if(this.state.block_time_from == content[i].availability_slots_attributes[j].time_to || this.state.block_time_to == content[i].availability_slots_attributes[j].time_from){
                content[i].availability_slots_attributes[j] = Object.assign({
                  id: content[i].availability_slots_attributes[j]?.id,
                  time_from: this.state.block_time_from,
                  time_to: this.state.block_time_to,
                  from_range: this.sortFromRange(this.state.block_time_from, this.state.block_time_to),
                  to_range: this.sortToRange(this.state.block_time_from, this.state.block_time_to)
                })
              }else{
                if(!this.sortFromRange(this.state.block_time_from, content[i].availability_slots_attributes[j].time_to).includes(content[i].availability_slots_attributes[j].time_from) && this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.block_time_to).includes(content[i].availability_slots_attributes[j].time_to)){
                  content[i].availability_slots_attributes[j] = Object.assign({
                    id: content[i].availability_slots_attributes[j]?.id,
                    time_from: this.state.block_time_from,
                    time_to: content[i].availability_slots_attributes[j].time_to,
                    from_range: this.sortFromRange(this.state.block_time_from, content[i].availability_slots_attributes[j].time_to),
                    to_range: this.sortToRange(this.state.block_time_from, this.state.block_time_to)
                  })
                }else if(this.sortFromRange(this.state.block_time_from, content[i].availability_slots_attributes[j].time_to).includes(content[i].availability_slots_attributes[j].time_from) && !this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.block_time_to).includes(content[i].availability_slots_attributes[j].time_to)){
                  content[i].availability_slots_attributes[j] = Object.assign({
                    id: content[i].availability_slots_attributes[j]?.id,
                    time_from: content[i].availability_slots_attributes[j].time_from,
                    time_to: this.state.block_time_to,
                    from_range: this.sortFromRange(content[i].availability_slots_attributes[j].time_from, this.state.block_time_to),
                    to_range: this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.block_time_to)
                  })
                }else{
                  content[i].availability_slots_attributes[j] = Object.assign({
                    id: content[i].availability_slots_attributes[j]?.id,
                    time_from: this.state.block_time_from,
                    time_to: this.state.block_time_to,
                    from_range: this.sortFromRange(this.state.block_time_from, this.state.block_time_to),
                    to_range: this.sortToRange(this.state.block_time_from, this.state.block_time_to)
                  })
                }
              }
            }
            j++;
          }
          else{
            if(this.sortFromRange(this.state.block_time_from, content[i].availability_slots_attributes[j].time_to).includes(content[i].availability_slots_attributes[j].time_from)){
              content[i].availability_slots_attributes[j] = Object.assign({
                id: content[i].availability_slots_attributes[j]?.id,
                time_from: content[i].availability_slots_attributes[j].time_from,
                time_to: content[i].availability_slots_attributes[j].time_to,
                from_range: this.sortFromRange(this.state.block_time_from, content[i].availability_slots_attributes[j].time_to),
                to_range: this.sortToRange(content[i].availability_slots_attributes[j].time_from, content[i].availability_slots_attributes[j+1].time_from)
              })
            j++;
            }else{
              content[i].availability_slots_attributes.splice(j, 1)
            }
          }
        }
        else{
          if(j === content[i].availability_slots_attributes?.length-1){
            if(this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.block_time_to).includes(content[i].availability_slots_attributes[j].time_to)){
              content[i].availability_slots_attributes[j] = Object.assign({
                id: content[i].availability_slots_attributes[j]?.id,
                time_from: content[i].availability_slots_attributes[j].time_from,
                time_to: content[i].availability_slots_attributes[j].time_to,
                from_range: this.sortFromRange(content[i].availability_slots_attributes[j-1].time_to, content[i].availability_slots_attributes[j].time_to),
                to_range: this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.block_time_to)
              })
              j++;
            }else{
              content[i].availability_slots_attributes.splice(j, 1)
              j--;
            }
          }
          else{
            content[i].availability_slots_attributes[j] = Object.assign({
              id: content[i].availability_slots_attributes[j]?.id,
              time_from: content[i].availability_slots_attributes[j].time_from,
              time_to: content[i].availability_slots_attributes[j].time_to,
              from_range: this.sortFromRange(content[i].availability_slots_attributes[j-1].time_to, content[i].availability_slots_attributes[j].time_to),
              to_range: this.sortToRange(content[i].availability_slots_attributes[j].time_from, content[i].availability_slots_attributes[j+1].time_from)
            })
            j++;
          }
        }
      }
      let help = this.sortToRange(this.state.block_time_from, this.state.block_time_to)

      if(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === this.state.block_time_to || content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === help[help?.length-2]){
        content[i] = Object.assign({ id: content[i].id, day: content[i].day, available: content[i].available, availability_slots_attributes: content[i].availability_slots_attributes, disable : true
          })
      }
      else{
        content[i] = Object.assign({ id: content[i].id, day: content[i].day, available: content[i].available, availability_slots_attributes: content[i].availability_slots_attributes, disable : false
          })
      }

      let fromHelp = this.sortFromRange(this.state.block_time_from, this.state.block_time_to)
      
      if(content[i].availability_slots_attributes[0].time_from === this.state.block_time_from || content[i].availability_slots_attributes[0].time_from === fromHelp[1]){
        content[i] = Object.assign({ id: content[i].id, day: content[i].day, availability_slots_attributes: content[i].availability_slots_attributes, disable : content[i].disable, prev_disable: true
          })
      }
      else{
        content[i] = Object.assign({ id: content[i].id, day: content[i].day, availability_slots_attributes: content[i].availability_slots_attributes, disable : content[i].disable, prev_disable: false
          })
      }
    }
    this.setState({
      blockDayIndex: content,
      loaded: true
    })
  }

  sortFromRange = (prevToTime, currentToTime) => {
    let prevToTime_index = this.state.block_hour_range.indexOf(prevToTime)
    let currentToTime_index = this.state.block_hour_range.indexOf(currentToTime)
    let check = []
    for (let i = prevToTime_index;i < this.state.block_hour_range?.length; i++){
      if(i < currentToTime_index){  
        check.push(this.state.block_hour_range[i])
      }
    }
    return check
  }

  sortToRange = (currentFromTime, nextFromTime) => {
    let currentFromTime_index = this.state.block_hour_range.indexOf(currentFromTime)
    let nextFromTime_index = this.state.block_hour_range.indexOf(nextFromTime)
    let check = []
    for (let i = currentFromTime_index +1;i < this.state.block_hour_range?.length; i++){
      if(i <= nextFromTime_index){
        check.push(this.state.block_hour_range[i])
      }
    }
    return check
  }

  dayName = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  }

  handleAdd = (e, day) => {
    let content = this.state.blockDayIndex;
    for (let i = 0; i < content?.length; i++){
      if(content[i].day === day){
        let help = this.sortToRange(this.state.block_time_from, this.state.block_time_to)

        if(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to !== this.state.block_time_to || content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].time_to !== help[help?.length-2]){

          content[i].availability_slots_attributes.push({
            time_from: this.sortFromRange(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to, this.state.block_time_to)[0], 
            time_to: this.state.block_time_to, 
            from_range: this.sortFromRange(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to, this.state.block_time_to),
            to_range: this.sortToRange(this.sortFromRange(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to, this.state.block_time_to)[1], this.state.block_time_to)
          })
          if(content[i].availability_slots_attributes?.length === 2){
            content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].to_range = this.sortToRange(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].time_from, content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_from)

          }
          else {
            content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].from_range = this.sortFromRange(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-3].time_to, content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].time_to)

            content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].to_range = this.sortToRange(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].time_from, content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_from)
          }
          

          if(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === this.state.block_time_to || content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === help[help?.length-2]){
            content[i].disable = true
          }
          else{
            content[i].disable = false
          }
        }
        else{
            content[i].disable = true
          }
      }
    }
    this.setState({
      blockDayIndex: content
    })
  }

  handleAddPrevSlot = (e, day) => {
    let content = this.state.blockDayIndex;
    let holdSlot = []
    let actualData = []
    let help = this.sortToRange(this.state.block_time_from, this.state.block_time_to)
    let fromHelp = this.sortFromRange(this.state.block_time_from, this.state.block_time_to)
    for (let i = 0; i < content?.length; i++){
      if(content[i].day === day){

        if(content[i].availability_slots_attributes[0].time_from !== this.state.block_time_from || content[i].availability_slots_attributes[0].time_from !== fromHelp[1]){

          holdSlot.push({
            time_from: this.state.block_time_from, 
            time_to: this.sortToRange(this.state.block_time_from, content[i].availability_slots_attributes[0].time_from)[this.sortToRange(this.state.block_time_from, content[i].availability_slots_attributes[0].time_from)?.length-1], 
            from_range: this.sortFromRange(this.state.block_time_from, this.sortToRange(this.state.block_time_from, content[i].availability_slots_attributes[0].time_from)[this.sortToRange(this.state.block_time_from, content[i].availability_slots_attributes[0].time_from)?.length-2]),
            to_range: this.sortToRange(this.state.block_time_from, content[i].availability_slots_attributes[0].time_from)
          })
          content[i].availability_slots_attributes[0].from_range = this.sortFromRange(holdSlot[0].time_to, content[i].availability_slots_attributes[0].time_to)
          actualData = [...holdSlot, ...content[i].availability_slots_attributes]
          content[i].availability_slots_attributes = actualData

          if(content[i].availability_slots_attributes[0].time_from === this.state.block_time_from || content[i].availability_slots_attributes[0].time_from === fromHelp[1]){
            content[i].prev_disable = true
          }
          else{
            content[i].prev_disable = false
          }
        }
        else{
            content[i].prev_disable = true
          }
      }
    }
    this.setState({
      blockDayIndex: content
    })
  }

  deleteSlot = (e, key, day) => {
    let content = this.state.blockDayIndex;
    for (let i = 0; i < content?.length; i++){
      if(content[i].day === day){
        if(content[i].availability_slots_attributes?.length > 1){
          
           if(key < content[i].availability_slots_attributes?.length-1){
            if(key === 0){
              content[i].availability_slots_attributes[key+1].from_range = this.sortFromRange(this.state.block_time_from, content[i].availability_slots_attributes[key+1].time_to)
            }
            else{
              if(key === content[i].availability_slots_attributes?.length-1){
                content[i].availability_slots_attributes[key-1].to_range = this.sortToRange(content[i].availability_slots_attributes[key-1].time_from, this.state.block_time_to)
              }
              else{
                content[i].availability_slots_attributes[key-1].to_range = this.sortToRange(content[i].availability_slots_attributes[key-1].time_from, content[i].availability_slots_attributes[key+1].time_from)
                content[i].availability_slots_attributes[key+1].from_range = this.sortFromRange(content[i].availability_slots_attributes[key-1].time_to, content[i].availability_slots_attributes[key+1].time_to)
              }
            }
          }
          else{
            content[i].availability_slots_attributes[key-1].to_range = this.sortToRange(content[i].availability_slots_attributes[key-1].time_from, this.state.block_time_to)
          }
          content[i].availability_slots_attributes.splice(key, 1);
          
          let help = this.sortToRange(this.state.block_time_from, this.state.block_time_to)
          
          if(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === this.state.block_time_to || content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === help[help?.length-2]){
            content[i].disable = true
          }
          else{
            content[i].disable = false
          }

          let fromHelp = this.sortFromRange(this.state.block_time_from, this.state.block_time_to)
          
          if(content[i].availability_slots_attributes[0].time_from === this.state.block_time_from || content[i].availability_slots_attributes[0].time_from === fromHelp[1]){
            content[i].prev_disable = true
          }
          else{
            content[i].prev_disable = false
          }
        }
        else if(content[i].availability_slots_attributes?.length === 1){
          content[i].available = false
        }
      }
    }
    this.setState({
      blockDayIndex: content
    })
  }

  handleFrom = (e, day, key) => {
    let content = this.state.blockDayIndex;
    for (let i = 0; i < content?.length; i++){
      if(content[i].day === day){
        for (let j = 0; j < content[i].availability_slots_attributes?.length; j++){
          if(key === j){
            if(j === 0){
              if(j === content[i].availability_slots_attributes?.length-1){
                content[i].availability_slots_attributes[j].time_from = e.target.value
                content[i].availability_slots_attributes[j].to_range = this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.block_time_to)
              }
              else{
                content[i].availability_slots_attributes[j].time_from = e.target.value
                content[i].availability_slots_attributes[j].to_range = this.sortToRange(content[i].availability_slots_attributes[j].time_from, content[i].availability_slots_attributes[j+1].time_from)
              }
            }
            else{
              if(j < content[i].availability_slots_attributes?.length-1 ){
                content[i].availability_slots_attributes[j].time_from = e.target.value
                content[i].availability_slots_attributes[j].to_range = this.sortToRange(content[i].availability_slots_attributes[j].time_from, content[i].availability_slots_attributes[j+1].time_from)
                content[i].availability_slots_attributes[j-1].to_range = this.sortToRange(content[i].availability_slots_attributes[j-1].time_from, content[i].availability_slots_attributes[j].time_from)
              }
              else{
                content[i].availability_slots_attributes[j].time_from = e.target.value
                let check_to_time = this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.block_time_to)
                if(!check_to_time.includes(content[i].availability_slots_attributes[j].time_to)){
                  
                  content[i].availability_slots_attributes[j].time_to = this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.block_time_to)[0]
                }
                content[i].availability_slots_attributes[j-1].to_range = this.sortToRange(content[i].availability_slots_attributes[j-1].time_from, content[i].availability_slots_attributes[j].time_from)
                content[i].availability_slots_attributes[j].to_range = this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.block_time_to)

              }
            }
          }
        }
        let help = this.sortToRange(this.state.block_time_from, this.state.block_time_to)

        if(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === this.state.block_time_to || content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === help[help?.length-2]){
          content[i].disable = true
        }
         else{
           content[i].disable = false
         }
        
        let fromHelp = this.sortFromRange(this.state.block_time_from, this.state.block_time_to)
          
        if(content[i].availability_slots_attributes[0].time_from === this.state.block_time_from || content[i].availability_slots_attributes[0].time_from === fromHelp[1]){
          content[i].prev_disable = true
        }
        else{
          content[i].prev_disable = false
        }
      }
    }
    this.setState({
      blockDayIndex: content
    })
  }

  handleTo = (e, day, key) => {
    let content = this.state.blockDayIndex;
    for (let i = 0; i < content?.length; i++){
      if(content[i].day === day){
        for (let j = 0; j < content[i].availability_slots_attributes?.length; j++){
          if(key === j){
            if(j === 0){
              if(j === content[i].availability_slots_attributes?.length-1){
                content[i].availability_slots_attributes[j].time_to = e.target.value
                content[i].availability_slots_attributes[j].from_range = this.sortFromRange(this.state.block_time_from, content[i].availability_slots_attributes[j].time_to)
              }
              else{
                content[i].availability_slots_attributes[j].time_to = e.target.value
                content[i].availability_slots_attributes[j].from_range = this.sortFromRange(this.state.block_time_from, content[i].availability_slots_attributes[j].time_to)
                content[i].availability_slots_attributes[j+1].from_range = this.sortFromRange(content[i].availability_slots_attributes[j].time_to, content[i].availability_slots_attributes[j+1].time_to)
              }
            }
            else{
              if(j < content[i].availability_slots_attributes?.length-1){
                content[i].availability_slots_attributes[j].time_to = e.target.value
                content[i].availability_slots_attributes[j].from_range = this.sortFromRange(content[i].availability_slots_attributes[j-1].time_to, content[i].availability_slots_attributes[j].time_to)
                content[i].availability_slots_attributes[j+1].from_range = this.sortFromRange(content[i].availability_slots_attributes[j].time_to, content[i].availability_slots_attributes[j+1].time_to)
              }
              else{
                content[i].availability_slots_attributes[j].time_to = e.target.value
                content[i].availability_slots_attributes[j].from_range = this.sortFromRange(content[i].availability_slots_attributes[j-1].time_to, content[i].availability_slots_attributes[j].time_to)
              }
            }
          }
          else if(j > key){
            content[i].availability_slots_attributes[j].from_range = this.sortFromRange(content[i].availability_slots_attributes[j-1].time_to, content[i].availability_slots_attributes[j].time_to)          }
        }
        let help = this.sortToRange(this.state.block_time_from, this.state.block_time_to)

        if(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === this.state.block_time_to || content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === help[help?.length-2]){
          content[i].disable = true
        }
        else{
          content[i].disable = false
        }

        let fromHelp = this.sortFromRange(this.state.block_time_from, this.state.block_time_to)
          
        if(content[i].availability_slots_attributes[0].time_from === this.state.block_time_from || content[i].availability_slots_attributes[0].time_from === fromHelp[1]){
          content[i].prev_disable = true
        }
        else{
          content[i].prev_disable = false
        }
      }
    }
    this.setState({
      blockDayIndex: content
    })
  }

  // handleChange = (e, day) => {
  //   let content = this.state.blockDayIndex;
  //   for (let i = 0; i < content?.length; i++){
  //     if(content[i].day === day){
  //       content[i].available = e.target.checked
  //     }
  //   }
  //   this.setState({
  //     blockDayIndex: content
  //   })
  // }

  handleDay = (e) => {
    this.setState({
      selectedBlockDay: e.target.value
    })
  }

  render() {
    const { business, time_zones, show, alert_message, alert_type, future_request_start, future_request_end, show_start_date, time_from, time_to, start_to_time, end_from_time, hour_range, to_hour_range, updateButtonDisabled, showStreamSecret, block_time_from, block_time_to, block_start_time, block_end_time, block_check_time, block_hour_range, block_to_hour_range, daysList, blockDayIndex, selectedBlockDay, loaded } = this.state
    const { loadingMessage } = this.props
    
    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={(e) => this.setState({show: false})}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto" onClick={(e) => this.setState({show: false})}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        {!loaded &&
          <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
            <p className="mb-1 text-center px-5">{loadingMessage}</p>
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        }
        {loaded &&
          <div className='bg-white rounded-md shadow px-6 mb-10'>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Start Date</label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className="lg:w-80 relative messenger-box py-1.5">
                  <DatePicker
                    value = {Object.keys(business).length === 0  ? new Date() : business?.start_date === '' ? new Date() : new Date(Date.parse(show_start_date))}
                    name = "hire_date"
                    className=" border rounded-md px-2 w-full shadow-sm h-10 border-gray-300 pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                    autoFocus={false}
                    format="M/d/yyyy"
                    onChange={(e) => this.handleDateChange(e)}
                    calendarType="US"
                    clearIcon={null}
                  />
                </div>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Default Time Zone
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <select name="default_time_zone" defaultValue={business?.default_time_zone} onChange={(e) => this.handleChange(e)} className='form-select lg:w-80 bg-white block w-full pl-3 pr-5 py-2 h-10 text-base border border-gray-300  sm:text-sm rounded-md shadow-sm'>
                  <option value="">Select Time Zone</option>
                  {time_zones.map((zone,i) =>
                    <option key={i} value={zone.key}>{zone.value}</option>
                  )}
                </select>  
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Number of invites someone receives without responding
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full text-sm shadow-sm h-10" name="no_response_invites_count" value={business?.no_response_invites_count} onChange={(e) => this.handleChange(e)} onBlur={(e) => this.handleOnBlur(e)}/>  
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Hide Escalation Button
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`Hide_Escalation_Button-1`}
                  checked={!business?.escalation_btn}
                  name="escalation_btn"
                  onChange={(e) => this.handleSwitch (e, "escalation_btn")}
                  className={classNames(
                    !business?.escalation_btn ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      !business?.escalation_btn ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="Hide_Escalation_Button-1">
                  {!business?.escalation_btn ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Activate Past Request
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`activate_past_request-1`}
                  checked={business?.activate_past_request}
                  name="activate_past_request"
                  onChange={(e) => this.handleSwitch (e, "activate_past_request")}
                  className={classNames(
                    business?.activate_past_request ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.activate_past_request ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="activate_past_request-1">
                  {business?.activate_past_request ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Turn off SMS for account
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`sms_notifications-1`}
                  checked={!business?.sms_notifications}
                  name="sms_notifications"
                  onChange={(e) => this.handleSwitch (e, "sms_notifications")}
                  className={classNames(
                    !business?.sms_notifications ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      !business?.sms_notifications ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="sms_notifications-1">
                  {!business?.sms_notifications ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Activate SMS Notifications Restriction
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`sms_notifications_restriction-1`}
                  checked={business?.sms_notifications_restriction}
                  name="sms_notifications_restriction"
                  onChange={(e) => this.handleSwitch (e, "sms_notifications_restriction")}
                  className={classNames(
                    business?.sms_notifications_restriction ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none', (business?.sms_notifications == "true" || business?.sms_notifications == true)? '' : 'cursor-not-allowed'
                  )}
                  disabled={(business?.sms_notifications == "true" || business?.sms_notifications == true)? false: true}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.sms_notifications_restriction ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className={`text-sm ml-2 ${(business?.sms_notifications == "true" || business?.sms_notifications == true)? '' : 'text-gray-400'}`} htmlFor="sms_notifications_restriction-1">
                  {business?.sms_notifications_restriction ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            {(business?.sms_notifications_restriction == "true" || business?.sms_notifications_restriction == true) && (business?.sms_notifications == "true" || business?.sms_notifications == true) &&
              <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                  SMS Notification Restriction Days
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <input type="number" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" placeholder = "Enter Number" min="1" name="sms_notifications_restriction_days" value = {business?.sms_notifications_restriction_days} onChange={(e) => this.handleChange(e)} onBlur={(e) => this.handleOnBlur(e)}/>  
                </div>
              </div>
            }
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Activate Messenger Feature
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                <Switch
                  id={`new_messenger-1`}
                  checked={business?.new_messenger}
                  name="new_messenger"
                  onChange={(e) => this.handleSwitch (e, "new_messenger")}
                  className={classNames(
                    business?.new_messenger ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.new_messenger ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="new_messenger-1">
                  {business?.new_messenger ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Activate Reports
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`reports-1`}
                  checked={business?.reports}
                  name="reports"
                  onChange={(e) => this.handleSwitch (e, "reports")}
                  className={classNames(
                    business?.reports ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.reports ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="reports-1">
                  {business?.reports ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500">
                Do you want to require time off approval (Manager Board feature) before users can submit a sub request?
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`manager_request_gate-1`}
                  checked={business?.manager_request_gate}
                  name="manager_request_gate"
                  onChange={(e) => this.handleSwitch (e, "manager_request_gate")}
                  className={classNames(
                    business?.manager_request_gate ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.manager_request_gate ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="manager_request_gate-1">
                  {business?.manager_request_gate ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            {(business?.manager_request_gate == "true" || business?.manager_request_gate == true) &&
              <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                  When do you want the Manager Board to be on?
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2'>
                  <select name="mrg_days" onChange={(e) => this.handleChange(e)} defaultValue={business?.mrg_days} className='lg:w-80 form-select bg-white block w-full pl-3 pr-10 py-2 h-9 text-base border border-gray-300  sm:text-sm rounded-md shadow-sm'>
                    <option value="">Select Weeks</option>
                    {[1, 2, 3, 4, 5, 6, 7].map((item, i) =>
                      <option key={i} value={item.toString()}>{item}</option>
                    )}
                  </select>  
                </div>
              </div>
            }
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Activate Api Synching
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`api_synch-1`}
                  checked={business?.api_synch}
                  name="api_synch"
                  onChange={(e) => this.handleSwitch (e, "api_synch")}
                  className={classNames(
                    business?.api_synch ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.api_synch ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="api_synch-1">
                  {business?.api_synch ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 pt-5 pb-3'>
              <label className="block font-medium text-gray-700 sm:mt-px sm:pt-2 text-md">
                Activate Integrations
              </label>
              <label className="block font-medium text-gray-500 sm:mt-px sm:pt-2 text-sm">
                {`(${!business?.abc_integration && !business?.crunch_integration && !business?.mbo_integration && !business?.marianatek_integration && !business?.fisikal_integration && !business?.clubready_integration ? 
                  'Basic Business' 
                :
                business?.abc_integration && !business?.crunch_integration && !business?.mbo_integration && !business?.marianatek_integration && !business?.fisikal_integration && !business?.clubready_integration ?
                  'ABC Integrated'
                :
                !business?.abc_integration && business?.crunch_integration && !business?.mbo_integration && !business?.marianatek_integration && !business?.fisikal_integration && !business?.clubready_integration ?
                  'Crunch Integrated'
                :
                !business?.abc_integration && !business?.crunch_integration && business?.mbo_integration && !business?.marianatek_integration && !business?.fisikal_integration && !business?.clubready_integration ?
                  'MBO Integrated'
                :
                !business?.abc_integration && !business?.crunch_integration && !business?.mbo_integration && business?.marianatek_integration && !business?.fisikal_integration && !business?.clubready_integration ?
                  'Mariana Tek Integrated'
                :
                !business?.abc_integration && !business?.crunch_integration && !business?.mbo_integration && !business?.marianatek_integration && business?.fisikal_integration && !business?.clubready_integration ?
                  'Fisikal Integrated'
                :
                !business?.abc_integration && !business?.crunch_integration && !business?.mbo_integration && !business?.marianatek_integration && !business?.fisikal_integration && business?.clubready_integration &&
                  'Club Ready Integrated'
                })`}
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2'>
              </div>
            </div>
            {/* ABC Integration */}
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 sm:ml-5">
                Activate ABC Integration
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`abc_integration-1`}
                  checked={business?.abc_integration}
                  name="abc_integration"
                  onChange={(e) => this.handleIntegrationSwitch (e, "abc_integration")}
                  className={classNames(
                    business?.abc_integration ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.abc_integration ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="abc_integration-1">
                  {business?.abc_integration ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            {(business?.abc_integration == "true" || business?.abc_integration == true) &&
              <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-5'>
                <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 sm:ml-5 ">
                  ABC User Sync Filters
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2'>
                  <select name="active_filter" onChange={(e) => this.handleChange(e)} defaultValue={business?.active_filter} className='form-select lg:w-80 bg-white block w-full pl-3 pr-10 py-2 h-9 text-base border border-gray-300  sm:text-sm rounded-md shadow-sm'>
                    <option value="">Select Active</option>
                    <option value="1">Active</option>
                    <option value="0">Not Active</option>
                  </select> 
                  <input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-xs" placeholder = "Enter Departments with comma separated" name = "departments_filter" onChange={(e) => this.handleChange(e)} defaultValue={business?.departments_filter}/>
                </div>
              </div>
            }
            {/* Crunch Integration */}
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3 items-center'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 sm:ml-5">
                Activate Crunch Integration
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-3 flex gap-2 items-center'>
                <Switch
                  id={`crunch_integration-1`}
                  checked={business?.crunch_integration}
                  name="crunch_integration"
                  onChange={(e) => this.handleIntegrationSwitch (e, "crunch_integration")}
                  className={classNames(
                    business?.crunch_integration ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.crunch_integration ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='ml-2' htmlFor="crunch_integration-1">
                  {business?.crunch_integration ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            {/* Fisikal Integration */}
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3 items-center'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 sm:ml-5">
                Activate Fisikal Integration
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-3 flex gap-2 items-center'>
                <Switch
                  id={`fisikal_integration-1`}
                  checked={business?.fisikal_integration}
                  name="fisikal_integration"
                  onChange={(e) => this.handleIntegrationSwitch (e, "fisikal_integration")}
                  className={classNames(
                    business?.fisikal_integration ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.fisikal_integration ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='ml-2' htmlFor="fisikal_integration-1">
                  {business?.fisikal_integration ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            {/* Mindbody Integration */}
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 sm:ml-5">
                Activate Mindbody Integration
              </label>
              <div className='mt-1 item-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`mbo_integration-1`}
                  checked={business?.mbo_integration}
                  name="mbo_integration"
                  onChange={(e) => this.handleIntegrationSwitch (e, "mbo_integration")}
                  className={classNames(
                    business?.mbo_integration ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.mbo_integration ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="mbo_integration-1">
                  {business?.mbo_integration ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            {/* Mariana Tek Integration */}
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 sm:ml-5">
                Activate Mariana Tek Integration
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`marianatek_integration-1`}
                  checked={business?.marianatek_integration}
                  name="marianatek_integration"
                  onChange={(e) => this.handleIntegrationSwitch (e, "marianatek_integration")}
                  className={classNames(
                    business?.marianatek_integration ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.marianatek_integration ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="marianatek_integration-1">
                  {business?.marianatek_integration ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            {(business?.abc_integration == "true" || business?.abc_integration == true) &&
              <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                  Activate Attendance Feature
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2'>
                  <Switch
                    id={`attendance_feature-1`}
                    checked={business?.attendance_feature}
                    name="attendance_feature"
                    onChange={(e) => this.handleSwitch(e, "attendance_feature")}
                    className={classNames(
                      business?.attendance_feature ? 'bg-gray-900' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                    )}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        business?.attendance_feature ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                  <label className='text-sm ml-2' htmlFor="attendance_feature-1">
                    {business?.attendance_feature ? 'Yes' : 'No'}
                  </label>
                </div>
              </div>
            }
            {/* Club Ready Integration */}
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 sm:ml-5">
                Activate Club Ready Integration
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`clubready_integration-1`}
                  checked={business?.clubready_integration}
                  name="clubready_integration"
                  onChange={(e) => this.handleIntegrationSwitch (e, "clubready_integration")}
                  className={classNames(
                    business?.clubready_integration ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.clubready_integration ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="clubready_integration-1">
                  {business?.clubready_integration ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Activate Two-way Syncing
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`two_way_syncing_feature-1`}
                  checked={business?.two_way_syncing_feature}
                  name="two_way_syncing_feature"
                  onChange={(e) => this.handleSwitch (e, "two_way_syncing_feature")}
                  className={classNames(
                    business?.two_way_syncing_feature ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.two_way_syncing_feature ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="two_way_syncing_feature-1">
                  {business?.two_way_syncing_feature ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Download Future Sub Requests
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-3 flex flex-wrap gap-2'>
                <div className="lg:w-80 relative messenger-box py-1.5">
                  <DatePicker
                    value = {future_request_start}
                    name = "hire_date"
                    className=" border rounded-md px-2 w-full shadow-sm h-10 border-gray-300 pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                    autoFocus={false}
                    format="M/d/yyyy"
                    onChange={(e) => this.handleDateChange1(e)}
                    calendarType="US"
                    clearIcon={null}
                  />
                </div> 
                <div className="lg:w-80 relative messenger-box py-1.5">
                  <DatePicker
                    value = {future_request_end}
                    name = "hire_date"
                    className=" border rounded-md px-2 w-full shadow-sm h-10 border-gray-300 pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                    autoFocus={false}
                    format="M/d/yyyy"
                    onChange={(e) => this.handleDateChange2(e)}
                    calendarType="US"
                    clearIcon={null}
                  />
                </div>
                <div className="py-1.5">
                  <a className="bg-dark-blue text-white inline-block py-2 ml-auto border shadow rounded-md cursor-pointer px-3 w-100" onClick={(e) => this.download(e)} >Download</a>
                </div>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Sub Request Sending Time Window Enable
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`enable_request_sending_time_check-1`}
                  checked={business?.enable_request_sending_time_check}
                  name="enable_request_sending_time_check"
                  onChange={(e) => this.handleSwitch (e, "enable_request_sending_time_check")}
                  className={classNames(
                    business?.enable_request_sending_time_check ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.enable_request_sending_time_check ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="enable_request_sending_time_check-1">
                  {business?.enable_request_sending_time_check ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            {(business?.enable_request_sending_time_check == "true" || business?.enable_request_sending_time_check == true) &&
              <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                  Sub Request Sending Time Window
                </label>
                <div className='mt-1 sm:mt-0 sm:col-span-3 flex flex-wrap gap-2 items-center'>
                  <div className="lg:w-28 relative messenger-box py-1.5">
                    <input type="number" min="0" id ="days_in_advance" className="border rounded-md px-2 py-1.5 w-full shadow-sm" name="days_in_advance" value = {business?.days_in_advance} onChange={(e) => this.handleChange(e)} onBlur={(e) => this.handleOnBlur(e)}/>
                  </div> 
                  <label className='text-sm font-medium text-gray-500 mr-2 py-1.5'>Days in Advance</label>
                  <label className='text-sm font-medium text-gray-500 py-1.5'>Up Until</label>
                  <div className="lg:w-28 relative messenger-box py-1.5">
                    <input type="number" min="0" id ="time_before_class" className="border rounded-md px-2 py-1.5 w-full shadow-sm" name="time_before_class" value = {business?.time_before_class} onChange={(e) => this.handleChange(e)} onBlur={(e) => this.handleOnBlur(e)}/>
                  </div>
                  <div className="lg:w-28 relative messenger-box py-1.5">
                    <select name="time_before_class_type" onChange={(e) => this.handleChange(e)} defaultValue={business?.time_before_class_type} className='form-select bg-white block w-full pl-3 pr-10 py-2 h-9 text-base border border-gray-300  sm:text-sm rounded-md shadow-sm'>
                      <option value="days">Days</option>
                      <option value="hours">Hours</option>
                    </select>
                  </div>
                  <label className='text-sm font-medium text-gray-500 py-1.5'>Time before class</label>
                </div>
              </div>
            }
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Activate Comments Feature
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`comments_feature-1`}
                  checked={business?.comments_feature}
                  name="comments_feature"
                  onChange={(e) => this.handleSwitch (e, "comments_feature")}
                  className={classNames(
                    business?.comments_feature ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.comments_feature ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="comments_feature-1">
                  {business?.comments_feature ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Turn off notifications for users who have not activated
              </label>
              <div className='mt-1 items-center sm:mt-0 sm:col-span-2 flex gap-2'>
                <Switch
                  id={`active_users_invite-1`}
                  checked={business?.active_users_invite}
                  name="active_users_invite"
                  onChange={(e) => this.handleSwitch (e, "active_users_invite")}
                  className={classNames(
                    business?.active_users_invite ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.active_users_invite ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="active_users_invite-1">
                  {business?.active_users_invite ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Turn off notifications for account
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                <Switch
                  id={`turn_off_notifications-1`}
                  checked={business?.turn_off_notifications}
                  name="turn_off_notifications"
                  onChange={(e) => this.handleSwitch (e, "turn_off_notifications")}
                  className={classNames(
                    business?.turn_off_notifications ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.turn_off_notifications ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="turn_off_notifications-1">
                  {business?.turn_off_notifications ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Activate Request Sub Button
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                <Switch
                  id={`enable_request_sub-1`}
                  checked={business?.enable_request_sub}
                  name="enable_request_sub"
                  onChange={(e) => this.handleSwitch (e, "enable_request_sub")}
                  className={classNames(
                    business?.enable_request_sub ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.enable_request_sub ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="enable_request_sub-1">
                  {business?.enable_request_sub ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Activate Availability Feature
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                <Switch
                  id={`availability_feature-1`}
                  checked={business?.availability_feature}
                  name="availability_feature"
                  onChange={(e) => this.handleSwitch (e, "availability_feature")}
                  className={classNames(
                    business?.availability_feature ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.availability_feature ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="availability_feature-1">
                  {business?.availability_feature ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            {(business?.availability_feature == "true" || business?.availability_feature == true) &&
              <React.Fragment>
                <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start py-3'>
                  <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                    <label className='text-sm font-medium text-gray-500 py-1.5'>From</label>
                    <div className="lg:w-32 relative messenger-box py-1.5 mr-2">
                      <select 
                        name = "time_from"
                        onChange={(e) => this.handleStart(e)} 
                        value={time_from} 
                        className='form-select bg-white block w-full pl-3 pr-5 py-2 h-9 text-base border border-gray-300  sm:text-sm rounded-md shadow-sm'>
                          {hour_range.map((fromHour, i) =>
                            <option key =  {i} value = {fromHour}>{fromHour}</option>
                          )}
                      </select>
                    </div>
                    <label className='text-sm font-medium text-gray-500 py-1.5'>To</label>
                    <div className="lg:w-32 relative messenger-box py-1.5">
                      <select 
                        name = "time_to"
                        onChange={(e) => this.handleEnd(e)}
                        value={time_to} 
                        className='form-select bg-white block w-full pl-3 pr-5 py-2 h-9 text-base border border-gray-300  sm:text-sm rounded-md shadow-sm'>
                          {to_hour_range.map((toHour, i) =>
                            <option key =  {i} value = {toHour}>{toHour}</option>
                          )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                  <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                    Activate Block Availability
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                    <Switch
                      id={`block_availability_feature-1`}
                      checked={business?.block_availability_feature}
                      name="block_availability_feature"
                      onChange={(e) => this.handleSwitch (e, "block_availability_feature")}
                      className={classNames(
                        business?.block_availability_feature ? 'bg-gray-900' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                      )}
                    >
                      <span className="sr-only">Use setting</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          business?.block_availability_feature ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                        )}
                      />
                    </Switch>
                    <label className='text-sm ml-2' htmlFor="availability_feature-1">
                      {business?.block_availability_feature ? 'Yes' : 'No'}
                    </label>
                  </div>
                </div>
                {(business?.block_availability_feature == "true" || business?.block_availability_feature == true) &&
                  <React.Fragment>
                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start pb-3'>
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-3 xl:col-span-2 flex gap-2 items-center'>
                        <div className="w-full">
                          {blockDayIndex.length > 0 &&
                            <React.Fragment>
                              {blockDayIndex.map((day, i) => 
                                (
                                  <div key = {i} className="flex flex-wrap py-4 border-t border-gray-200 relative">
                                    <div className="w-1/6 sm:1/6 md:w-1/6 lg:mr-0 md:mr-0 text-sm text-gray-900 font-medium">
                                      <label className="inline-flex items-center">
                                        {this.dayName(day.day)}
                                      </label>
                                    </div>
                                    <div className="w-full lg:w-3/6 md:mt-7 mt-2">
                                      {day.availability_slots_attributes.map((hours, j) => 
                                        <div key = {j} className="flex mb-2">
                                          <div className="w-full">
                                            <div className="flex">
                                              <div className="text-sm font-semibold text-gray-700 pb-2 pt-3 pl-1.5">From</div>
                                            </div>
                                            <div className="flex -mx-1 mb-2">
                                              <div className="w-full px-1 md:mb-0">
                                                <div className="relative">
                                                  <select 
                                                    className = "form-select text-sm p-2 bg-white text-gray-700 rounded-md border shadow-sm w-full focus:outline-none"
                                                    name = "time_from"
                                                    onChange={(e) => this.handleFrom(e, day.day, j)} 
                                                    value={hours?.time_from}
                                                  >
                                                  {hours.from_range.map((start_time, i) =>
                                                    <option key = {i} value = {start_time}>{start_time}</option>
                                                  )}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-full md:ml-3.5 ml-2.5">
                                            <div className="flex">
                                              <div className="text-sm font-semibold text-gray-700 pb-2 pt-3 pl-1.5">To</div>
                                            </div>
                                            <div className="flex -mx-1 mb-2">
                                              <div className="w-full px-1 md:mb-0">
                                                <div className="relative">
                                                  <select 
                                                    className = "form-select text-sm p-2 bg-white text-gray-700 rounded-md border shadow-sm w-full focus:no-focus"
                                                    name = "time_to"
                                                    onChange={(e) => this.handleTo(e, day.day, j)} 
                                                    value={hours?.time_to}
                                                  >
                                                  {hours.to_range.map((end_time, i) =>
                                                    <option key = {i} value = {end_time}>{end_time}</option>
                                                  )}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-1/12 flex items-center mt-7 ml-2.5">
                                            <a className="cursor-pointer" onClick = {(e) => this.deleteSlot(e,j, day.day)}>
                                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                              </svg>
                                            </a>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="text-right absolute right-0">
                                      {!day.disable ?
                                        <button className="font-medium inline-flex bg-dark-blue text-xs text-white py-2 px-2 rounded-md h-8 gap-1.5" onClick = {(e) => this.handleAdd(e, day.day)} disabled = {day.disable}>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                          </svg>
                                          Add Time Block
                                        </button>
                                      :
                                        <React.Fragment>
                                          {((day.disable && day.prev_disable)) &&
                                            <button className="font-medium inline-flex  text-xs text-white py-2 px-2 rounded-md h-8 gap-1.5 cursor-default bg-gray-300" onClick = {(e) => this.handleAdd(e, day.day)} disabled = {day.disable}>
                                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                              </svg>
                                              Add Time Block
                                            </button>
                                          }
                                          {day.disable && !day.prev_disable &&
                                            <button className="font-medium inline-flex bg-dark-blue text-xs text-white py-2 px-2 rounded-md h-8 gap-1.5" onClick = {(e) => this.handleAddPrevSlot(e, day.day)} disabled = {day.prev_disable}>
                                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                              </svg>
                                              Add Time Block
                                            </button>
                                          }
                                        </React.Fragment>
                                      }
                                      {!day?.prev_disable && day.disable}
                                        
                                    </div>
                                  </div>
                                )
                              )}
                            </React.Fragment>
                          }
                        </div>
                      </div>
                    </div>
                    <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 pt-3'>
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px">
                        Subbing / Permanent
                      </label>
                    </div>
                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start pb-3'>
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px text-right">
                        Subbing Availability
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                        <Switch
                          id={`block_subbing_availability-1`}
                          checked={business?.block_subbing_availability}
                          name="block_subbing_availability"
                          onChange={(e) => this.handleSwitch (e, "block_subbing_availability")}
                          className={classNames(
                            business?.block_subbing_availability ? 'bg-gray-900' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                          )}
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              business?.block_subbing_availability ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                          />
                        </Switch>
                        <label className='text-sm ml-2' htmlFor="availability_feature-1">
                          {business?.block_subbing_availability ? 'Yes' : 'No'}
                        </label>
                      </div>
                    </div>
                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start pb-3'>
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px text-right">
                        Permanent Availability
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                        <Switch
                          id={`block_permanent_availability-1`}
                          checked={business?.block_permanent_availability}
                          name="block_permanent_availability"
                          onChange={(e) => this.handleSwitch (e, "block_permanent_availability")}
                          className={classNames(
                            business?.block_permanent_availability ? 'bg-gray-900' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                          )}
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              business?.block_permanent_availability ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                          />
                        </Switch>
                        <label className='text-sm ml-2' htmlFor="availability_feature-1">
                          {business?.block_permanent_availability ? 'Yes' : 'No'}
                        </label>
                      </div>
                    </div>
                  </React.Fragment>
                }
              </React.Fragment>
            }
            {(business?.new_messenger == "true" || business?.new_messenger == true) &&
              <React.Fragment>
                  <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                    <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                      Activate Group Chat Feature
                    </label>
                    <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                      <Switch
                        id={`group_chat_feature-1`}
                        checked={business?.group_chat_feature}
                        name="group_chat_feature"
                        onChange={(e) => this.handleSwitch (e, "group_chat_feature")}
                        className={classNames(
                          business?.group_chat_feature ? 'bg-gray-900' : 'bg-gray-200',
                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                        )}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            business?.group_chat_feature ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                          )}
                        />
                      </Switch>
                      <label className='text-sm ml-2' htmlFor="group_chat_feature-1">
                        {business?.group_chat_feature ? 'Yes' : 'No'}
                      </label>
                    </div>
                  </div>
                {(business?.group_chat_feature == "true" || business?.group_chat_feature == true) &&
                  <React.Fragment>
                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start pt-2'>
                      <label htmlFor='stream_app_id' className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                        Stream Application Key
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-3 flex flex-wrap gap-2 items-center'>
                        <div className="lg:w-80 relative messenger-box py-1.5">
                          <input type="text" id ="stream_app_id" placeholder='Stream application key' className="border rounded-md px-2 py-1.5 w-full shadow-sm" name="stream_app_id" value = {business?.stream_app_id} onChange={(e) => this.handleChange(e)}/>
                        </div> 
                      </div>
                    </div>
                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start pt-2 pb-3'>
                      <label htmlFor='stream_secret' className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                        Stream Application Secret
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-3 flex flex-wrap gap-2 items-center'>
                        <div className="lg:w-80 relative messenger-box py-1.5">
                          <input type={showStreamSecret ? 'text' : 'password'} id ="stream_secret" placeholder='Stream application secret'  className="border rounded-md pl-2 pr-9 py-1.5 w-full shadow-sm" name="stream_secret" value = {business?.stream_secret} onChange={(e) => this.handleChange(e)}/>
                          {!showStreamSecret &&
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 absolute right-3 top-4" onClick={() => this.setState({showStreamSecret: true})}>
                              <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                              <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clipRule="evenodd" />
                            </svg>
                          }
                          {showStreamSecret &&
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 absolute right-3 top-4" onClick={() => this.setState({showStreamSecret: false})}>
                              <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                              <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                              <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                            </svg>
                          }
                        </div> 
                      </div>
                    </div>
                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                        Enable Multi Tenant Get Stream App
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                        <Switch
                          id={`enable_multi_tenant_app`}
                          checked={business?.multi_tenant_enabled}
                          name="multi_tenant_enabled"
                          onChange={(e) => this.handleSwitch (e, "multi_tenant_enabled")}
                          className={classNames(
                            business?.multi_tenant_enabled ? 'bg-gray-900' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                          )}
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              business?.multi_tenant_enabled ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                          />
                        </Switch>
                        <label className='text-sm ml-2' htmlFor="enable_multi_tenant_app">
                          {business?.multi_tenant_enabled ? 'Yes' : 'No'}
                        </label>
                      </div>
                    </div>
                    <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
                      <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                        Update User's GetStream Token
                      </label>
                      <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                        <Switch
                          id={`update_users_get_stream_token`}
                          checked={business?.update_users_stream_token}
                          name="update_users_stream_token"
                          onChange={(e) => this.handleSwitch (e, "update_users_stream_token")}
                          className={classNames(
                            business?.update_users_stream_token ? 'bg-gray-900' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                          )}
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            aria-hidden="true"
                            className={classNames(
                              business?.update_users_stream_token ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            )}
                          />
                        </Switch>
                        <label className='text-sm ml-2' htmlFor="update_users_get_stream_token">
                          {business?.update_users_stream_token ? 'Yes' : 'No'}
                        </label>
                      </div>
                    </div>
                  </React.Fragment>
                }
              </React.Fragment>
            }
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Activate My Schedule Feature
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                <Switch
                  id={`my_schedule_feature-1`}
                  checked={business?.my_schedule_feature}
                  name="my_schedule_feature"
                  onChange={(e) => this.handleSwitch (e, "my_schedule_feature")}
                  className={classNames(
                    business?.my_schedule_feature ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.my_schedule_feature ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="my_schedule_feature-1">
                  {business?.my_schedule_feature ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Make “Employee ID” required?
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                <Switch
                  id={`employee_id_optional-1`}
                  checked={!business?.employee_id_optional}
                  name="employee_id_optional"
                  onChange={(e) => this.handleSwitch (e, "employee_id_optional")}
                  className={classNames(
                    !business?.employee_id_optional ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      !business?.employee_id_optional ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="employee_id_optional-1">
                  {!business?.employee_id_optional ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Activate calendar sync feature
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                <Switch
                  id={`calendar_sync_feature-1`}
                  checked={business?.calendar_sync_feature}
                  name="calendar_sync_feature"
                  onChange={(e) => this.handleSwitch (e, "calendar_sync_feature")}
                  className={classNames(
                    business?.calendar_sync_feature ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.calendar_sync_feature ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="calendar_sync_feature-1">
                  {business?.calendar_sync_feature ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
                Activate documentation feature
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                <Switch
                  id={`documentations_feature-1`}
                  checked={business?.documentations_feature}
                  name="documentations_feature"
                  onChange={(e) => this.handleSwitch (e, "documentations_feature")}
                  className={classNames(
                    business?.documentations_feature ? 'bg-gray-900' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      business?.documentations_feature ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <label className='text-sm ml-2' htmlFor="documentations_feature-1">
                  {business?.documentations_feature ? 'Yes' : 'No'}
                </label>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start border-t border-gray-200 py-5'>
              <label className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2"></label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div>
                  <button className={` text-white inline-block px-4 py-2 ml-auto border shadow rounded-md cursor-pointer w-44 ${updateButtonDisabled ? 'button-disabled bg-gray-700' : 'bg-dark-blue'}`} onClick={(e) => this.submit(e)} disabled={updateButtonDisabled}>
                    <div className="flex items-center justify-center gap-1 relative">
                      {updateButtonDisabled && 
                        <div className="flex items-center -left-2.5 justify-center absolute">
                          <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        </div>
                      }
                      <div className='text-white text-base'>Update Settings</div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}

import React from "react";
import { statusColours } from '../helpers';

export default function StatusBubble({status}) {

  const statusClasses = (status) => {
    return statusColours[status.toLowerCase()];
  }

  return (
    <div className={`${statusClasses(status)} text-xs px-2.5 py-1.5 rounded-xl font-medium truncate`}>{status}</div>
  )
}
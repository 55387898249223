import React, { Fragment, useState, useEffect } from "react";
import { checkInstructorDisableRows } from './../../../../../../helpers';

export default function InstructorTdComponent(props) {
	const { schedule, instructorDaysInAdvanceCheckDate, netgymSchedules, newSchedules, newSubRequest, user, selectedSchedules, handleSelectSchedules, netgymSchedulesEventIds, handleClickReopen } = props
	const [ newScheduleRecord, setNewScheduleRecord ] = useState(newSchedules[schedule.class_id] ? newSchedules[schedule.class_id][0] : {})
	const [ requestingInstructorId, setRequestingInstructorId ] = useState(newSubRequest?.requestingInstructor?.id)

	useEffect(() => {
		if (newSubRequest?.requestingInstructor?.id !== '') setRequestingInstructorId(newSubRequest?.requestingInstructor?.id)
	}, [newSubRequest])

	useEffect(() => {
		setNewScheduleRecord(newSchedules[schedule.class_id] ? newSchedules[schedule.class_id][0] : {})
	}, [newSchedules])
 
  return(
		<Fragment>
			<td className={`px-3 py-4 text-sm font-medium text-gray-900 ${checkInstructorDisableRows(schedule, netgymSchedules) ? ((newSchedules[schedule.class_id] && newScheduleRecord['status'] == 'confirm') ? '' : 'opacity-50') : ''}`}>
				{(
					!(newSchedules[schedule.class_id] && newScheduleRecord['status'] == 'confirm') && 
					!(newSchedules[schedule.class_id] &&
						newScheduleRecord['status'] != 'confirm' &&
						newScheduleRecord['reopened'] == true && 
						newScheduleRecord['requesting_instructor_id'] == requestingInstructorId
					)
				 ) && (
					!(checkInstructorDisableRows(schedule, netgymSchedules)) ?
						<input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1 ${netgymSchedules?.includes(schedule?.class_id) ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`} value={schedule?.class_id} checked={selectedSchedules.includes(schedule?.class_id)} onChange={(e) => handleSelectSchedules(e, schedule)} disabled={netgymSchedules?.includes(schedule?.class_id)}/>
					:
						<input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1 opacity-50 cursor-not-allowed`} value={schedule?.class_id} checked={selectedSchedules.includes(schedule?.class_id)} disabled/>
				)}
				{newSchedules[schedule.class_id] && newScheduleRecord['status'] == 'confirm' &&
					<Fragment>
					{(!schedule.up_untill_check && !schedule.blackout_check && !schedule?.days_in_advance_check && !newScheduleRecord['mrg_reopened']) &&
						(<button className="flex justify-center items-center rounded-2xl px-2 py-1 bg-white text-xs text-gray-900 border-2 border-gray-300 font-semibold" onClick={(e) => handleClickReopen(e, netgymSchedulesEventIds[schedule?.class_id], schedule?.is_invite_more_case)}>Reopen</button>)
          }
					{((schedule.up_untill_check || schedule.blackout_check || schedule?.days_in_advance_check || newScheduleRecord['mrg_reopened'])) &&
						(<button className="flex justify-center items-center rounded-2xl px-2 py-1 bg-white text-xs text-gray-900 border-2 border-gray-300 font-semibold opacity-50 cursor-not-allowed">Reopen</button>)
					}
					</Fragment>
				}
				{(
					newSchedules[schedule.class_id] &&
					newScheduleRecord['status'] != 'confirm' && 
					newScheduleRecord['reopened'] == true && 
					newScheduleRecord['requesting_instructor_id'] == requestingInstructorId
				) &&
					<button className="flex justify-center items-center rounded-2xl px-2 py-1 bg-white text-xs text-gray-900 border-2 border-gray-300 font-semibold opacity-50 cursor-not-allowed">Reopened</button>
				}
			</td>
		</Fragment>
	)
}
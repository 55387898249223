import React, { useState, Fragment, useEffect } from "react";
import Calendar from 'rc-year-calendar';
import { checkCurentMonth, currentYear, checkMonthNameAfterAddition, checkMonthNameAfterCertainAddition, defaultPolicy } from './helpers';
import SummaryComponent from './components/summaryComponent';
import SwitchCommonComponent from './components/switchCommonComponent';
import YearViewComponent from './components/yearViewComponent';
import NumberInputComponent from './components/numberInputComponent';
import DeleteTimeFrameComponent from './components/deleteTimeFrameComponent';
import SkillDropDownComponent from './components/skillDropDownComponent';

export default function SubPolicyComponent(props) {
  const { timeFrame, i, disabledPage, handleDeleteTimeFrame, updateSubbingTimeFrames, skillList, updateSkillOnTimeFrames } = props

  const todaysDate = new Date();
  const currentMonth = checkCurentMonth(new Date());
  const [ subPolicy, setSubPolicy ] = useState(timeFrame || defaultPolicy())
  const [ selectedYear, setSelectedYear ] = useState(parseInt(currentYear))

  useEffect(() => {
    setSubPolicy(timeFrame || defaultPolicy())
  }, [timeFrame])

  const handleSwitch = (e, name) => {
    setSubPolicy(prevSubPolicy => (
      {...prevSubPolicy, [name]: !prevSubPolicy[name]}
    ))
  }

  useEffect(() => {
    updateSubbingTimeFrames(i, subPolicy)
  }, [subPolicy])

  const handleChange = (e) => {
    const { name, value } = e.target

    if(name === 'additionalMonths'){
      let val = 0
      if(value !== ''){
        if(value > 5){
          return
        }else{
          let result = value.replace(/^0+/, '');
          val = result
        }
      }else{
        val = 0
      }
      let certainDateAdditionalMonthVal = subPolicy?.certainDateAdditionalMonth === '' ? 0 : subPolicy?.certainDateAdditionalMonth
      let today = todaysDate
      let dateCopy = new Date(today.getTime());
      let dateSecCopy = new Date(today.getTime());
      let addedMonth = checkMonthNameAfterAddition(dateCopy, parseInt(val == '' ? 0 : val))
      let certainDateAdditionalMonth = checkMonthNameAfterCertainAddition (dateSecCopy, parseInt(val == '' ? 0 : val), parseInt(certainDateAdditionalMonthVal))
      setSubPolicy(prevSubPolicy => (
        {...prevSubPolicy, 
          additionalMonths: val,
          additionalMonthsName: addedMonth,
          certainDateAdditionalMonthName: certainDateAdditionalMonth,
        }
      ))
    }else if(name === 'certainDate'){
      let val = ''
      if(value !== ''){
        if(value > 30 || value < 1){
          return
        }else{
          let result = value.replace(/^0+/, '');
          val = result
        }
      }
      setSubPolicy(prevSubPolicy => (
        {...prevSubPolicy, 
          certainDate: val
        }
      ))
    }else if(name === 'certainDateAdditionalMonth'){
      let val = 0
      if(value !== ''){
        if(value > 3){
          return
        }else{
          let result = value.replace(/^0+/, '');
          val = result
        }
      }else{
        val = 0
      }
      let addedMonthVal = subPolicy?.additionalMonths === '' ? 0 : subPolicy?.additionalMonths
      let today = todaysDate
      let dateCopy = new Date(today.getTime());
      let addedMonth = checkMonthNameAfterCertainAddition (dateCopy, parseInt(val == '' ? 0 : val), parseInt(addedMonthVal))
      setSubPolicy(prevSubPolicy => (
        {...prevSubPolicy, 
          certainDateAdditionalMonth: val,
          certainDateAdditionalMonthName: addedMonth
        }
      ))
    }else if(name === 'timeBeforeClass'){
      let val = 0
      if(value !== ''){
        if(subPolicy?.timeBeforeClassType === 'days' && value > 30 || subPolicy?.timeBeforeClassType === 'hours' && value > 24){
          return
        }else{
          let result = value.replace(/^0+/, '');
          val = result
        }
      }else{
        val = 0
      }
      setSubPolicy(prevSubPolicy => (
        {...prevSubPolicy, 
          timeBeforeClass: val
        }
      ))
    }else if(name === 'timeBeforeClassType'){
      setSubPolicy(prevSubPolicy => (
        {...prevSubPolicy, 
          timeBeforeClassType: value
        }
      ))
    }else if(name === 'timeFrameName'){
      setSubPolicy(prevSubPolicy => (
        {...prevSubPolicy, 
          name: value
        }
      ))
    }
  }

  const handleReminderDayKeyDown = (e) => {
    var invalidChars = [
      "-",
      "+",
      "e",
      "."
    ]
    if(invalidChars.includes(e.key)){
      e.preventDefault()
    }
  }

  const handleAdditionalMonthOnBlur = (e) => {
    const { name, value } = e.target
    let val = 0
    if(value === ''){
      val = 0
      if(name === 'additionalMonths'){
        let certainDateAdditionalMonthVal = subPolicy?.certainDateAdditionalMonth === '' ? 0 : subPolicy?.certainDateAdditionalMonth
        let today = todaysDate
        let dateCopy = new Date(today.getTime());
        let dateSecCopy = new Date(today.getTime());
        let addedMonth = checkMonthNameAfterAddition(dateCopy, parseInt(val))
        let certainDateAdditionalMonth = checkMonthNameAfterCertainAddition (dateSecCopy, parseInt(val), parseInt(certainDateAdditionalMonthVal))
        setSubPolicy(prevSubPolicy => (
          {...prevSubPolicy, 
            additionalMonths: val,
            additionalMonthsName: addedMonth,
            certainDateAdditionalMonthName: certainDateAdditionalMonth,
          }
        ))
      }else if(name === 'certainDateAdditionalMonth'){
        let addedMonthVal = subPolicy?.additionalMonths === '' ? 0 : subPolicy?.additionalMonths
        let today = todaysDate
        let dateCopy = new Date(today.getTime());
        let addedMonth =  checkMonthNameAfterCertainAddition (dateCopy, parseInt(val), parseInt(addedMonthVal))
        setSubPolicy(prevSubPolicy => (
          {...prevSubPolicy, 
            certainDateAdditionalMonth: val,
            certainDateAdditionalMonthName: addedMonth
          }
        ))
      }else if(name === 'certainDate'){
        setSubPolicy(prevSubPolicy => (
          {...prevSubPolicy, 
            certainDate: 1
          }
        ))
      }else if(name === 'timeBeforeClass'){
        setSubPolicy(prevSubPolicy => (
          {...prevSubPolicy, 
            timeBeforeClass: val
          }
        ))
      }
    }
  }
  
  const saveCurrentEventOnClick = (e) => {
    if(disabledPage) return
    if(e.events !== undefined && e.events?.length > 0){
      let evt = e.events[0]
      setSubPolicy(prevSubPolicy => (
        {...prevSubPolicy, 
          blackoutDates: prevSubPolicy.blackoutDates.filter(item => item.id != evt.id)
        }
      ))
    }else{
      setSubPolicy(prevSubPolicy => (
        {...prevSubPolicy, 
          blackoutDates: prevSubPolicy.blackoutDates.concat([{ 'id': (prevSubPolicy?.blackoutDates?.length > 0 ? (Math.max(...prevSubPolicy?.blackoutDates.map(evt => evt.id)) + 1) : 0), 'startDate': e.date, 'endDate': e.date, color: 'yellow' }])
        }
      ))
    }
  }

  const handleClickDelete = (e) => {
    e.preventDefault();
    handleDeleteTimeFrame(i)
  }

  const handleSaveSkills = (data) => {
    setSubPolicy(prevSubPolicy => (
      {...prevSubPolicy, 
        skillIds: data
      }
    ))
    updateSkillOnTimeFrames(i, data)
  }

  return (
    <Fragment>
      <div className="mb-2">
        <div className='table pb-3'>
          <div className='text-sm inline mr-3'>Timeframe name  </div>
          <input
            type="text"
            className={`inline mr-3 mt-1 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 block w-32 h-9 sm:text-sm border-gray-300 rounded-md  px-2 ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
            name='timeFrameName'
            value={subPolicy?.name}
            onChange={(e) => handleChange(e)}
            disabled={disabledPage}
          />
        </div>
        <div className='pb-3 flex flex-wrap items-center'>
          <div className='text-sm inline mr-3'>Do you want to apply this timeframe to specific skills?  </div>
          <SkillDropDownComponent i={i} skillIds={subPolicy?.skillIds} handleSaveSkills={handleSaveSkills} disabled={disabledPage} skillList={skillList}/>
        </div>
        <div className='table pb-3'>
          <div className='text-sm inline mr-3'>Always allow sub requests for current month and  </div>
            <NumberInputComponent name={'additionalMonths'} value={subPolicy?.additionalMonths} min={0} max={5} onChange={handleChange} onKeyDown={handleReminderDayKeyDown} onBlur={handleAdditionalMonthOnBlur} disabled={disabledPage}/>
          <div className='text-sm inline'>additional month(s)</div>
        </div>     
        <div className='table pb-3'>
          <div className='text-sm inline mr-3'>Do you want to release future months on a certain date within the current month?</div>
          <SwitchCommonComponent id={`allowCertainDate-${i}`} value={subPolicy?.allowCertainDate} name={"allowCertainDate"} onChange={handleSwitch} disabledValue={disabledPage}/>
        </div>
        {subPolicy?.allowCertainDate &&
          <div className="table pb-3">
            <div className='text-sm inline mr-3'>On day</div>
            <NumberInputComponent name={'certainDate'} value={subPolicy?.certainDate} min={1} max={30} onChange={handleChange} onKeyDown={handleReminderDayKeyDown} onBlur={handleAdditionalMonthOnBlur} disabled={disabledPage}/>
            <div className='text-sm inline mr-3'>of current month, allow sub request for</div>
            <NumberInputComponent name={'certainDateAdditionalMonth'} value={subPolicy?.certainDateAdditionalMonth} min={0} max={3} onChange={handleChange} onKeyDown={handleReminderDayKeyDown} onBlur={handleAdditionalMonthOnBlur} disabled={disabledPage}/>
            <div className='text-sm inline'>additional month(s)</div>
          </div>
        }
        <div className='table pb-3'>
          <div className='text-sm inline mr-3'>Do you want to prevent instructors from sending sub request with in a certain amount of time before the class?</div>
          <SwitchCommonComponent id={`enableRequestSendingTimeCheck-${i}`} value={subPolicy?.enableRequestSendingTimeCheck} name={"enableRequestSendingTimeCheck"} onChange={handleSwitch} disabledValue={disabledPage}/>
        </div>
        {subPolicy?.enableRequestSendingTimeCheck &&
          <div className="table pb-3">
            <div className='text-sm inline mr-3'>Instructors cannot send sub requests within</div>
            <NumberInputComponent name={'timeBeforeClass'} value={subPolicy?.timeBeforeClass} min={1} max={subPolicy?.timeBeforeClassType === 'hours' ? 24 : 30} onChange={handleChange} onKeyDown={handleReminderDayKeyDown} onBlur={handleAdditionalMonthOnBlur} disabled={disabledPage}/>
            <select
              name="timeBeforeClassType"
              value={subPolicy?.timeBeforeClassType}
              onChange={(e) => handleChange(e)}
              className={`inline mr-3 mt-1 shadow-sm border bg-white focus:ring-indigo-500 focus:border-indigo-500 block w-20 h-9 sm:text-sm border-gray-300 rounded-md ${disabledPage ? 'cursor-not-allowed opacity-50' : ''}`}
              disabled={disabledPage}
            >
              <option value="hours">Hours</option>
              <option value='days'>Days</option>
            </select> 
            <div className='text-sm inline'>of the class start time</div>
          </div>
        }
        <SummaryComponent currentMonth={currentMonth} subPolicy={subPolicy}/>
        <div className='table pb-3'>
          <div className='text-sm inline mr-3'>Do you want to enable blackout dates?</div>
          <SwitchCommonComponent id={`enableBlackoutDates-${i}`} value={subPolicy?.enableBlackoutDates} name={"enableBlackoutDates"} onChange={handleSwitch} disabledValue={disabledPage}/>
        </div>
        {subPolicy?.enableBlackoutDates &&
          <div className='items-center gap-3'>
            <div className='text-sm'>
              Please select specific dates in which you do not want instructors to send out sub requests.
            </div>
            <div className='mt-5'>
              <div className='flex justify-between items-center'>
                <div className='ml-2 text-gray-900 font-medium'>
                  {selectedYear}
                </div>
                <div className='mr-2'>
                  <YearViewComponent currentYear={currentYear} selectedYear={selectedYear} setSelectedYear={setSelectedYear}/>
                </div>
              </div>
              <Calendar 
                style="background"
                enableRangeSelection={false}
                enableContextMenu={false}
                year={selectedYear}
                onDayClick={(e) => saveCurrentEventOnClick(e)}
                dataSource={subPolicy?.blackoutDates}
                disabled={disabledPage}
              />
            </div>
          </div>
        }
        {i !== 0 && 
          <DeleteTimeFrameComponent handleClick={handleClickDelete} disabled={disabledPage}/>
        }
      </div>
    </Fragment>
  );
}
import React, { Fragment, useEffect, useState } from "react";
import ClassDropDownComponent from './classDropDownComponent';
import { getTheClassesHash } from '../../../../helpers';

export default function ClassSelectorComponent(props) {
  const { i, newClass, instructorClasses, handleSaveClass } = props
  const [ classOptions, setClassOptions ] = useState(getTheClassesHash(instructorClasses) || [])

  useEffect(() => {
    setClassOptions(getTheClassesHash(instructorClasses))
  }, [instructorClasses])
  
  return(
    <Fragment>
      <div className="flex flex-col gap-2 w-full">
        <div className={`flex ${i > 0 ? 'lg:hidden' : ''} text-base md:text-lg text-black font-semibold`}>
            Class Name
          </div>
        <div>
          <ClassDropDownComponent
            i={i}
            instructorClasses={instructorClasses}
            selectedClsId={newClass?.selectedClass?.id}
            selectedClsName={newClass?.selectedClass?.name}
            selectedSkillId={newClass?.selectedClass?.business_category_id}
            handleSaveClass={(classId, className, skillId, blackOutDates, blockBeforeHours, blockBeforeDays, daysInAdvance) => handleSaveClass(classId, className, skillId, i, blackOutDates, blockBeforeHours, blockBeforeDays, daysInAdvance)}
            classOptions={classOptions}
          />
        </div>
      </div>
    </Fragment>
  )
}
import React, { Fragment } from "react";
import { checkInstructorDisableRows, instructorToolTipMessage } from '../../../../../helpers';
import { Link } from "react-router-dom";
import InstructorTdComponent from './instructorTdComponent';
import ReactTooltip from "react-tooltip";

export default function InstructorTableRecordComponent(props) {
	const { schedules, instructorDaysInAdvanceCheckDate, netgymSchedules, user, newSchedules, selectedSchedules, handleSelectSchedules, handleClickReopen, netgymSchedulesEventIds, eventDetailPath, newSubRequest, business } = props

  return(
		<Fragment>
			{schedules?.map((schedule, i) => 
				<tr 
					key={i} 
					className={`relative ${(checkInstructorDisableRows(schedule, netgymSchedules) === true) ? 'cursor-not-allowed bg-gray-200' : 'bg-white'}`}
					data-tip data-for={`${schedule?.class_id}_${i}_row_tooltip`}
				>
					<InstructorTdComponent schedule={schedule} instructorDaysInAdvanceCheckDate={instructorDaysInAdvanceCheckDate} netgymSchedules={netgymSchedules} newSchedules={newSchedules} newSubRequest={newSubRequest} user={user} selectedSchedules={selectedSchedules} handleSelectSchedules={handleSelectSchedules} netgymSchedulesEventIds={netgymSchedulesEventIds} handleClickReopen={handleClickReopen} />
					<td className={`px-3 py-3 ${(checkInstructorDisableRows(schedule, netgymSchedules)) ? 'opacity-50' : ''}`}>
						<div className='text-sm text-gray-900 font-medium whitespace-nowrap'>
							{schedule?.date}
						</div>
					</td>
					<td className={`px-3 py-3 ${(checkInstructorDisableRows(schedule, netgymSchedules)) ? 'opacity-50' : ''}`}>
						<div className='text-sm text-gray-900 font-medium whitespace-nowrap'>
							{schedule?.time.trim()}
						</div>
					</td>
					<td className='px-3 py-3'>
						{(instructorToolTipMessage(schedule, netgymSchedules) !== '') &&
							<ReactTooltip className='max-w-xs' id={`${schedule?.class_id}_${i}_row_tooltip`} place="top" effect="solid">
								{instructorToolTipMessage(schedule, netgymSchedules)}
							</ReactTooltip>
						}
						<div className='text-sm font-medium text-gray-900'>
							{netgymSchedules?.includes(schedule?.class_id) ?
								<Link to={eventDetailPath(netgymSchedulesEventIds[schedule?.class_id])} className='custom-blue-200 text-sm font-medium'>{schedule?.title}</Link>
								:
								<p className={`${(checkInstructorDisableRows(schedule, netgymSchedules)) ? 'opacity-50' : ''}`}>{schedule?.title}</p>
							}
						</div>
					</td>
					<td className={`px-3 py-3 ${(checkInstructorDisableRows(schedule, netgymSchedules)) ? 'opacity-50' : ''}`}>
						<div className='text-sm font-medium text-gray-900'>
							{schedule?.duration}
						</div>
					</td>
					{!business?.abc_integration &&
						<td className={`px-3 py-3 ${(checkInstructorDisableRows(schedule, netgymSchedules)) ? 'opacity-50' : ''}`}>
							<div className='text-sm font-medium text-gray-900'>
								{schedule?.location}
							</div>
						</td>
					}
				</tr>
			)}
		</Fragment>
	)
}
import React, { Fragment } from "react";

export default function SummaryComponent({currentMonth, subPolicy}) {
  const { additionalMonths, additionalMonthsName, allowCertainDate, certainDate, certainDateAdditionalMonth, certainDateAdditionalMonthName, enableRequestSendingTimeCheck, timeBeforeClass, timeBeforeClassType } = subPolicy

  return (
    <Fragment>
      <p className='text-xs pt-4 pb-5'>{`Here's summary of your policy: Always allow sub requests for current month (${currentMonth}) and ${additionalMonths === '' ? 0 : additionalMonths} additional month${additionalMonths > 1 ? 's' : ''}${additionalMonthsName === '' ? '' : ` (${additionalMonthsName})`}.${allowCertainDate ? ` On the ${certainDate === '' ? '1' : certainDate}th of the current month (${currentMonth}), instructors can begin sending sub requests for ${certainDateAdditionalMonth === '' ? 0 : certainDateAdditionalMonth} more additional month${certainDateAdditionalMonth > 1 ? 's' : ''}${certainDateAdditionalMonthName === '' ? '' : ` (${certainDateAdditionalMonthName})`}.`:''}${enableRequestSendingTimeCheck ? ` Instructors cannot send sub requests within ${timeBeforeClass === '' ? '0' : timeBeforeClass} ${timeBeforeClassType === 'hours' ? `${timeBeforeClass > 1 ? timeBeforeClassType : 'hour'}` : `${timeBeforeClass > 1 ? timeBeforeClassType : 'day'}`} of the start time of the class.`:''}`}  </p>
    </Fragment>
  )
}
import React, { Fragment } from "react";
import HistoryCard from "./historyCard";
import { ChevronDownIcon } from '@heroicons/react/outline'

export default function HistoryComponent(props) {

  return(
    <Fragment>
      <div className="w-full p-4 bg-white shadow border border-gray-100 rounded-lg mt-4">
        <p className="text-lg font-bold break-all pb-3">
          History
        </p>
        <div>
          <HistoryCard />
        </div>
        <button className="flex custom-blue-200 text-sm font-semibold ml-3 py-2 gap-3 items-center">
          <ChevronDownIcon className="mr-auto custom-blue-200 h-4 w-4" aria-hidden="true" />
          Show Older
        </button>
      </div>
    </Fragment>
  )
}
import React, { Fragment } from "react";
import TopButtonsComponent from "../topButtonsComponent";
import EventDetailSectionComponent from "../eventDetailSectionComponent";
import CommentSectionComponent from "../commentSectionComponent";
import UsersListSectionComponent from "../usersListSectionComponent";
import MgrEventButtonsComponent from '../../../subRequests/pages/components/mgrEventButtonsComponent';
import AcceptDeclineButtonsComponent from './../eventDetailSectionComponent/acceptDeclineButtonsComponent';
import ReopenMessageComponent from './../reopenMessageComponent';
import { checkMgrEventWithRole } from './../../helpers';

export default function SubRequestDetailsPageComponent(props) {
  const {
    user,
    business,
    permissions,
    path,
    eventData,
    handleSetAlert,
    handleUpdateEventComments,
    handleReloadData,
    handleUpdateEvent
  } = props;

  return (
    <Fragment>
      <div className="w-full">
        <TopButtonsComponent 
          user={user}
          eventData={eventData}
          handleSetAlert={handleSetAlert}
          handleUpdateEvent={handleUpdateEvent}
          business={business}
        />
        {eventData?.Reopened_message && eventData?.Reopened_message !== '' &&
          <ReopenMessageComponent eventData={eventData}/>
        }
        <div className="flex flex-wrap mt-5 h-auto lg:h-full w-full">
          <div className={`h-full w-full ${(!eventData?.show_invites_section && !(eventData?.manager_gate_event && checkMgrEventWithRole(eventData, user))) ? 'lg:w-1/2' : 'lg:w-2/5'}`}>
            <EventDetailSectionComponent
              user={user}
              eventData={eventData}
              handleSetAlert={handleSetAlert}
              handleUpdateEvent={handleUpdateEvent}
              business={business}
            />
            {eventData?.show_manger_request_buttons &&
              <div className='flex gap-3 my-4 lg:hidden px-8'>
                <Fragment>
                  <MgrEventButtonsComponent 
                    user={user} 
                    eventId={eventData?.id} 
                    handleSetAlert={handleSetAlert} 
                    handleCloseAndReloadData={handleUpdateEvent} 
                    type='detail'
                    calendarEvent={false} 
                    handleUpdateCalendarEvent={() => {}}
                    deletedEvent={eventData?.deleted}
                  />
                </Fragment>
              </div>
            }
            {eventData?.show_accept_decline_button &&
              <div className='flex gap-3 my-4 lg:hidden px-8'>
                <Fragment>
                  <AcceptDeclineButtonsComponent 
                    eventData={eventData} 
                    business={business} 
                    handleAlert={handleSetAlert} 
                    handleUpdateEvent={handleUpdateEvent}
                  />
                </Fragment>
              </div>
            }
            {(eventData?.show_invites_section || (eventData?.manager_gate_event && checkMgrEventWithRole(eventData, user))) &&
              <div className="hidden lg:flex h-full">
                <CommentSectionComponent
                  user={user}
                  business={business}
                  eventData={eventData}
                  handleSetAlert={handleSetAlert}
                  handleUpdateEvents={handleUpdateEventComments}
                />
              </div>
            }
          </div>
          <div className={`w-full ${(!eventData?.show_invites_section && !(eventData?.manager_gate_event && checkMgrEventWithRole(eventData, user))) ? 'lg:w-1/2' : 'lg:w-3/5'}`}>
            {(eventData?.show_invites_section || (eventData?.manager_gate_event && checkMgrEventWithRole(eventData, user))) ?
              <UsersListSectionComponent 
                user={user} 
                eventData={eventData} 
                handleReloadData={handleReloadData} 
                handleUpdateEvent={handleUpdateEvent}
                business={business}
              />
              :
              <CommentSectionComponent
                user={user}
                business={business}
                eventData={eventData}
                handleSetAlert={handleSetAlert}
                handleUpdateEvents={handleUpdateEventComments}
              />
            }
          </div>
          {(eventData?.show_invites_section || (eventData?.manager_gate_event && checkMgrEventWithRole(eventData, user))) &&
            <div className="flex lg:hidden h-full w-full">
              <CommentSectionComponent
                user={user}
                business={business}
                eventData={eventData}
                handleSetAlert={handleSetAlert}
                handleUpdateEvents={handleUpdateEventComments}
              />
            </div>
          }
        </div>
      </div>
    </Fragment>
  );
}
import React, { Fragment, useState, useRef, useEffect } from "react";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

export default function SkillDropDownComponent(props) {
  const { skillList, skillIds, handleSaveSkills, i, disabled } = props;
  const menuButtonRef = useRef(null);
  const [ selectedSkillIds, setSelectedSkillIds ] = useState(skillIds || []);

  useEffect(() => {
    setSelectedSkillIds(skillIds)
  }, [skillIds])

  const handleChangeSkills = (e, skillId) => {
    const { checked } = e.target;
    var __FOUND = selectedSkillIds.indexOf(skillId) > -1;
    if (checked) {
      if(!__FOUND) setSelectedSkillIds([...selectedSkillIds, skillId]);
    } else {
      if(__FOUND) setSelectedSkillIds(selectedSkillIds.filter(id => id !== skillId));
    }
  }

  const processFillBackFilter = () => {
    setSelectedSkillIds(skillIds)
  }

  const setRef = node => {
    if (node) {
      $(`.skills-need-border-${i}`).addClass('sub-policy-skills-dynamic-border')
    }else if (node === null) {
      $(`.skills-need-border-${i}`).removeClass('sub-policy-skills-dynamic-border')
      processFillBackFilter()
    }
  };

  const processSetSelectedSkills = () => {
    handleSaveSkills(selectedSkillIds)
    menuButtonRef.current.click()
  }

  const checkSkillName = (status) => {
    return skillList.filter((item) => status.includes(item.id)).map((item) => item.name).join(', ')
  }

  const checkSelectAll = () => {
    let allSkillIds = skillList.map((item) => item?.id)
    return allSkillIds.every((item) => selectedSkillIds.includes(item)) && allSkillIds.length !== 0
  }

  const handleSelectAll = () => {
    if(checkSelectAll()){
      setSelectedSkillIds([])
    }else{
      let allValue = skillList.map((item) => item?.id)
      setSelectedSkillIds(allValue)
    }
  }

  const cancelSkillSelection = () => {
    processFillBackFilter()
    menuButtonRef.current.click()
  }
  
  return (
    <Fragment>
      <div className="inline w-56">
        <div className="relative filters_box"> 
          <Menu as="div" className="relative block text-left">
            <div>
              <Menu.Button className={`flex justify-center w-full rounded-md pl-4 pr-2 py-2 bg-white text-sm text-gray-900 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 border border-gray-300 skills-need-border-${i} ${disabled ? 'cursor-not-allowed opacity-50' : ''}`} ref={menuButtonRef} disabled={disabled}>
                {selectedSkillIds.length === 0 ? 
                  "Select Skills"
                : 
                  (selectedSkillIds.length === skillList?.length ?
                    "All Skills"
                  :
                    <p className = "text-gray-900 truncate">{checkSkillName(selectedSkillIds)}</p>
                  )
                }
                <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5 shrink-0" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items ref={setRef} className="origin-top-right z-30 right-0 w-full rounded-md bg-white ring-1 ring-gray-500 focus:outline-none mt-2 absolute">
                <div className="py-2 h-60 max-h-60 overflow-y-auto">
                  <div className='flex items-center justify-between px-4 py-2 mb-2 border-b border-gray-200'>
                    <label className="inline-flex items-center text-sm">
                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2" checked = {checkSelectAll()} onChange = {(e) => handleSelectAll(e)}/>
                      Select all
                    </label>
                    <a onClick={() => cancelSkillSelection()} className='custom-red text-sm cursor-pointer'>Cancel</a>
                  </div>
                  <div>
                    {skillList.map((skill, i) => 
                      <div key={i}>
                        <label className="flex items-center px-4 text-gray-900 text-sm mb-2">
                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 cursor-pointer mr-2" onChange={(e) => handleChangeSkills(e, skill?.id)} value={skill?.id} checked={selectedSkillIds.includes(skill?.id)}/>
                          {skill?.name}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center p-2 border-t">
                  <div className="flex justify-center items-center w-full px-3 gap-3">
                    <div className=''>
                      <a className='bg-dark-blue text-white text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => processSetSelectedSkills()}>Apply</a>
                    </div>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </Fragment>
  )
}
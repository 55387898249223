import React, { Fragment, useState } from "react";
import CustomPopupContainerComponent from '../../../../../subRequestDetails/components/customPopupContainerComponent';
import { LoadingIcon } from '../../../../../subRequests/assets';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, } from '@heroicons/react/outline'
import { axiosRequest } from '../../../../../subRequests/helpers/helpers';

export default function AssignCertificationPopup(props) {
  const { showAssignCertPopup, closeAssignCertPopup, certificationCategories, selectedPeopleForMassActions, showAlert, completeMassAssignActions } = props
  const [ selectedCertificationIds, setSelectedCertificationIds ] = useState([])
  const [ disableConfirmBtn, setDisableConfirmBtn ] = useState(false)
  const certificationCategoriesIds = (certificationCategories?.map(item => item.id) || [])

  const handleAssign = () => {
    if (disableConfirmBtn || selectedCertificationIds?.length === 0 || selectedPeopleForMassActions?.length === 0) return;
    setDisableConfirmBtn(true)
    let params = {
      business_user_ids: selectedPeopleForMassActions,
      documentation_category_ids: selectedCertificationIds
    }
    axiosRequest(`/api/v3/documentations/bulk_assign`, 'POST', params, 'data').then(result => {
      if(result?.status === 200){
        showAlert(true, 'success', result?.message)
        setTimeout(()=>{
          showAlert(false, '', '')
          completeMassAssignActions()
          setDisableConfirmBtn(false)
        }, 3000)
      }else{
        showAlert(true, 'danger', result?.error)
        setDisableConfirmBtn(false)
        setTimeout(()=>{
          showAlert(false, '', '')
        }, 3000)
      }
    })
  }

  const checkAllCertsSelected = () => {
    return certificationCategoriesIds.every(v => selectedCertificationIds.includes(v)) && certificationCategoriesIds?.length > 0
  }

  const handleSelectAllCerts = (e) => {
    setSelectedCertificationIds(e.target.checked ? certificationCategoriesIds : [])
  }

  const handleSelectCert = (e) => {
    let val = parseInt(e.target.value)
    var __FOUND = selectedCertificationIds.includes(val)
    setSelectedCertificationIds(__FOUND ? selectedCertificationIds.filter(v => v !== val) : [...selectedCertificationIds, val])
  }

  const categoryName = (categoryId) => {
    let category = certificationCategories.find((item) => item?.id === categoryId)
    if(category){
      return category?.name
    }else{
      return categoryId
    }
  }

  const removeSelectedCertCategories = (categoryId) => {
    setSelectedCertificationIds(prevState => (prevState.filter(item => item !== parseInt(categoryId))))
  }

  return(
    <Fragment>
      <CustomPopupContainerComponent show={showAssignCertPopup} close={closeAssignCertPopup} title={''} customWidth={'sm:max-w-sm'}>
        <div className="px-4 pt-3 font-open-sans">
          <div className="text-center font-bold text-xl font-open-sans">
            Assign Documentation
          </div>
          <div className="min-h-400">
            <div className='grid grid-cols-1 sm:gap-4 sm:items-start mt-4'>
              <div className='mt-1 sm:mt-0 sm:col-span-1'>
                <div className="relative filters_box w-full">
                  <Menu as="div" className="relative block text-left">
                    <div>
                      <Menu.Button className={`h-10 flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500`}>
                        {selectedCertificationIds?.length === 0 ?
                          <p>Select documentation</p>
                        :
                        (checkAllCertsSelected()) ?
                          <p className='text-gray-900'>All documentation selected</p>
                        :
                          <p className='text-gray-900'>{`${selectedCertificationIds?.length} documentation selected`}</p>
                        } 
                        <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="origin-top-right z-10 relative right-0 w-full rounded-md shadow-lg bg-white ring-none border border-gray-300 focus:outline-none">
                        <div className="flex items-center p-2 border-b">
                          <div className="flex items-center py-1">
                            <label className="inline-flex items-center text-gray-400 text-sm">
                              <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked = {checkAllCertsSelected()} onChange = {(e) => handleSelectAllCerts(e)}/>
                              Select All
                              </label>
                          </div>
                        </div>
                        <div className="p-2 max-h-60 overflow-y-auto">
                          {certificationCategories.map((cert, i) =>
                            <div className="py-1" key = {i}>
                              <label className="flex items-center text-gray-900 text-sm mb-2">
                                <input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1`} id={cert.id} name="certifications[]" onChange={(e) => handleSelectCert(e, cert)} value={cert.id} checked={selectedCertificationIds?.includes(cert.id)}/>
                                <label className={`ml-1.5 text-sm font-medium text-gray-500`} htmlFor={cert.id}>{cert?.name}</label> 
                              </label>
                            </div>
                          )}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                {selectedCertificationIds?.length > 0 &&
                  <div className='flex items-center mt-3 gap-2 flex-wrap'>
                    {selectedCertificationIds.map((categoryId, i) => 
                      <div className='bg-purple-100 text-purple-800 py-0.5 px-2.5 flex items-center rounded-xl' key={i}>
                        <div className='text-sm pr-1 font-medium'>{categoryName(categoryId)}</div>
                        <a onClick={() => removeSelectedCertCategories(categoryId)}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="text-violet-800  h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                          </svg>
                        </a>
                      </div>
                    )}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="mt-4 relative">
            {disableConfirmBtn && 
              <div className="flex items-center left-5 top-2.5 justify-center absolute">
                <LoadingIcon classNames={'animate-spin h-4 w-4 text-black'}/>
              </div>
            }
            <button 
              className={`bg-gray-900 rounded-md text-white flex items-center justify-center w-full py-2 text-sm ${(selectedCertificationIds?.length == 0 || disableConfirmBtn) ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleAssign()}
              disabled={selectedCertificationIds?.length == 0 || disableConfirmBtn}
            >
              Assign
            </button>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}
import React, { Fragment, useState } from "react";
import CustomPopupComponent from './../../../../../subRequests/pages/components/customPopupComponent/customPopupComponent';
import { axiosRequest } from './../../../../../subRequests/helpers/helpers';

export default function NoShowConfirmationComponent(props){
  const { eventData, handleSetAlert, closeNoShowPopup, handleUpdateEvent, showNoShowPopup, noShowPopupType, type, calendarEvent, handleUpdateCalendarEvent } = props
  const [ disableContinueBtn, setDisableContinueBtn ] = useState(false)

  const handleContinueConfirm = () => {
    setDisableContinueBtn(true)
    let url = `/api/v3/business_events/${eventData?.id}/perform_more_actions`
    let params = {
      key: noShowPopupType,
      preview: (type !== 'detail'),
      calendar_event: calendarEvent
    }
    
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(Object.keys(result).includes('status') && result?.status === 200){
        setDisableContinueBtn(false);
        handleSetAlert(true, result?.success, 'success');
        closeNoShowPopup(false);
        handleUpdateEvent(result?.event)
        if(calendarEvent) handleUpdateCalendarEvent(result?.calendar_event, 'updated')
      }else{
        setDisableContinueBtn(false);
        handleSetAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleSetAlert(false, '', ''); 
      }, 3000)
    })
  }

  return(
    <Fragment>
      <CustomPopupComponent show={showNoShowPopup} close={closeNoShowPopup} title={eventData?.no_show ? 'Undo No Show' : 'No Show'}>
        <div className="px-4 pt-3 font-open-sans">
          <div className='text-sm px-2 font-semibold text-gray-700 mt-3 text-center'>
            {eventData?.no_show ? `Ah bummer! Please confirm this instructor did not show up to teach this class.` : `Please confirm instructor was not a no show for this class. Upon confirmation, we'll remove this label on the Sub Schedule and update Insights` }
          </div>
          <div className="mt-5 flex gap-3 flex-row-reverse">
            <button 
              className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-40 py-2 text-sm font-semibold ${disableContinueBtn ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={() => handleContinueConfirm()}
              disabled={disableContinueBtn}
            >
              Confirmed!
            </button>
            <button 
              className={`bg-white border rounded-xl text-gray-800 flex items-center justify-center w-40 py-2 text-sm hover:bg-gray-100 font-semibold shadow`}
              onClick={() => closeNoShowPopup(false)}
            >
              Oops, never mind!
            </button>
          </div>
        </div>
      </CustomPopupComponent>
    </Fragment>
  )
}
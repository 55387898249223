import React, { Fragment } from "react";

export default function RenewButtonIcon({classNames, strokeColor='black'}) {

  return(
    <Fragment>
      <svg className={classNames} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_160_24274)">
          <path d="M13.4168 2.33342V5.83342M13.4168 5.83342H9.91683M13.4168 5.83342L10.7102 3.29008C10.0832 2.66283 9.30761 2.20462 8.45569 1.9582C7.60377 1.71178 6.70331 1.68519 5.83833 1.8809C4.97335 2.07661 4.17205 2.48825 3.50919 3.07741C2.84632 3.66657 2.34351 4.41404 2.04766 5.25008M0.583496 11.6668V8.16675M0.583496 8.16675H4.0835M0.583496 8.16675L3.29016 10.7101C3.9171 11.3373 4.69271 11.7955 5.54464 12.042C6.39656 12.2884 7.29702 12.315 8.162 12.1193C9.02698 11.9236 9.82828 11.5119 10.4911 10.9228C11.154 10.3336 11.6568 9.58613 11.9527 8.75009" stroke={strokeColor || 'black'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_160_24274">
          <rect width="14" height="14" fill="white" transform="translate(0.000244141)"/>
        </clipPath>
        </defs>
      </svg>
    </Fragment>
  )
}
import React, { Fragment } from 'react'

export default function ResetSelectionComponent (props){
  const { setTheState } = props

  const handleReset = () => {
    setTheState('selectedPeopleForMassActions', [])
  }


  return(
    <Fragment>
      <div className="relative filters_box">
        <button className={`bg-dark-blue text-white py-1.5 px-4 rounded-full gap-2 text-sm flex items-center border border-white hover:bg-gray-800`} onClick={(e) => handleReset(e)}>
          Clear Selection
        </button>
      </div>
    </Fragment>
  )
}
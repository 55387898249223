import React, { Fragment, useEffect, useState } from "react";
import { axiosRequest } from './../../../../../helpers/helpers';
import { UserIcon, ReOpenIcon, MessageIcon, TashIcon, CalendarIcon } from './../../../../../assets';
import ReOpenEventComponent from './../../../reOpenEventComponent';
import InviteMoreComponent from './../../../../../../subRequestDetails/components/topButtonsComponent/moreActionComponent/inviteMoreComponent';
import { reopenActionHash } from '../../../../../helpers/supportHelpers';

export default function ApprovedEevntsButtonsComponent(props) {
  const { event, business, user, handleShowAssignPopup, handleAddComment, handleShowCancelPopup, handleAlert, closeAndReloadData, listType, path, sentBucket } = props;
  const [ approvedUserId, setApprovedUserId ] = useState('')
  const [ showReopenPopup, setShowReopenPopup ] = useState(false)
  const [ disableReopenBtn, setDisableReopenBtn ] = useState(false)
  const [ confirmationMessage, setConfirmationMessage ] = useState('')
  const [ showInviteMorePopup, setShowInviteMorePopup ] = useState(false)
  const [ eventUpdateData, setEventUpdateData ] = useState(event)
  const [ reopenEventMRG, setReopenEventMRG ] = useState(false)
  const [ reopenAction, setReopenAction ] = useState(reopenActionHash(event))

  useEffect(() => {
    if(event){
      setReopenAction(reopenActionHash(event))
    }
  }, [event])

  const checkReopenDisabled = () => {
    return (reopenAction && reopenAction.is_disabled)
  }

  const handleReopenEventClick = (e) => {
    if(!event?.is_invite_more_case){
      setApprovedUserId(event?.approved_user)
      setShowReopenPopup(true)
    }else{
      handleDirectReopen(event?.approved_user?.id)
    }
  }
  const handleCloseReopenPopup = (data) => {
    setShowReopenPopup(data)
    setApprovedUserId('')
  }
  const handleDirectReopen = (userId) => {
    setDisableReopenBtn(true)
    let params = {
      event_id: event?.id,
      inst_id: userId,
      reason: '1',
      gql_event_details: true,
      preview: true,
      bucket_type: sentBucket
    }
    let url = '/api/v2/reopen_sub_request'
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(!Object.keys(result).includes('error')){
        setDisableReopenBtn(false);
        handleAlert(true, result?.success, 'success');
        setEventUpdateData(result?.event)
        if(result?.invite_more_display){
          setConfirmationMessage(result?.invite_more_display_msg)
          if(result?.MRG){
            setReopenEventMRG(result?.MRG)
          }
          setShowInviteMorePopup(true)
        }else{
          closeAndReloadData(result?.event);
        }
      }else{
        setDisableReopenBtn(false);
        handleAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleAlert(false, '', ''); 
      }, 3000)
    })
  }
  const hanldeCloseAndReloadData = (eventData) => {
    handleCloseReopenPopup(false)
    closeAndReloadData(eventData)
  }

  const closeInviteMorePopup = (data) => {
    setConfirmationMessage('')
    setShowInviteMorePopup(data)
    closeAndReloadData(eventUpdateData)
  }
  const handleUpdateEventData = (data) => {
    setEventUpdateData(data)
    closeAndReloadData(data)
  }
  
  const fetchInviteMorePopupData = (result) => {
    setEventUpdateData(result?.event)
    setConfirmationMessage(result?.invite_more_display_msg)
    if(result?.MRG){
      setReopenEventMRG(result?.MRG)
    }
    setShowInviteMorePopup(true)
  }

  return (
    <Fragment>
      {showInviteMorePopup &&
        <InviteMoreComponent user={user} eventId={event?.id} confirmationMessage={confirmationMessage} closeInviteMorePopup={closeInviteMorePopup} showInviteMorePopup={showInviteMorePopup} handleUpdateEvent={handleUpdateEventData} handleSetAlert={handleAlert} type={'preview'} sentBucket={sentBucket} reopenEventMRG={reopenEventMRG}/>
      }
      {showReopenPopup &&
        <ReOpenEventComponent showReopenPopup={showReopenPopup} closeReopenPopup={handleCloseReopenPopup} user={user} eventId={event?.id} approvedUser={approvedUserId} handleSetAlert={handleAlert} processFetchEventData={hanldeCloseAndReloadData} type={'preview'} sentBucket={sentBucket} calendarEvent={false} handleUpdateCalendarEvent={() => {}} fetchInviteMorePopupData={fetchInviteMorePopupData}/>
      }
      <div className='flex gap-1.5 mt-3'>
        {(['all-requests']?.includes(path)) && listType == 'Approved' &&
          <Fragment>
            {(event?.show_assign_button) &&
              <button className='w-full tc-11 font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5 px-1.5' onClick={() => handleShowAssignPopup('replace')}>
                <UserIcon classNames={'wc-14 hc-14'}/>
                Assign
              </button>
            }
          </Fragment>
        }
        {(['my-requests']?.includes(path) && (listType == 'Approved' || (listType == 'Available' && event?.status_content.toLowerCase().includes('approved') && event?.status == 'Approved'))) &&
          <a href={event?.google_cal_link} target="_blank" className='w-full tc-11 font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5 px-1.5'>
            <CalendarIcon classNames={'wc-14 hc-14 shrink-0'}/>
            Add to Cal
          </a>
        }
        {((['my-requests']?.includes(path) && listType == 'Approved' && !event?.mrg_reopened) || event?.show_reopen_button) &&
          <button className={`w-full  tc-11 font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5 px-1.5 ${(disableReopenBtn || checkReopenDisabled()) ? 'opacity-50 cursor-not-allowed' : ''}`}onClick={() => handleReopenEventClick()} disabled={disableReopenBtn || checkReopenDisabled()}>
            <ReOpenIcon classNames={'wc-14 hc-14'}/>
            Reopen
          </button>
        }
        {business?.comments_feature &&
          <button className='w-full tc-11 font-semibold flex items-center justify-center border border-gray-300 rounded-2xl h-8 gap-0.5 px-1.5' onClick={() => handleAddComment()}>
            <MessageIcon classNames={'wc-14 hc-14'}/>
            Comment
          </button>
        }
        {event?.show_cancel_button &&
          <button className='flex justify-center items-center border border-gray-400 rounded-full min-h-8 min-w-8' onClick={() => handleShowCancelPopup()}>
            <TashIcon classNames={'text-black wc-14 hc-14'}/>
          </button>
        }
      </div>
    </Fragment>
  );
}
import React, { Fragment } from "react";
import InstructorFilterComponent from '../../../../subRequests/pages/components/instructorFilterComponent';
import StatusFilter from './statusFilter';
import CategoryFilter from './categoryFilter';
import LocationFilterComponent from '../../../../subRequests/pages/components/locationFilterComponent/locationFilterComponent';
import ExportButton from './exportButton';

export default function FilterComponents(props) {
  const { selectedInstructors, handleSelectInstructors, user, selectedStatus, handleSelectedStatus, selectedCategories, handleSelectedCategories, categories, statusFilterCounts, regionLocationsList, showRegions, selectedLocations, selectedRegions, hanldleSelectLocations } = props
  
  return(
    <Fragment>
      <div className="flex flex-wrap flex-col sm:flex-row justify-center gap-3 sm:items-center mb-5 certifications-filter">
        <div className="w-full sm:w-56">
          <InstructorFilterComponent selectedInstructors={selectedInstructors} handleSelectInstructors={handleSelectInstructors} user={user} page={'documentation'}/>
        </div>
        <div className="w-full sm:w-56">
          <StatusFilter selectedStatus={selectedStatus} handleSelectedStatus={handleSelectedStatus} user={user} statusFilterCounts={statusFilterCounts}/>
        </div>
        <div className="w-full sm:w-56">
          <CategoryFilter selectedCategories={selectedCategories} handleSelectedCategories={handleSelectedCategories} user={user} categoriesList={categories}/>
        </div>
        <div className="w-full sm:w-56">
          <LocationFilterComponent regionLocationsList={regionLocationsList || []} selectedFilterLocations={selectedLocations} hanldleSelectFilterLocations={hanldleSelectLocations} selectedFilterRegions={selectedRegions} showRegions={showRegions || true} user={user} path={'instructor-documentation'} />
        </div>
        <ExportButton selectedInstructors={selectedInstructors} selectedStatus={selectedStatus} selectedCategories={selectedCategories} selectedLocations={selectedLocations}/>
      </div>
    </Fragment>
  )
}
import React, { Component, useEffect } from 'react';
import ConfigureNavbar from './navbar'

import MyClasses from './myClasses'
import Reports from './reports'

const AttendanceLayout = (props) => {
  const { user, setCurrent, pageLoaded, loadingMessage, path } = props;

  const showReports = () => {
    return user?.role_display !== 'Fitness Instructor'
  };

  useEffect(() => {
    setCurrent('Attendance');
  }, [setCurrent]);

  const renderSwitch = () => {
    switch (path) {
      case 'my-classes':
        return <MyClasses pageLoaded={pageLoaded} />;
      case 'reports':
        return <Reports loadingMessage={loadingMessage} pageLoaded={pageLoaded} showReports={showReports()} />;
      default:
        return null;
    }
  };

  return (
    <div className='w-full min-h-screen bg-gray-50 pt-20 md:pt-8'>
      <div className='h-full'>
        <div className='max-w-8xl mx-auto px-6 lg:px-8 h-full'>
          <ConfigureNavbar showReports={showReports()} path={path} />
          {renderSwitch()}
        </div>
      </div>
    </div>
  );
};

export default AttendanceLayout;
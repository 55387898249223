import React, { Fragment, useState, useEffect } from "react";
import {
  ClockIcon,
  LocationPinIcon,
  ExclamationIcon
} from "./../../../subRequests/assets";
import InstructorDetailComponent from "../../../subRequests/pages/components/cardViewComponents/cardComponent/instructorDetailComponent";
import MgrEventButtonsComponent from '../../../subRequests/pages/components/mgrEventButtonsComponent';
import AcceptDeclineButtonsComponent from './acceptDeclineButtonsComponent';
import ViewHistoryComponent from './viewHistoryComponent';
import ManagersNameComponent from './managersNameComponent';
import RequestDetailsComponent from './requestDetailsComponent';
import ClassDescriptionComponent from './classDescriptionComponent';
import ReactTooltip from "react-tooltip";

export default function EventDetailSectionComponent(props) {
  const { user, business, eventData, handleSetAlert, handleUpdateEvent } = props;
  const [showInstructorDetail, setShowInstructorDetail] = useState(false);
  const [detailInstructor, setDetailInstructor] = useState({});
  const [ deletedEvent, setDeletedEvent ] = useState(false)

  useEffect(() => {
    setDeletedEvent(eventData?.deleted)
  }, [eventData?.deleted])

  const handleShowInstructorDetails = (data) => {
    setShowInstructorDetail(true);
    setDetailInstructor(data);
  };

  const handleCloseInstructorDetails = () => {
    setShowInstructorDetail(false);
    setDetailInstructor({});
  };

  return (
    <Fragment>
      {showInstructorDetail && (
        <InstructorDetailComponent
          showInstructorDetail={showInstructorDetail}
          detailInstructor={detailInstructor}
          close={handleCloseInstructorDetails}
          handleAlert={handleSetAlert}
        />
      )}
      <div className="lg:mr-2">
        <div className={`flex lg:rounded-2xl lg:border-2 lg:border-gray-300 h-auto lg:h-1/2 w-full bg-white p-4 relative`}>
          <div className={`${
          ['Open', 'Re-Opened'].includes(eventData?.status_content)
          ? "border-open-status"
          : eventData?.status_content.toLowerCase().includes('pending')
          ? ((eventData?.manager_gate_event && !eventData?.mrg_reopened) ? "border-mgr-status" : eventData?.status == 'Open' ? "border-open-status" : (eventData?.status == 'Approved' && eventData?.mrg_reopened) ?  "border-approved-status" : "border-pending-status")
          : eventData?.status_content.toLowerCase().includes('approved')
          ? "border-approved-status"
          : ["Declined", 'Canceled'].includes(eventData?.status_content)
          ? "border-canceleld-status"
          : [
            "Response Needed (Re-opened)",
            "Response Needed",
          ].includes(eventData?.status_content) &&
          ["Open", "Approved"].includes(eventData?.status)
          ? "border-mgr-status"
          : eventData?.status?.toLowerCase() === 'deleted' || deletedEvent
          ? "border-deleted-status"
          : ""
        } h-full absolute left-0 top-0 lg:hidden`}></div>
          <div className="flex w-full">
            <div className="w-10 h-full items-center mt-1">
              <div className="tc-10 font-medium flex justify-center uppercase">
                {eventData?.month_name}
              </div>
              <div className="text-xl font-semibold flex justify-center">
                {eventData?.day}
              </div>
              <div className="tc-10 font-medium flex justify-center uppercase">
                {eventData?.week_day}
              </div>
            </div>
            <div className="w-full ml-3">
              <div className="flex flex-wrap justify-between mb-3">
                <div className="text-xl font-semibold text-gray-900 flex items-center">
                  {eventData?.title}
                  {(eventData?.primary_skill_name && eventData?.primary_skill_name !== '') &&
                    <span className="text-sm ml-2">({eventData?.primary_skill_name})</span>
                  }
                </div>
                <div className="flex gap-2 items-center">
                  {eventData?.blank_request &&
                    <div className="flex item-center">
                      <ExclamationIcon classNames={"text-red-600 h-4 w-4 inline-block ml-1"} componentId={eventData?.id.toString()+'_sub_request_blank_error'}/>
                      <ReactTooltip className='max-w-xs text-center react-tooltip-z-index-max' id={eventData?.id.toString()+'_sub_request_blank_error'} place="top" effect="solid">
                        {"There are no instructors invited to this request due to lack of availability. Once instructors become available, click Invite More to send them this request"}
                      </ReactTooltip>
                    </div>
                  }
                  <div
                    className={`${
                      eventData?.status_content === "Open"
                        ? "text-yellow-500"
                        : [
                            "Pending Approval",
                            "Pending Your Approval",
                            "Pending Manager Approval",
                          ].includes(eventData?.status_content) ||
                          eventData?.status_content
                            .toLowerCase()
                            .includes("pending")
                        ? (eventData?.manager_gate_event && !eventData?.mrg_reopened)
                          ? "mgr-text-color"
                          : eventData?.status == "Open"
                          ? "text-yellow-500"
                          : eventData?.status == "Approved"
                          ? "approved-text-color"
                          : "pending-text-color"
                        : eventData?.status_content
                            .toLowerCase()
                            .includes("approved")
                        ? "approved-text-color"
                        : ["Cancelled", "Declined"].includes(
                            eventData?.status_content
                          )
                        ? "cancelled-text-color"
                        : [
                            "Response Needed (Re-opened)",
                            "Response Needed",
                          ].includes(eventData?.status_content) &&
                          ["Open", "Approved"].includes(eventData?.status)
                        ? "mgr-text-color"
                        : eventData?.status?.toLowerCase() === 'deleted' || deletedEvent
                        ? "text-gray-600"
                        : "text-yellow-500"
                    } text-lg font-semibold`}
                  >
                    {eventData?.status_content}
                  </div>
                </div>
              </div>
              {(eventData?.very_original_instructor?.id !== eventData?.requesting_instructor?.id && eventData?.reopened) ?
                <Fragment>
                  <div className="flex items-center mb-2 gap-2">
                    <Fragment>
                      <img
                        src={eventData?.very_original_instructor?.image}
                        className="w-6 h-6 rounded-full"
                        onClick={() =>
                          handleShowInstructorDetails(
                            eventData?.very_original_instructor
                          )
                        }
                      />
                      <div className="flex items-center truncate gap-1.5">
                        <div className="text-base font-semibold">
                          Original Instructor:
                        </div>
                        <button
                          className={`custom-blue-200 text-base font-semibold text-left ${eventData?.status_content.toLowerCase().includes("approved") && "truncate"}`}
                          onClick={() =>
                            handleShowInstructorDetails(
                              eventData?.very_original_instructor
                            )
                          }
                        >
                          {eventData?.very_original_instructor?.full_name}
                        </button>
                      </div>
                      {(eventData?.show_view_history && eventData?.very_original_instructor?.id !== user?.id) &&
                        <ViewHistoryComponent eventUserId={eventData?.very_original_instructor?.id} eventUserName={eventData?.very_original_instructor?.full_name} />
                      }
                    </Fragment>
                  </div>
                  <div className="flex flex-wrap items-center mb-2 gap-2">
                    <Fragment>
                      <div className="flex items-center gap-2">
                        <img
                          src={eventData?.requesting_instructor?.image}
                          className="w-6 h-6 rounded-full"
                          onClick={() =>
                            handleShowInstructorDetails(
                              eventData?.requesting_instructor
                            )
                          }
                        />
                        <div className="flex items-center truncate gap-1.5">
                          <div className="text-base font-semibold">
                            Reopened Sub:
                          </div>
                          <button
                            className={`custom-blue-200 text-base font-semibold text-left ${eventData?.show_view_history && "truncate max-wc-147"}`}
                            onClick={() =>
                              handleShowInstructorDetails(
                                eventData?.requesting_instructor
                              )
                            }
                          >
                            {eventData?.requesting_instructor?.full_name}
                          </button>
                        </div>
                      </div>
                      {eventData?.show_view_history &&
                        <ViewHistoryComponent eventUserId={eventData?.requesting_instructor?.id} eventUserName={eventData?.requesting_instructor?.full_name} />
                      }
                    </Fragment>
                  </div>
                </Fragment>
                :
                <div className="flex flex-wrap items-center mb-2 gap-2">
                  <Fragment>
                    <div className="flex items-center gap-2">
                      <img
                        src={eventData?.requesting_instructor?.image}
                        className="w-6 h-6 rounded-full"
                        onClick={() =>
                          handleShowInstructorDetails(
                            eventData?.requesting_instructor
                          )
                        }
                      />
                      <div className="flex items-center truncate gap-1.5">
                        {(eventData?.status_content
                          .toLowerCase()
                          .includes("approved") || eventData?.status_content == 'Response Needed (Re-opened)') && (
                          <div className="text-base font-semibold">
                            Original Instructor:
                          </div>
                        )}
                        <button
                          className={`custom-blue-200 text-base font-semibold text-left ${eventData?.status_content.toLowerCase().includes("approved") && `truncate ${eventData?.show_view_history && 'max-wc-120'}`}`}
                          onClick={() =>
                            handleShowInstructorDetails(
                              eventData?.requesting_instructor
                            )
                          }
                        >
                          {eventData?.requesting_instructor?.full_name}
                        </button>
                      </div>
                    </div>
                    {eventData?.show_view_history &&
                      <ViewHistoryComponent eventUserId={eventData?.requesting_instructor?.id} eventUserName={eventData?.requesting_instructor?.full_name} />
                    }
                  </Fragment>
                </div>
              }
              {(eventData?.status_content.toLowerCase().includes("approved") || eventData?.status_content == 'Response Needed (Re-opened)') && (
                <div className="flex items-center mb-2">
                  <img
                    src={eventData?.approved_user?.image}
                    className="w-6 h-6 rounded-full"
                    onClick={() =>
                      handleShowInstructorDetails(eventData?.approved_user)
                    }
                  />
                  <div className="text-base font-semibold ml-2">Sub:</div>
                  <button
                    className="custom-blue-200 text-base font-semibold ml-2"
                    onClick={() =>
                      handleShowInstructorDetails(eventData?.approved_user)
                    }
                  >
                    {eventData?.approved_user?.full_name}
                  </button>
                </div>
              )}
              <div className="flex items-center mb-2">
                <ClockIcon classNames={"w-5 h-5 text-neutral-400"} />
                <div className="text-base ml-2 font-medium text-neutral-600">
                  {`${eventData?.edit_start_time.trim()}-${eventData?.edit_end_time.trim()} `}
                  {user?.show_tz ? ` ${eventData?.tz_display}` : ""}
                  {` (${eventData?.duration_display})`}
                </div>
              </div>
              <div className="flex items-center mb-2">
                <LocationPinIcon classNames={"w-5 h-5 text-neutral-400"} />
                <a 
                  href={eventData?.business_location?.location_map_url} 
                  target="_blank"
                  className="custom-blue-200 text-base font-medium ml-2 cursor-pointer"
                >
                  {eventData?.business_location?.name}
                </a>
              </div>
              {eventData?.fitness_managers_details?.length > 0 && (
                <ManagersNameComponent 
                  eventData={eventData} 
                  handleShowInstructorDetails={handleShowInstructorDetails}
                />
              )}
              <RequestDetailsComponent 
                eventData={eventData} 
                handleShowInstructorDetails={handleShowInstructorDetails}
              />
              <ClassDescriptionComponent
                eventData={eventData}
              />
              <div className='gap-3 mt-3 hidden lg:flex'>
                {eventData?.show_manger_request_buttons &&
                  <Fragment>
                    <MgrEventButtonsComponent 
                      user={user} 
                      eventId={eventData?.id} 
                      handleSetAlert={handleSetAlert} 
                      handleCloseAndReloadData={handleUpdateEvent} 
                      type='detail'
                      calendarEvent={false} 
                      handleUpdateCalendarEvent={() => {}}
                      deletedEvent={deletedEvent}
                    />
                  </Fragment>
                }
                {eventData?.show_accept_decline_button &&
                  <Fragment>
                    <AcceptDeclineButtonsComponent 
                      eventData={eventData} 
                      business={business} 
                      handleAlert={handleSetAlert} 
                      handleUpdateEvent={handleUpdateEvent}
                    />
                  </Fragment>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

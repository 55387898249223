import React, { Component, Fragment } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { Switch } from '@headlessui/react'
import { getUsers, getRoles, setLocationSettings, filterUsers, exportUsers, uploadCSV, sendConfirmationAll, sendConfirmation, updateUserActivation, deleteUser, updatePassword, getRegionLocations, syncUsers, processSyncUsers, downloadData, syncPaginatedUsers, getTeachingLocations, getSkills, getRegions, createUser, updateUser, uploadUsers, downloadMboSyncData, assignTempPassword, sendEmailVerification, findMatchingRoleAndSlice } from './helpers';
import { getSudomain, setTitle, timeZones, checkSettingsPermissios, clearApiTokenAndRedirectToSignIn } from '../../helpers'
import Cookies from 'js-cookie';
import ReactTooltip from "react-tooltip";
import Pagination from "react-js-pagination";
import { debounce } from "lodash";
import { components } from "react-select";
import Creatable from "react-select";
import UserForm from './addUserForm'
import { disableAutofill, enableEditing, initial_user, axiosGetRequest } from './usersPageIntegrationComponents/helpers'
import HomeClubComponent from './usersPageIntegrationComponents/components/filtersAndButtons/homeClubComponent';
import LocationComponent from './usersPageIntegrationComponents/components/filtersAndButtons/locationComponent/locationComponent';
import AssignCertificationPopup from './usersPageIntegrationComponents/components/massActionComponent/assignCertificationPopup';
import MassActionBar from './usersPageIntegrationComponents/components/massActionComponent/massActionBar';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function hideItems(cls){
  $('.filter_items').each(function(i, obj) {
    if($(obj).hasClass(`${cls}_items`)){
      $(`.${cls}_items`).toggleClass("hidden")
    }else{
      $(obj).addClass("hidden")
    }
  });
}

const menu = (props) => {
  const optionSelectedLength = props.getValue().length || 0;
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 3 ? (
        props.children
      ) : (
        <div style={{ margin: 15 }}>You can select up to 3 locations at a time for this report.</div>
      )}
    </components.Menu>
  );
};

const menu2 = (props) => {
  const optionSelectedLength = props.getValue().length || 0;
  return (
    <components.Menu {...props}>
    </components.Menu>
  );
};

export default class Users extends Component {
  constructor(props){
    super(props);
    this.state = {
      locationSettings: [],
      selectedLocations: [],
      selectedRegions: [],
      check: false,
      users: [],
			alert_type: '',
			alert_message: '',
      show: false,
			loaded: false,
			activePage: 1,
			total: 0,
			baseCount: 0,
			sortType: 'signup',
			sortReverse: false,
			search: '',
      pageSize: 30,
			search_user: "",
      noSkillUsers: 0,
      inactiveUsers: 0,
      deleteModal: false,
      showModal: false,
      emailModal: false,
      sendAllModal: false,
      syncModal: false,
      passwordModal: false,
      activateModal: false,
      deactivateModal: false,
      rolesName: [],
      search_text: "",
      dataLoaded: true,
      uploadOldUsers: [],
      uploadNewUsers: [],
      showUpload: false,
      sendAllModal: false,
      emailUser: {
        id: '',
        name: ''
      },
      userActivation_sent_at: "",
      sendAllModalType: '',
      activateUser: {},
      deleteUser: {
        id: '',
        name: ''
      },
      passwordUser: {
        id: '',
        name: '',
        email: ''
      },
      showPassword: false,
      showConfirmPassword: false,
      newPass: '',
      confirmNewPass: '',
      notMatching: false,
      submitted: false,
      site_ids: [],
      fetching: false,
      remote_classes: [],
      regionLocations: [],
      downloadModal: false,
      selectedSiteId: '',
      activeCheck: [],
      mboCheckId: [],
      holdClassToAdd: [],
      abcActive: true,
      mboStaffFilter: false,
      mboEmailFilter: false,
      selectedActive: false,
      syncActivePage: 1,
      syncTotal: 0,
      blank: true,
      roles: [],
      showFilterOther: null,
      showFormOther: null,
      teachingRegionWithLocations: [],
      showFormOtherTeaching: null,
      regionWithLocations: [],
      skills: [],
      userForm: initial_user,
      time_zones: timeZones(),
      disabledActivationBtn: false,
      disabledDeleteBtn: false,
      disabledEmailConfirmBtn: false,
      disabledSendAllBtn: false,
      syncDataType: true,
      disabledEmailReVerificationBtn: false,
      resendEmailUser: {
        email: '',
        id: '',
      },
      userSyncSkills: [],
      dataReady: false,
      selectedHomeClubLocations: [],
      selectedHomeClubRegions: [],
      certificationCategories: [],
      userIdsForMassActions: [],
      selectedPeopleForMassActions: [],
      showAssignCertPopup: false,
    }
  }

  componentDidMount() {
    if(Object.keys(this.props?.user).length !== 0 && Object.keys(this.props?.permissions).length !== 0){
      checkSettingsPermissios('users', this.props)
      this.checkUserRoles(this.props?.user?.role_name)
    }
    if(this.props.pageLoaded){
      this.getLocations()
      if(this.props?.business?.documentations_feature && (this.props?.user?.role_display == "Netgym Employee" || this.props?.permissions?.documentations?.write || this.props?.permissions?.documentations?.read)) this.getCertificationCategories()
      this.setState({check: true})
    }
		setTitle('users')
    // window.setSidebarActive('users')
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  checkUserRoles = (roleName) => {
    let allRoles = findMatchingRoleAndSlice(roleName)
    let rolesName = allRoles.map(item => item.value)
    this.setState({
      rolesName: rolesName,
      roles: allRoles
    })
  }

  getLocations = () => {
    axiosGetRequest(`/api/v3/get_user_location_and_skills`)
    .then(result => {
      if(result.status === 200){
        let regions_and_locations = result.regions_and_locations.sort((a, b) => (a.region?.name > b.region?.name) ? 1 : -1)
        let filter_regions_and_locations = result.filter_regions_and_locations.sort((a, b) => (a.region?.name > b.region?.name) ? 1 : -1)
        this.setState({
          showFormOther: result.show_other,
          regionWithLocations: regions_and_locations,
          showFormOtherTeaching: result.show_other,
          teachingRegionWithLocations: regions_and_locations,
          skills: result.business_categories,
          userSyncSkills: result?.integration_skills,
          locationSettings: filter_regions_and_locations,
          showFilterOther: result.filter_show_other,
        })
        this.setSelectedLocations(result.filter_regions_and_locations)
        if(this.props.user.role_display === "Netgym Employee" && this.state.check === true){
          this.setAllLocationForNE(result.filter_regions_and_locations)
        }
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
      }
    })
  }

  setSelectedLocations = (regions) => {
    let selected_regions = []
    let selected_locations = []
    for(var i=0; i<regions.length;i++){
      if(regions[i]['region']['checked'] === true){
        selected_regions.push(regions[i]['region']['id'])
      }
      for(var j=0; j<regions[i]['locations'].length; j++){
        if(regions[i]['locations'][j]['checked'] === true){
          selected_locations.push(regions[i]['locations'][j]['id'])
        }
      }
    }
    this.setState({
      selectedLocations: selected_locations,
      selectedRegions: selected_regions
    })
    this.getAll(1)
  }

  setAllLocationForNE(locationSettings) {
    this.setState({check: false})
    let all = locationSettings
    let selected_regions = []
    let selected_locations = []
    for(var i=0; i<all.length;i++){
      selected_regions.push(all[i]['region']['id'])
      for(var j=0; j<all[i]['locations'].length; j++){
        selected_locations.push(all[i]['locations'][j]['id'])
      }
    }
    this.setState({
      selectedLocations: selected_locations,
      selectedRegions: selected_regions,
    })
    // this.setSelLocations()
  }

  selectedAllRegLoc = () => {
    let check = this.state.selectedRegions?.length === this.state.locationSettings?.length
    return check
  }

  selectAll = (e) => {
    if(e.target.checked){
      let all = this.state.locationSettings
      let selected_regions = []
      let selected_locations = []
      for(var i=0; i<all.length;i++){
        selected_regions.push(all[i]['region']['id'])
        for(var j=0; j<all[i]['locations'].length; j++){
          selected_locations.push(all[i]['locations'][j]['id'])
        }
      }
      this.setState({
        selectedLocations: selected_locations,
        selectedRegions: selected_regions
      })
    }else{
      this.setState({
        selectedRegions: [],
        selectedLocations: []
      })
    }
  }

  selectRegions = (e, region) => {
    let val = parseInt(e.target.value)

    let regions = this.state.selectedRegions

    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
    let selected_locations = this.state.selectedLocations;

    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }));
        selected_locations = Array.prototype.push.apply(selected_locations, result)
      }
    }else{
      if(__FOUND){

        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)
        });
        selected_locations = selected_locations.filter(item => !result.includes(item));
        this.setState({
          selectedLocations: selected_locations
        })
      }
    }
    if(this.state.selectedRegions?.length === this.state.locationSettings?.length -1 || this.state.selectedRegions?.length === this.state.locationSettings?.length){
      this.setState({
        selectedAll: true
      })
    }
    else{
      this.setState({
        selectedAll: false
      })
    }
  }

  selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newlocs = [...this.state.selectedLocations, val]
        this.setState(state => ({ selectedLocations: newlocs }));
        this.checkRegionVisibility(region, newlocs)
      }
    }else{
      if(__FOUND){
        const { selectedLocations } = this.state;
        let newLocs = selectedLocations.filter((tag, index) => tag !== val)
        this.setState({
          selectedLocations: newLocs
        });
        this.checkRegionVisibility(region, newLocs)
      }
    }

  }

  checkRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.id

    let regions = this.state.selectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }))
      }
    }else{
      if(__FOUND){

        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)
        });
      }
    }
  }

  getAll = (pageNumber) => {
		getUsers(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), pageNumber, this.state.selectedLocations, this.state.sortType, this.state.sortReverse, this.state.selectedHomeClubLocations, '').then(
      response => response.json()
    )
    .then(result => {
			if(result.status === 200){
        this.setState({
          users: result.results,
          total: result.total,
          activePage: parseInt(result.page),
          inactiveUsers: result.inactive_users_count,
          deleteModal: false,
          showModal: false,
          emailModal: false,
          sendAllModal: false,
          syncModal: false,
          passwordModal: false,
          activateModal: false,
          deactivateModal: false,
          userIdsForMassActions: result.results?.map(item => item.id),
          selectedPeopleForMassActions: [],
        }, function () {
            this.setState({
              loaded: true,
              dataLoaded: true
            })
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
      }
    })
    getRoles(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
          let roles = result.roles
          let rolesName = []
          for (let i = 0; i < roles?.length; i++){
            rolesName.push(roles[i].value)
          }
          this.setState({
            roles: result.roles,
            rolesName: rolesName
          })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
      }
    })
	}

  location_filter_message = () => {
    if(this.state.selectedLocations?.length > 0){
      return "locations_selected"
    }else{
      return ""
    }
  }

  setSelLocations = () => {
    let locations = this.state.selectedLocations;
    if(locations.length === 0){
      this.setState({
        alert_message: 'Please select atleast one location',
        alert_type: 'danger'
      })
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
      return;
    }
    if(locations.length > 0 && !this.selectedAllRegLoc()){
      this.setState({search_text: this.location_filter_message()})
    }
    this.setState({
      dataLoaded: false
    })
    // window.hideFilter()

    setLocationSettings(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.selectedLocations, this.state.selectedRegions).then(
      response => response.json()
    )
    .then(result => {
			if(result.status === 200){
        this.getLocations()
        this.setState({
          // loaded: true
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
      }
    })
  }

  getTooltipMsg = (user) =>{
    let msg = ""
    // if(user.business_skill_ids.length === 0 && user.business_location_ids.length === 0 && !user.email){
    //   msg = `${user.first_name} needs to be assigned email, skills and locations.`
    // }
    // else if(user.business_skill_ids.length === 0 && user.business_location_ids.length === 0 && user.email){
    //   msg = `${user.first_name} needs to be assigned skills and locations.`
    // }
    // else if(user.business_skill_ids.length === 0 && user.business_location_ids.length > 0 && !user.email){
    //   msg = `${user.first_name} needs to be assigned email and skills.`
    // }
    // else if(user.business_skill_ids.length === 0 && user.business_location_ids.length > 0 && user.email){
    //   msg = `${user.first_name} needs to be assigned skills.`
    // }
    // else if(user.business_skill_ids.length > 0 && user.business_location_ids.length === 0 && !user.email){
    //   msg = `${user.first_name} needs to be assigned email and locations.`
    // }
    // else if(user.business_skill_ids.length > 0 && user.business_location_ids.length === 0 && user.email){
    //   msg = `${user.first_name} needs to be assigned locations.`
    // }
    // else if(user.business_skill_ids.length > 0 && user.business_location_ids.length > 0 && !user.email){
    //   msg = `${user.first_name} needs to be assigned email.`
    // }
    if(user.business_skill_ids.length === 0 && user.business_location_ids.length === 0){
      msg = `${user.first_name} needs to be assigned skills and locations.`
    }else if(user.business_skill_ids.length === 0 && user.business_location_ids.length > 0){
      msg = `${user.first_name} needs to be assigned skills.`
    }else if(user.business_skill_ids.length > 0 && user.business_location_ids.length === 0){
      msg = `${user.first_name} needs to be assigned locations.`
    }
    else{
      msg = ""
    }
    return msg
  }

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber, dataLoaded: false, selectedPeopleForMassActions: []});
    this.performFilters(pageNumber, this.state.selectedLocations, this.state.sortType, this.state.sortReverse, this.state.selectedHomeClubLocations, this.state.search_user)
  }

  performFilters = (pageNumber, selectedLocations, sortType, sortReverse, selectedHomeClubLocations, search) => {
    getUsers(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), pageNumber, selectedLocations, sortType, sortReverse, selectedHomeClubLocations, search).then(
      response => response.json()
    ).then(result => {
      if(result.status === 200){
        this.setState({
          users: result.results,
          activePage: parseInt(result.page),
          loaded: true,
          dataLoaded: true,
          sendAllModal: false,
          disabledActivationBtn: false,
          disabledDeleteBtn: false,
          disabledEmailConfirmBtn: false,
          disabledSendAllBtn: false,
          disabledEmailReVerificationBtn: false,
          total: result.total,
          userIdsForMassActions: result.results?.map(item => item.id)
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  search = (search) => {
    this.setState({
      search_user: search,
      search_text: search
    })
    this.debounceTest(search)
  }

  performSearch = (search) => {
    this.setState({dataLoaded: false})
    this.performFilters(1, this.state.selectedLocations, this.state.sortType, this.state.sortReverse, this.state.selectedHomeClubLocations, search)
  }

  debounceTest = debounce(val => {
    this.performSearch(val)
  }, 800);

  export = (e) => {
    e.preventDefault()
    exportUsers(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(
      response => response.blob()
      .then(result => {
        const url = window.URL.createObjectURL(result)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', "users.csv")
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    )
  }

  upload = (e) => {
    e.preventDefault()
    this.setState({
      dataLoaded: false
    })
    uploadCSV(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), e.target.files[0], this.props.business).then(
      response => response.json()
      .then(result => {
        if(result.status === 200){
          this.setState({
            uploadNewUsers: result.results,
            uploadOldUsers: result.old_results,
            alert_message: 'File uploaded successfully',
            alert_type: 'success',
            loaded: true,
            dataLoaded: true,
            showUpload: true
          })
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger',
            loaded: true,
            dataLoaded: true,
          })
        }
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      })
    )
  }

  cancelLocationFilter = () => {
    hideItems('location')
  }

  sort = (e, param, type) => {
    e.preventDefault()
    let sort_desc = false
    if(type === 'asc'){
      sort_desc = false
    }else{
      let sortRev =  this.state.sortReverse
      if(sortRev === true){
        sort_desc = false
      }
      else{
        sort_desc = true
      }
    }

    this.setState({
      sortType: param,
      sortReverse: sort_desc
    }, () => {
      this.filter('')
    });
  }

  filter = (search) => {
    if(this.state.search_user !== "locations_selected" && this.state.search_user !== ""){
      search = this.state.search_user
    }
    this.setState({
      dataLoaded: false
    })
    this.performFilters(this.state.activePage, this.state.selectedLocations, this.state.sortType, this.state.sortReverse, this.state.selectedHomeClubLocations, search)
  }

  openSendAll = (e, type, user) => {
    if(type === 'Email_Confirmation'){
      // e.preventDefault()
      this.setState({
        emailUser: {
          id: user.id,
          name: user.full_name
        },
        userActivation_sent_at: user.activation_sent_at
      })
    }else if(['User_Activate', 'User_Deactivate'].includes(type)){
      this.setState({
        activateUser: user,
      })
    }else if(type === 'Delete_User'){
      this.setState({
        deleteUser: {
          id: user.id,
          name: user.full_name
        },
      })
    }else if(type === 'change_password'){
      this.setState({
        passwordUser: {
          id: user.id,
          name: user.full_name,
          email: user.email
        },
      })
      disableAutofill()
    }else if(type === 'resend_verification_email'){
      this.setState({
        resendEmailUser: {
          id: user.id,
          email: user.email
        }
      })
    }
    this.setState({
      sendAllModalType: type,
      sendAllModal: true
    })
  }

  closeModal = (e) => {
    enableEditing()
    this.setState({
      // deleteModal: false,
      showModal: false,
      // emailModal: false,
      sendAllModal: false,
      syncModal: false,
      // passwordModal: false,
      deleteUser: {
        id: '',
        name: ''
      },
      emailUser: {
        id: '',
        name: ''
      },
      passwordUser: {
        id: '',
        name: '',
        email: ''
      },
      remote_classes: [],
      selectedSiteId: '',
      // activateModal: false,
      // deactivateModal: false,
      syncing: false,
      abcClassesLoading: false,
      mboEmailFilter: false,
      selectedActive: false,
      abcActive: true,
      mboStaffFilter: false,
      showUpload: false,
      uploadOldUsers: [],
      uploadNewUsers: [],
      resendEmailUser: {
        id: '',
        email: ''
      }
    })
  }

  processSendAll = (e) => {
    e.preventDefault()
    this.setState({disabledSendAllBtn: true})
    sendConfirmationAll(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.inactiveUsers).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result.success,
          alert_type: 'success',
          // dataLoaded: false,
        })
        // this.getAll(this.state.activePage)
        this.getAllFilteredUsersAfterInvite(this.state.search_user)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          disabledSendAllBtn: false
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  processSend = (e) => {
    e.preventDefault()
    this.setState({disabledEmailConfirmBtn: true})
    sendConfirmation(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.emailUser).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result.success,
          alert_type: 'success',
          // dataLoaded: false,
        })
        // this.getAll(this.state.activePage)
        this.getAllFilteredUsersAfterInvite(this.state.search_user)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          disabledEmailConfirmBtn: false,
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  getAllFilteredUsersAfterInvite = (search) => {
    if(this.state.search_user !== "locations_selected" && this.state.search_user !== ""){
      search = this.state.search_user
    }
    // this.setState({
    //   dataLoaded: false
    // })
    this.performFilters(this.state.activePage, this.state.selectedLocations, this.state.sortType, this.state.sortReverse, this.state.selectedHomeClubLocations, search)
  }

  processAcitvate = (e,val) => {
    this.setState({disabledActivationBtn: true})
    updateUserActivation(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), val, this.state.activateUser).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result.success,
          alert_type: 'success',
          loaded: false,
        })
        // this.getAll(this.state.activePage)
        this.filter(this.state.search_user)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          disabledActivationBtn: false
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  processDelete = (e) => {
    e.preventDefault()
    this.setState({disabledDeleteBtn: true})
    deleteUser(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.deleteUser).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: 'User successfully Deleted',
          alert_type: 'success',
          loaded: false,
        })
        this.filter(this.state.search_user)
        // this.getAll(this.state.activePage)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          disabledDeleteBtn: false
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { newPass, confirmNewPass } = this.state;
    this.setState({
      submitted: true
    });

    if (newPass !== "" && confirmNewPass !== "") {
      if(newPass?.split("")?.length >= 6 && confirmNewPass?.split("")?.length >= 6){
        if(newPass === confirmNewPass){
          this.setState({
            notMatching: false
          })
          this.processChange()
        }else{
          this.setState({
            notMatching: true
          })
        }
      } else {
      console.log("Error creating User");
      }
    } else {
      console.log("Error creating User");
    }
  }

  processChange = () => {
    updatePassword(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.newPass, this.state.confirmNewPass, this.state.passwordUser).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result.success,
          alert_type: 'success',
          passwordModal: false
        })
        enableEditing()
        if(this.state.passwordUser?.id == this.props.user?.id){
          clearApiTokenAndRedirectToSignIn()
        }else{
          this.filter(this.state.search_user)
        }
        // this.getAll(this.state.activePage)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          syncModal: false,
          syncing: false
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })


  }

  sync_users = (e, syncType) => {
    e.preventDefault()
    let site_ids = []
    e.target.classList.add("btn-disabled");
    const elem = e.target
    this.setState({
      fetching: true
    })
    switch(syncType){
      case 'mbo':
        site_ids = this.props.user.assigned_mbo_site_ids
      break
      case 'marinatek':
        site_ids = ['']
      break;
    }
    this.setState({ site_ids, syncType })

    if(site_ids.length > 1){
      this.setState({
        direct_sync: false,
        syncModal: true,
        downloadModal: false,
        fetching: false
      })
      elem.classList.remove("btn-disabled");
    }else{
      this.setState({
        selectedSiteId: site_ids[0]
      })
      syncUsers(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), syncType, site_ids[0]).then(
        response => response.json()
      )
      .then(result => {
        elem.classList.remove("btn-disabled");
        if(result.status === 200){
          this.setLocations(result.users)
          this.setState({
            remote_classes: result.users,
            syncModal: true,
            fetching: false,
            syncTotal: result.total,
            syncActivePage: result.page,
            allSelected: false
          })
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger',
            show: true,
            syncModal: false
          })
        }
      })
    }
  }

  showClasses = () => {
    getRegionLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), '').then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          regionLocations: result.results,
          syncModal: true,
          fetching: false
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
    return
  }

  filterLocations = (val) => {
    if(val === ""){
      this.setState({
        regionLocations: []
      })
      return;
    }
    getRegionLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), val).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          regionLocations: result.results
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  setLocations = (locations) => {
    let req_locations = []
    let down_classes = []
    let check_active = []
    let mbo_check_id = []
    for(var i=0; i < locations?.length; i++){
      var o = {}
      var obj = {}
      var o_check = {}
      var mbo_obj = {}
      switch(this.state.syncType){
        case 'mbo':
          if(locations[i].mindbody_id > 0){
            mbo_obj['nonStaff'] = true
          }else{
            mbo_obj['nonStaff'] = false
          }
          if(locations[i].email === ""){
            mbo_obj['email'] = false
          }else{
            mbo_obj['email'] = true
          }
          if(locations[i].existing !== true){
            o[locations[i].mindbody_id] = locations[i].existing;
            // obj[locations[i].mindbody_id] = locations[i].existing;
            mbo_obj[locations[i].mindbody_id] = locations[i].existing;
          }else{
            o[locations[i].mindbody_id] = locations[i].existing;
            mbo_obj[locations[i].mindbody_id] = locations[i].existing;
            obj[locations[i].mindbody_id] = locations[i].existing;
          }
          break;
        case 'marinatek':
          o_check['active'] = locations[i].active;
          if(locations[i].existing !== true){
            o[locations[i].marianatek_id] = locations[i].existing;
            o_check[locations[i].marianatek_id] = locations[i].existing;
            obj[locations[i].marianatek_id] = locations[i].existing;
          }else{
            o[locations[i].marianatek_id] = locations[i].existing;
            o_check[locations[i].marianatek_id] = locations[i].existing;
          }
          break;
      }
      down_classes.push(obj)
      req_locations.push(o)
      check_active.push(o_check)
      mbo_check_id.push(mbo_obj)
    }
    this.setState({
      classestoAdd: req_locations,
      classesToDownload: down_classes,
      activeCheck: check_active,
      mboCheckId: mbo_check_id,
      holdClassToAdd: req_locations
    })
  }

  objectChecked = (cls) => {
    let existingClasses = this.state.classestoAdd;
    let checked = false
    for(var i=0; i < existingClasses?.length; i++){
      if(cls.existing === true){
        checked = true
      }else{
        switch(this.state.syncType){
          case 'mbo':
            if (typeof existingClasses[i][cls.mindbody_id] !== 'undefined' ) {
              checked = existingClasses[i][cls.mindbody_id] === true
            }
            break;
          case 'marinatek':
            if (typeof existingClasses[i][cls.marianatek_id] !== 'undefined' ) {
              checked = existingClasses[i][cls.marianatek_id] === true
            }
            break;
        }
      }
    }
    return checked
  }

  toggleRequestClass = (e, cls) => {
    if(cls.existing){
      return;
    }
    let existingClasses = this.state.classestoAdd;
    let checked = e.target.checked;

    for(var i=0; i < existingClasses.length; i++){
      switch(this.state.syncType){
        case 'mbo':
          if (typeof existingClasses[i][cls.mindbody_id] !== 'undefined' ) {
            existingClasses[i][cls.mindbody_id] = checked
          }
          break;
        case 'marinatek':
          if (typeof existingClasses[i][cls.marianatek_id] !== 'undefined' ) {
            existingClasses[i][cls.marianatek_id] = checked
          }
          break;
      }
    }
    this.setState({
      classestoAdd: existingClasses
    })
  }

  checkAll = (e, val) => {
    let existingClasses = this.state.classestoAdd;
    for(var i=0; i < existingClasses.length; i++){
      Object.keys(existingClasses[i]).map((property) => {
        existingClasses[i][property] = val;
      });
    }
    this.setState({
      classestoAdd: existingClasses,
      allSelected: val
    })
  }

  processSync = (e) => {
    e.preventDefault()
    e.target.classList.add("btn-disabled");
    const elem = e.target
    let site_ids = this.state.selectedSiteId
    this.setState({
      syncing: true
    })

    processSyncUsers(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.syncType, site_ids, this.state.classestoAdd, this.state.remote_classes).then(
      response => response.json()
    )
    .then(result => {
      elem.classList.remove("btn-disabled");
      if(result.status === 200){
        this.setState({
          alert_message: result?.success || 'Users Synced Successfully',
          alert_type: 'success',
          syncModal: false,
          syncType: '',
          site_ids: [],
          selectedSiteId: '',
          remote_classes: [],
          syncing: false
        })
        this.getAll(this.state.activePage)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          syncModal: false,
          syncing: false
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  selectMboSite = (e) => {
    this.setState({
      abcClassesLoading: true,
      classestoAdd: [],
      remote_classes: [],
      selectedSiteId: e.target.value
    })
    syncUsers(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), 'mbo', e.target.value).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setLocations(result.users)
        this.setState({
          remote_classes: result.users,
          syncTotal: result.total,
          syncActivePage: result.page,
          syncModal: true,
          abcClassesLoading: false,
          allSelected: false
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          syncModal: false
        })
      }
    })
  }

  selectActive = (e) => {
    let existingClasses = this.state.classestoAdd;
    let check_active = this.state.activeCheck;
    let val = e.target.checked
    for(var i=0; i < check_active.length; i++){
      if(check_active[i].active === true ){
        //console.log(check_active[i].existingClasses[i].keys)
        Object.keys(existingClasses[i]).map((property) => {
         existingClasses[i][property] = val;
        });
      }
    }
    this.setState({
      classestoAdd: existingClasses,
      selectedActive: val
    })
    //allSelected: val
  }

  mboSelectFilters = (e, type) => {
    let existingClasses = this.state.classestoAdd;
    let mboUsers = this.state.mboCheckId
    let val =  e.target.checked
    if(type === "email"){
      this.setState({
        mboEmailFilter: val
      })
      this.mboCheckFilter(this.state.mboStaffFilter, val)
    }
    else if(type === "staff"){
      this.setState({
        mboStaffFilter: val
      })
      this.mboCheckFilter(val , this.state.mboEmailFilter)
    }
  }

  mboCheckFilter = (nonstaff, email) => {
    let existingClasses = this.state.holdClassToAdd;
    let mboUsers = this.state.mboCheckId
    if(email === true && nonstaff === true){
      for(var i=0; i < mboUsers.length; i++){
        if(mboUsers[i].email === true && mboUsers[i].nonStaff === true){
          Object.keys(existingClasses[i]).map((property) => {
            existingClasses[i][property] = true;
          });
        }
        else{
          Object.keys(existingClasses[i]).map((property) => {
            existingClasses[i][property] = false;
          });
        }
      }
    }else if(email === false && nonstaff === true){
      for(var i=0; i < mboUsers.length; i++){
        if(mboUsers[i].nonStaff === true){
          Object.keys(existingClasses[i]).map((property) => {
            existingClasses[i][property] = true;
          });
        }else{
          Object.keys(existingClasses[i]).map((property) => {
            existingClasses[i][property] = false;
          });
        }
      }
    }else if(email === true && nonstaff === false){
      for(var i=0; i < mboUsers.length; i++){
        if(mboUsers[i].email === true){
          Object.keys(existingClasses[i]).map((property) => {
            existingClasses[i][property] = true;
          });
        }else{
          Object.keys(existingClasses[i]).map((property) => {
            existingClasses[i][property] = false;
          });
        }
      }
    }else if(email === false && nonstaff === false){
      let locations = this.state.remote_classes
      let req_locations = []
      let down_classes = []
      let mbo_check_id = []
      for(var i=0; i < locations?.length; i++){
        var o = {}
        var obj = {}
        var mbo_obj = {}

        if(locations[i].mindbody_id > 0){
          mbo_obj['nonStaff'] = true
        }else{
          mbo_obj['nonStaff'] = false
        }
        if(locations[i].email === ""){
          mbo_obj['email'] = false
        }else{
          mbo_obj['email'] = true
        }
        if(locations[i].existing !== true){
          o[locations[i].mindbody_id] = locations[i].existing;
          obj[locations[i].mindbody_id] = locations[i].existing;
          mbo_obj[locations[i].mindbody_id] = locations[i].existing;
        }else{
          o[locations[i].mindbody_id] = locations[i].existing;
          mbo_obj[locations[i].mindbody_id] = locations[i].existing;
        }

        down_classes.push(obj)
        req_locations.push(o)
        mbo_check_id.push(mbo_obj)
      }
      existingClasses = req_locations
    }

    this.setState({
      classestoAdd: existingClasses,
    })
  }

  download = (e, type) => {
    e.preventDefault()
    let filename = ""
    let site_ids = ""
    switch(type){
      case "marina":
        filename = "marina_users.csv"
        break;
      case "mbo":
        this.state.site_ids = this.props.user.assigned_mbo_site_ids
        filename = "mbo_users.csv"
        break;
    }
    if(type === 'mbo'){
      site_ids = this.state.site_ids
      if(site_ids.length > 1){
        this.setState({
          syncType: "mbo",
          syncModal: true,
          downloadModal: true
        })
      }else{
        this.setState({
          selectedSiteId: site_ids[0]
        })
        downloadData(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), type, site_ids[0]).then(
          response => response.blob()
          .then(result => {
            const url = window.URL.createObjectURL(result)
            const link = document.createElement('a')
            link.href = url

            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        )
      }
    }
    else{
      downloadData(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), type).then(
        response => response.blob()
        .then(result => {
          const url = window.URL.createObjectURL(result)
          const link = document.createElement('a')
          link.href = url

          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      )
    }
  }

  selectMboSiteId = (e) => {
    this.setState({selectedSiteId: e.target.value})
  }

  downloadMob = (e) => {
    if(!this.state.selectedSiteId){
      this.setState({
        alert_message: 'Please select atleast one Site',
        alert_type: 'danger',
        show: true
      })
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
      return;
    }

    e.preventDefault()
    let filename = "sample_all_users_"+this.state.selectedSiteId.toString()+".csv"
    downloadData(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), 'mbo', this.state.selectedSiteId).then(
      response => response.blob()
        .then(result => {
          const url = window.URL.createObjectURL(result)
          const link = document.createElement('a')
          link.href = url

          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      )
  }

  handleSyncPageChange(pageNumber) {
    this.setState({
      syncActivePage: pageNumber,
      abcClassesLoading: true,
      allSelected: false
    })
    this.handleSyncPaginatedResults(pageNumber)
  }

  handleSyncPaginatedResults = (pageNumber) => {
    this.setState({
      abcClassesLoading: true,
      classestoAdd: [],
      remote_classes: []
    })
    syncPaginatedUsers(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), pageNumber, this.state.selectedSiteId).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setLocations(result.users)
        this.setState({
          remote_classes: result.users,
          syncTotal: result.total,
          syncActivePage: result.page,
          syncModal: true,
          abcClassesLoading: false
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          syncModal: false
        })
      }
    })
  }

  setAdd = (e) => {
    e.preventDefault()
    this.setState({
      blank: false,
      dataReady: false,
      showModal: true,
      formType: 'add',
      userForm: initial_user
    },()=>{
      window.setTimeout(()=>{
        this.setState({dataReady: true})
      },50)
    })
  }

  setEdit = (e, user) => {
    e.preventDefault()
    this.setState({
      dataReady: false,
      showModal: true,
      formType: 'edit',
      userForm: user
    },()=>{
      window.setTimeout(()=>{
        this.setState({dataReady: true})
      },50)
    })
  }

  addUser = (req) => {
    this.setState({showModal: false,})
    createUser(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), req).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result.error,
          alert_type: 'success',
          // loaded: false,
        })
        this.filter(this.state.search_user)
        // this.getAll(this.state.activePage)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger'
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  editUser = (req) => {
    this.setState({showModal: false,})
    updateUser(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), req).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result.error,
          alert_type: 'success',
          dataLoaded: false,
          showModal: false,
        })
        if(req?.id === this.props.user?.id){
          this.props.setLoaded()
        }
        this.filter(this.state.search_user)
        // this.getAll(this.state.activePage)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger'
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  saveUpload = () => {
    uploadUsers(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.uploadNewUsers).then(
      response => response.json()
      .then(result => {
        if(result.status === 200){
          this.setState({
            uploadNewUsers: [],
            uploadOldUsers: [],
            alert_message: 'File uploaded successfully',
            alert_type: 'success',
            loaded: true,
            showUpload: false
          })
          this.getAll()
        }else{
          this.setState({
            alert_message: result.error,
            alert_type: 'danger',
            loaded: true
          })
        }
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      })
    )
  }

  processDownload = (e) => {
    e.preventDefault()
    let site_ids = this.state.selectedSiteId
    let filename = this.props.business?.subdomain.toString()+"_users_"+site_ids.toString()+".csv"
    downloadMboSyncData(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), site_ids, this.state.classestoAdd).then(
      response => response.blob()
        .then(result => {
          const url = window.URL.createObjectURL(result)
          const link = document.createElement('a')
          link.href = url

          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
      )
  }

  handleCreateTempPassword = (e, user) => {
    e.preventDefault();
    assignTempPassword(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), user?.id, user?.can_login_with_temp_password ? false : true).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        let users = this.state.users
        for (let i = 0; i < users?.length; i++){
          if(users[i]?.id == result?.user_id){
            users[i] = {...users[i], can_login_with_temp_password: result?.user_temp_password}
          }
        }
        this.setState({
          alert_message: result?.message,
          alert_type: 'success',
          // users: users
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  processSendEmailVerification = (e) => {
    e.preventDefault()
    this.setState({disabledEmailReVerificationBtn: true})
    sendEmailVerification(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.resendEmailUser.id).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: result.success,
          alert_type: 'success',
        })
        this.getAllFilteredUsersAfterInvite(this.state.search_user)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          disabledEmailReVerificationBtn: false,
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  setTheState = (paramName, paramData) => {
    this.setState({
      [paramName]: paramData
    })
  }

  handleSaveHomeClub = (data) => {
    this.setState({dataLoaded: false})
    this.performFilters(1, this.state.selectedLocations, this.state.sortType, this.state.sortReverse, data, this.state.search_user)
  }

  showAlert = (show, type, message) => {
    this.setState({
      show: show,
      alert_type: type,
      alert_message: message
    })
  }

  closeAndReload = () => {
    this.setState({dataLoaded: false, showModal: false});
    this.performFilters(this.state.activePage, this.state.selectedLocations, this.state.sortType, this.state.sortReverse, this.state.selectedHomeClubLocations, this.state.search_user)
  }

  getCertificationCategories = () => {
    axiosGetRequest('/api/v3/documentation_categories').then(result => {
      if(result.status === 200){
        this.setState({
          certificationCategories: result.certification_categories
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
        })
        this.setState({show:true},()=>{
          window.setTimeout(()=>{
            this.setState({show:false})
          },3000)
        });
      }
    })
  }

  checkAllPeopleSelect = () => {
    let check = (this.state.userIdsForMassActions.every(v => this.state.selectedPeopleForMassActions.includes(v)) && this.state.userIdsForMassActions?.length !== 0)
    return check
  }

  handleSelectAllPeople = (e) => {
    const { users, userIdsForMassActions, selectedPeopleForMassActions } = this.state
    var result = userIdsForMassActions
    let selected_people = selectedPeopleForMassActions

    if(e.target.checked){
      for(var i=0; i<result?.length;i++){
        var usr = users.find(elem => elem?.id === result[i])
        if(!selected_people.includes(result[i])){
          selected_people.push(result[i])
        }
      }
      this.setTheState('selectedPeopleForMassActions', selected_people)
    }else{
      let new_peoples = selected_people.filter(item => !result.includes(item))
      this.setTheState('selectedPeopleForMassActions', new_peoples)
    }
  }

  handleSelectPeople = (e) => {
    let val = parseInt(e.target.value)
    let selectedValues = this.state.selectedPeopleForMassActions
    var __FOUND = selectedValues.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newUsers = [...selectedValues, val]
        this.setTheState('selectedPeopleForMassActions', newUsers)
      }
    }else{
      if(__FOUND){
        let newUsers = selectedValues.filter((tag, index) => tag !== val)
        this.setTheState('selectedPeopleForMassActions', newUsers)
      }
    }
  }

  handleAssignCertification = (data) => {
    this.setState({
      showAssignCertPopup: data
    })
  }

  closeAssignCertPopup = () => {
    this.setState({
      showAssignCertPopup: false
    })
  }

  completeMassAssignActions = () => {
    this.setState({
      showAssignCertPopup: false,
      selectedPeopleForMassActions: [],
      dataLoaded: false
    })
    this.getAllFilteredUsersAfterInvite(this.state.search_user)
  }

  render() {
    const { locationSettings, showFilterOther, enabled, selectedLocations, selectedRegions, users, alert_type, alert_message, show, loaded, activePage, total, baseCount, search, noSkillUsers, inactiveUsers, rolesName, pageSize, dataLoaded, showUpload, sortType, sortReverse, sendAllModal, userActivation_sent_at, emailUser, sendAllModalType, activateUser, deleteUser, passwordUser, showPassword, newPass, confirmNewPass, showConfirmPassword, notMatching, submitted, site_ids, fetching, syncModal, remote_classes, downloadModal, selectedSiteId, activeCheck, mboCheckId, holdClassToAdd, syncType, abcClassesLoading, allSelected, syncing, mboStaffFilter, mboEmailFilter, selectedActive, showModal, roles, showFormOther, teachingRegionWithLocations, showFormOtherTeaching, regionWithLocations, skills, formType, userForm, time_zones, uploadNewUsers, uploadOldUsers, disabledActivationBtn, disabledDeleteBtn, disabledEmailConfirmBtn, disabledSendAllBtn, disabledEmailReVerificationBtn, resendEmailUser, userSyncSkills, dataReady, selectedHomeClubLocations, selectedHomeClubRegions, search_user, certificationCategories, userIdsForMassActions, selectedPeopleForMassActions, showAssignCertPopup } = this.state
    const {user, business, loadingMessage, permissions} = this.props
    const isValidNewOption = (inputValue, selectValue) => inputValue.length > 0 && selectValue.length < 3;

    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
          </React.Fragment>
        }
        {showAssignCertPopup && <AssignCertificationPopup showAssignCertPopup={showAssignCertPopup} closeAssignCertPopup={this.closeAssignCertPopup} certificationCategories={certificationCategories} selectedPeopleForMassActions={selectedPeopleForMassActions} showAlert={this.showAlert} completeMassAssignActions={this.completeMassAssignActions}/>}
        <div className='w-full min-h-screen bg-gray-50'>
          <div className='h-full'>
            {!loaded &&
              <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                <p className="mb-1 text-center px-5">{loadingMessage}</p>
                <img className = "w-9" src="/assets/business/loading.gif" />
              </div>
            }
            {loaded &&
              <div className='max-w-8xl mx-auto h-full'>
                <div className='hidden mt-2 mb-5'>
                  <div className='bg-white break-words p-3 border border-gray-200 rounded-lg'>
                    {((noSkillUsers > 0) && (user.role_display === "Netgym Employee" || user.role_display === "Business Admin")) &&
                      <p className="text-center text-gray-500 text-sm font-normal mb-2">
                        <span className="settings-text class-detail-count">
                          You have {noSkillUsers > 0 && <span><strong>{noSkillUsers}</strong> instructors without assigned locations and/or skills</span>}.
                        </span>
                      </p>
                    }
                    <p className="text-gray-500 text-sm font-normal">
                      No more outdated distribution lists! Quickly add or delete users in your account! When you add a new user, you’ll assign their role, skills, and locations. New users will receive an invite to activate their NetGym account.
                    </p>
                  </div>
                </div>
                <div className='flex flex-wrap gap-2'>
                  <LocationComponent locationSettings={locationSettings} showFilterOther={showFilterOther} selectedLocations={selectedLocations} selectedRegions={selectedRegions} setTheState={this.setTheState} setSelLocationsHandler={this.setSelLocations}/>
                  <div className="w-60 relative rounded-md mb-3 md:mb-0">
                    <div className='w-full bg-white border border-gray-300 shadow-sm rounded-md h-10 flex items-center pr-1.5'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4 ml-3.5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                      </svg>
                      <input type="text" className="text-sm w-full border-0 h-10 ml-1.5 focus:outline-none bg-transparent users-search-class" placeholder="Search" value={search_user} onChange = {(e) => this.search(e.target.value)}/>
                    </div>
                  </div>
                  <HomeClubComponent locationSettings={locationSettings} showFilterOther={showFilterOther} selectedHomeClubLocations={selectedHomeClubLocations} selectedHomeClubRegions={selectedHomeClubRegions} setTheState={this.setTheState} handleSaveHomeClub={this.handleSaveHomeClub} />

                </div>
                <div className='grid items-center'>
                  <div className='w-full pt-3 px-0 lg:flex flex items-center gap-2 flex-wrap'>

                    {(permissions?.business_users?.write || user.role_display === "Netgym Employee") &&
                      <React.Fragment>
                        {(business?.marianatek_integration || business?.mbo_integration) ?
                          <div className=''>
                            <a className='bg-dark-blue text-white hover:bg-gray-800 text-sm rounded-md py-2 px-7 font-medium h-9 block cursor-pointer' onClick={(e) => this.sync_users(e, business?.mbo_integration ? 'mbo' : business?.marianatek_integration ? 'marinatek' : '')}>
                              {fetching &&
                                <React.Fragment>
                                  <div className="flex items-center justify-center relative">
                                    <svg className="animate-spin -right-5 h-4 w-4 text-white absolute" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    {/* <p className="text-center">{window.getStartSyncSlogan()}</p> */}
                                    <p className="text-center">Add User from {business?.mbo_integration ? 'MBO' : business?.marianatek_integration ? 'MT' : ''}</p>
                                  </div>
                                </React.Fragment>
                              }
                              {!fetching &&
                                <React.Fragment>
                                  Add User from {business?.mbo_integration ? 'MBO' : business?.marianatek_integration ? 'MT' : ''}
                                </React.Fragment>
                              }
                            </a>
                          </div>
                          :
                          (!business.crunch_integration ?
                            <div className=''>
                              <a className='bg-dark-blue text-white hover:bg-gray-800 text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => this.setAdd(e)}>Add New User</a>
                            </div>
                            :
                            (user.role_display === "Netgym Employee" &&
                              <div className=''>
                                <a className='bg-dark-blue text-white hover:bg-gray-800 text-sm rounded-md py-2 px-4 font-medium h-9 block cursor-pointer' onClick={(e) => this.setAdd(e)}>Add New User</a>
                              </div>
                            )
                          )
                        }
                      </React.Fragment>
                    }
                    <div>
                      <a className='border rounded-md border-gray-300 hover:bg-gray-100 shadow-sm text-gray-700 text-sm py-2 px-4 h-9 block cursor-pointer' onClick={(e) => this.export(e)}>Export Users</a>
                    </div>
                    {user.role_display === "Netgym Employee" &&
                      <React.Fragment>
                        {!(business?.mbo_integration && business?.multi_site_multi_staff_feature) &&
                          <div>
                            <div className='border rounded-md border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 relative h-9 block cursor-pointer hover:bg-gray-100'>Upload CSV
                            <input type="file" className='absolute w-full top-0 left-0 bottom-0 opacity-0 cursor-pointer' required="required" accept=".csv" onChange={(e) => this.upload(e)}/>
                            </div>
                          </div>
                        }
                        {(business?.marianatek_integration || business?.mbo_integration) &&
                          <React.Fragment>
                            <div>
                              <a className='border rounded-md border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 h-9 block cursor-pointer hover:bg-gray-100' onClick={(e) => this.setAdd(e)}>+ User (manual)</a>
                            </div>
                            <div>
                              <a className='border rounded-md border-gray-300 shadow-sm text-gray-700 text-sm py-2 px-4 h-9 block cursor-pointer hover:bg-gray-100' onClick={(e) => {this.download(e, business?.mbo_integration ? 'mbo' : business?.marianatek_integration ? 'marina' : '')}}>Download Users from {business?.mbo_integration ? 'MBO' : business?.marianatek_integration ? 'MT' : ''}</a>
                            </div>
                          </React.Fragment>
                        }
                      </React.Fragment>
                    }

                  </div>
                </div>
                <div className='mt-3 overflow-x-auto overflow-y-hidden sm:-mx-6 lg:-mx-8 mb-4'>
                  <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                    {!dataLoaded &&
                      <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                        <p className="mb-1 text-center px-5">{loadingMessage}</p>
                        <img className = "w-9" src="/assets/business/loading.gif" />
                      </div>
                    }
                    {dataLoaded &&
                      <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-200'>
                          <thead className='bg-gray-50'>
                            <tr>
                              {user?.documentations_feature &&
                                <th className="pl-4 pr-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                                  <div>
                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={this.checkAllPeopleSelect() == true ? true : false} value={'all'} onChange={(e) => this.handleSelectAllPeople(e)}/>
                                  </div>
                                </th>
                              }
                              <th className="hidden lg:table-cell pl-4 pr-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                <div>
                                  {['signup', 'signdown'].includes(sortType) ?
                                    <React.Fragment>
                                      {!sortReverse &&
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-500 min-w-16 cursor-pointer hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'signup', 'desc')}>
                                          <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                        </svg>
                                      }
                                      {sortReverse &&
                                        <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer min-w-16 text-gray-500 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'signdown', 'asc')}>
                                          <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                        </svg>
                                      }
                                    </React.Fragment>
                                  :
                                    // <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-500 h-5 w-5 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'signup', 'desc')}>
                                    //     <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                    //   </svg>

                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'signup', 'desc')}>
                                      <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                    </svg>
                                  }
                                </div>
                              </th>
                              <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                <div className='flex items-center'>
                                  <span className='cursor-pointer' onClick={(e) => this.sort(e, 'full_name', 'desc')}>Name</span> &nbsp; &nbsp;
                                  {sortType !== 'full_name' &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'full_name', 'desc')}>
                                      <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                    </svg>
                                  }
                                  {(sortType === 'full_name' && sortReverse) &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'full_name', 'asc')}>
                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                    </svg>
                                  }
                                  {(sortType === 'full_name' && !sortReverse) &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'full_name', 'desc')}>
                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                    </svg>
                                  }
                                </div>
                              </th>
                              <th className="hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                <div className='flex items-center'>
                                  <span className='cursor-pointer' onClick={(e) => this.sort(e, 'role_display', 'desc')}>Role</span>&nbsp; &nbsp;
                                  {sortType !== 'role_display' &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'role_display', 'desc')}>
                                      <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                    </svg>
                                  }
                                  {(sortType === 'role_display' && sortReverse) &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'role_display', 'asc')}>
                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                    </svg>
                                  }
                                  {(sortType === 'role_display' && !sortReverse) &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'role_display', 'desc')}>
                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                    </svg>
                                  }
                                </div>
                              </th>
                              {(business.mbo_integration && !user?.assigned_single_site_id) &&
                                <th className="hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">MBO Site Name</th>
                              }
                              <th className="hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                <div className='flex items-center'>
                                  <span className='cursor-pointer' onClick={(e) => this.sort(e, 'regions', 'desc')}>Region</span>&nbsp; &nbsp;
                                  {sortType !== 'regions' &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'regions', 'desc')}>
                                      <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                    </svg>
                                  }
                                  {(sortType === 'regions' && sortReverse) &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'regions', 'asc')}>
                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                    </svg>
                                  }
                                  {(sortType === 'regions' && !sortReverse) &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'regions', 'desc')}>
                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                    </svg>
                                  }
                                </div>
                              </th>
                              <th className="hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                <div className='flex items-center'>
                                  <span className='cursor-pointer' onClick={(e) => this.sort(e, 'email', 'desc')}>Email</span>&nbsp; &nbsp;
                                  {sortType !== 'email' &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'email', 'desc')}>
                                      <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                    </svg>
                                  }
                                  {(sortType === 'email' && sortReverse) &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'email', 'asc')}>
                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                    </svg>
                                  }
                                  {(sortType === 'email' && !sortReverse) &&
                                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'email', 'desc')}>
                                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                    </svg>
                                  }
                                </div>
                              </th>
                              {(permissions.business_users?.edit || user.role_display === "Netgym Employee") &&
                                <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  <div className='flex items-center'>
                                    <span className='cursor-pointer' onClick={(e) => this.sort(e, 'active', 'desc')}>Status</span>&nbsp; &nbsp;
                                    {sortType !== 'active' &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'active', 'desc')}>
                                        <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'active' && sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'active', 'asc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                      </svg>
                                    }
                                    {(sortType === 'active' && !sortReverse) &&
                                      <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.sort(e, 'active', 'desc')}>
                                        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                                      </svg>
                                    }
                                    <div className='ml-1'>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.openSendAll(e, 'Users_Activation', '')} data-tip data-for={`status`+user.id.toString()}>
                                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                      </svg>
                                      <ReactTooltip className='max-w-xs text-center' id={`status`+user.id.toString()} place="top" effect="solid">
                                        <p className='capitalize'>Send mass activation email</p>
                                        <p>{user?.business_activation_sent_at}</p>
                                      </ReactTooltip>
                                    </div>
                                  </div>
                                </th>
                              }
                              <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200'>
                            {selectedPeopleForMassActions?.length > 1 && 
                              <MassActionBar
                                selectedPeopleForMassActions={selectedPeopleForMassActions}
                                handleAssignCertification={this.handleAssignCertification}
                                pageTab={''}
                                user={user}
                                showAlert={this.showAlert}
                                reloadDataAfterSendActivationEmail={() => this.getAllFilteredUsersAfterInvite(search_user)}
                                setTheState={this.setTheState}
                                usersIds={[]}
                              />
                            }
                            {users.map((usr, i) =>
                              <tr key={i} className='bg-white'>
                                {user?.documentations_feature &&
                                  <>
                                    {userIdsForMassActions.includes(usr?.id) ?
                                      <td className='pl-4 pr-3 py-4 text-sm font-medium text-gray-900 text-center'>
                                        <input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1`} checked={selectedPeopleForMassActions.includes(usr?.id)} value={usr?.id} onChange={(e) => this.handleSelectPeople(e)}/>
                                      </td>
                                    :
                                      <td></td>
                                    }
                                  </>
                                }
                                <td className='hidden lg:table-cell pl-4 pr-3 py-4 text-sm font-medium text-gray-900'>
                                  {this.getTooltipMsg(usr) !== "" &&
                                    <div>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-4 w-4" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={usr.id.toString()}>
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                      </svg>
                                      <ReactTooltip className='max-w-xs' id={usr.id.toString()} place="top" effect="solid">
                                        {this.getTooltipMsg(usr)}
                                      </ReactTooltip>
                                    </div>
                                  }
                                </td>
                                <td className='w-full max-w-0 sm:w-auto sm:max-w-none px-3 py-4 text-sm text-gray-900'>
                                  <div className='inline lg:flex items-center'>
                                    <div>
                                      <img src={usr?.image} className='h-10 w-10 rounded-full min-wh-40'/>
                                    </div>
                                    <div className='ml-0 lg:ml-4'>
                                      <div className='text-sm text-gray-900 flex items-center font-medium break-words'>
                                        <div className='truncate'>{usr?.full_name}</div>
                                        <div className='lg:hidden table-cell ml-1'>
                                        {this.getTooltipMsg(usr) !== "" &&
                                          <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-4 w-4" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={usr.id.toString()}>
                                              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                            </svg>
                                            <ReactTooltip className='w-52' id={usr.id.toString()} place="top" effect="solid">
                                              {this.getTooltipMsg(usr)}
                                            </ReactTooltip>
                                          </div>
                                        }
                                        </div>
                                      </div>
                                      <div className='flex'>
                                        {(['all', 'email'].includes(usr.notifications) && !usr.away) &&
                                          <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" data-tip data-for={usr.id.toString()+'notificationType'}>
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                            </svg>
                                            {usr?.daily_notifications !== ''&&
                                              <ReactTooltip className='max-w-xs whitespace-nowrap' id={usr.id.toString()+'notificationType'} place="top" effect="solid">
                                                {usr?.daily_notifications === "daily" ? 'Daily summary' : 'Immediate sub request notifications'}
                                              </ReactTooltip>
                                            }
                                          </div>
                                        }
                                        {(['all', 'sms'].includes(usr.notifications) && !usr.away) &&
                                          <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" data-tip data-for={`text_notification`+usr.id.toString()+'notificationType'}>
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                                            </svg>
                                            <ReactTooltip className='max-w-xs whitespace-nowrap' id={`text_notification`+usr.id.toString()+'notificationType'} place="top" effect="solid">
                                              Text notifications
                                            </ReactTooltip>
                                          </div>
                                        }
                                        {usr.away &&
                                          <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" data-tip data-for={`notification`+usr.id.toString()+'notificationOff'}>
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <ReactTooltip className='max-w-xs whitespace-nowrap' id={`notification`+usr.id.toString()+'notificationOff'} place="top" effect="solid">
                                              Notifications Off
                                            </ReactTooltip>
                                          </div>
                                        }
                                      </div>
                                      <dl className='lg:hidden'>
                                        <dd className='mt-1'>
                                          <div className="text-sm text-gray-500 truncate items-center gap-3">
                                          {usr.email}
                                          {(usr.unconfirmed_email !== "" && usr.unconfirmed_email !== null && usr.status === "Yes") &&
                                            <React.Fragment >
                                              <svg xmlns="http://www.w3.org/2000/svg" className={`text-red-600 h-4 w-4 ${disabledEmailReVerificationBtn ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`} viewBox="0 0 20 20" fill="currentColor" data-tip data-for={usr.id.toString()+'email'} onClick={(e) => {!disabledEmailReVerificationBtn && (this.openSendAll(e, 'resend_verification_email', usr))}} disabled={disabledEmailReVerificationBtn}>
                                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                              </svg>
                                              <ReactTooltip className='max-w-xs' id={usr.id.toString()+'email'} place="top" effect="solid">
                                                Pending Verification
                                              </ReactTooltip>
                                            </React.Fragment>
                                          }
                                          </div>

                                        </dd>
                                        <dd className='mt-1'>
                                          {(business.mbo_integration && !user?.assigned_single_site_id) &&
                                            <div>
                                              <div className='text-sm text-gray-500'>{usr?.site_name}</div>
                                            </div>
                                          }
                                        </dd>
                                      </dl>
                                    </div>

                                  </div>
                                </td>
                                <td className='hidden lg:table-cell px-3 py-4'>
                                  <div className='text-sm text-gray-500'>{usr?.role_display}</div>
                                </td>
                                {(business.mbo_integration && !user?.assigned_single_site_id) &&
                                  <td className='hidden lg:table-cell px-3 py-4'>
                                    <div className='text-sm text-gray-500'>{usr?.site_name}</div>
                                  </td>
                                }
                                <td className='hidden lg:table-cell px-3 py-4'>
                                  <div className='text-sm text-gray-500'>{usr?.regions}</div>
                                </td>
                                <td className='hidden lg:table-cell px-3 py-4'>
                                  <div className="text-sm text-gray-500 items-center flex gap-3">
                                    {usr.email}
                                    {(usr.unconfirmed_email !== "" && usr.unconfirmed_email !== null && usr.status === "Yes") &&
                                      <React.Fragment >
                                        <button className={`${disabledEmailReVerificationBtn ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={(e) => this.openSendAll(e, 'resend_verification_email', usr)} disabled={disabledEmailReVerificationBtn}>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-4 w-4" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={usr.id.toString()+'email'}>
                                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                          </svg>
                                          </button>
                                        <ReactTooltip className='max-w-xs' id={usr.id.toString()+'email'} place="top" effect="solid">
                                          Pending Verification
                                        </ReactTooltip>
                                      </React.Fragment>
                                    }
                                  </div>
                                </td>
                                {(permissions.business_users?.edit || user.role_display === "Netgym Employee") &&
                                  <td className='px-3 py-4'>
                                    <React.Fragment>
                                      {(usr.status === 'Yes') ?
                                        <React.Fragment>
                                          {(rolesName.includes(usr?.role)) &&
                                          <div>
                                            <Switch
                                              checked={usr.user_activated}
                                              onChange={(e) => this.openSendAll(e, e === true ? 'User_Activate' : 'User_Deactivate', usr)}
                                              className={`${usr.user_activated ? 'bg-green-400' : 'bg-red-600'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none ${business.crunch_integration ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
                                              data-tip data-for={`status_`+usr?.id.toString()}
                                              disabled={business.crunch_integration}
                                            >
                                              {/* <span className="sr-only">Use setting</span> */}
                                              <span
                                                aria-hidden="true"
                                                className={`${usr.user_activated ? 'translate-x-5' : 'translate-x-0'} 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'`}
                                              />
                                            </Switch>
                                            <ReactTooltip className='max-w-xs whitespace-nowrap' id={`status_`+usr?.id.toString()} place="top" effect="solid">
                                              {usr.user_activated ? 'Deactivate user' : 'Activate user'}
                                            </ReactTooltip>
                                          </div>
                                          }
                                          {/* <div className='text-xs text-gray-500 inline-block px-2.5 py-0.5 rounded-xl font-medium text-center'>{`${usr.user_activated ? 'Active' : 'Inactive'}`}</div> */}
                                        </React.Fragment>
                                      :
                                        <React.Fragment>
                                          <div className='flex items-center gap-0.5'>
                                            <div className='text-xs text-gray-500 inline-block rounded-xl font-medium text-center'>{usr.status}</div>
                                            {usr.email &&
                                              <React.Fragment>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 hover:text-gray-400 h-3.5 w-3.5 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={`envelope_${usr.id}`} onClick={(e) => this.openSendAll(e, 'Email_Confirmation', usr)}>
                                                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                                </svg>
                                                {usr.activation_sent_at !== "" &&
                                                  <ReactTooltip id={`envelope_${usr.id}`} place="top" effect="solid">
                                                    {usr.activation_sent_at}
                                                  </ReactTooltip>
                                                }
                                              </React.Fragment>
                                            }
                                            {/* {usr.email &&
                                              <div className='text-xs text-gray-500 inline-block rounded-xl font-medium text-center'>Invited</div>
                                            } */}
                                          </div>
                                        </React.Fragment>
                                      }
                                    </React.Fragment>
                                  </td>
                                }
                                <td className='px-3 py-4'>
                                  <div className='flex gap-x-2'>
                                    {(permissions?.business_users?.edit || user.role_display === "Netgym Employee") &&
                                      <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => this.setEdit(e, usr)} data-tip data-for={`edit_button_`+usr?.id.toString()}>
                                        <a className='cursor-pointer'>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                          </svg>
                                        </a>
                                        <ReactTooltip className='max-w-xs whitespace-nowrap' id={`edit_button_`+usr?.id.toString()} place="top" effect="solid">
                                          Edit user
                                        </ReactTooltip>
                                      </div>
                                    }
                                    {usr.status === 'Yes' && usr.user_activated && (permissions.business_users?.update_password || user.role_display === "Netgym Employee") &&
                                      <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => this.openSendAll(e, 'change_password', usr)} data-tip data-for={`password_${usr.id}`}>
                                        <a >
                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clipRule="evenodd" />
                                          </svg>
                                        </a>
                                        <ReactTooltip id={`password_${usr.id}`} place="top" effect="solid">
                                          Change user password
                                        </ReactTooltip>
                                      </div>
                                    }
                                    {(permissions.business_users?.write || user.role_display === "Netgym Employee") && rolesName.includes(usr?.role) &&
                                      (!business.crunch_integration ?
                                          <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => this.openSendAll(e, 'Delete_User', usr)} data-tip data-for={`delete_button_`+usr?.id.toString()}>
                                            <a className='cursor-pointer'>
                                              <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                              </svg>
                                            </a>
                                            <ReactTooltip className='max-w-xs whitespace-nowrap' id={`delete_button_`+usr?.id.toString()} place="top" effect="solid">
                                              Delete user
                                            </ReactTooltip>
                                          </div>
                                        :
                                          (user.role_display === "Netgym Employee" &&
                                            <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => this.openSendAll(e, 'Delete_User', usr)} data-tip data-for={`delete_button_`+usr?.id.toString()}>
                                              <a className='cursor-pointer'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                  <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                                </svg>
                                              </a>
                                              <ReactTooltip className='max-w-xs whitespace-nowrap' id={`delete_button_`+usr?.id.toString()} place="top" effect="solid">
                                                Delete user
                                              </ReactTooltip>
                                            </div>
                                          )
                                      )
                                    }
                                    {user?.ng_big_gun &&
                                      <div className={`${usr?.can_login_with_temp_password ? 'bg-green-400 hover:bg-green-500' : 'bg-red-400 hover:bg-red-500'} w-8 h-8 rounded flex items-center justify-center cursor-pointer`} onClick={(e) => this.handleCreateTempPassword(e, usr)} data-tip data-for={`create_temp_password_${usr.id}`}>
                                        <a >
                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clipRule="evenodd" />
                                          </svg>
                                        </a>
                                        <ReactTooltip id={`create_temp_password_${usr.id}`} place="top" effect="solid">
                                          {usr?.can_login_with_temp_password ? "Delete temporary password" : "Assign temporary password"}
                                        </ReactTooltip>
                                      </div>
                                    }
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {this.state.total === 0 &&
                          <div className='bg-white shadow-md min-h-screen border border-gray-300 px-6 pt-7 flex justify-center rounded-b-lg items-center'>
                            <div className='flex items-center'>
                              <div className='flex flex-col'>
                                <div className='w-28 bg-gray-300 rounded p-1 relative'>
                                  <div className='bg-white border border-black rounded px-2.5 py-3'>
                                    <div className='flex items-center gap-2'>
                                      <div className='w-3 h-3 bg-dark-blue rounded-full'></div>
                                      <div className='flex flex-col gap-1.5'>
                                        <div className='bg-gray-300 w-8 h-1 rounded-full'></div>
                                        <div className='bg-gray-300 w-12 h-1 rounded-full'></div>
                                      </div>
                                    </div>
                                    <div className='flex justify-end mt-2.5 gap-0.5'>
                                      <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                      <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                      <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                    </div>
                                  </div>
                                  <div className='absolute -bottom-2 left-2'>
                                    <img src='/assets/bottom-angle.png' />
                                  </div>
                                </div>
                                <div className='w-28 bg-gray-300 rounded p-1 relative mt-3 ml-12'>
                                  <div className='bg-white border border-black rounded px-2.5 py-3'>
                                    <div className='flex items-center gap-2'>
                                      <div className='w-3 h-3 bg-dark-blue rounded-full'></div>
                                      <div className='flex flex-col gap-1.5'>
                                        <div className='bg-gray-300 w-8 h-1 rounded-full'></div>
                                        <div className='bg-gray-300 w-12 h-1 rounded-full'></div>
                                      </div>
                                    </div>
                                    <div className='flex justify-end mt-2.5 gap-0.5'>
                                      <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                      <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                      <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                                    </div>
                                  </div>
                                  <div className='absolute -bottom-2 right-2 transform rotate-90'>
                                    <img src='/assets/bottom-angle.png' />
                                  </div>
                                </div>
                              </div>
                              <div className='ml-9'>
                                <div className='text-gray-500 text-sm mb-3'>No Users found</div>
                                {/* <Link to='/messenger/create-post' className='bg-dark-blue text-white text-xs py-2 px-3.5 rounded'>Create your first post !</Link> */}
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>
                  <div className="flex justify-center w-full flex-wrap mt-4 pb-2">
                    <div></div>
                    <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0 mr-7">
                      {total > pageSize &&
                        <Pagination
                          activePage={activePage}
                          itemsCountPerPage={pageSize}
                          totalItemsCount={total}
                          pageRangeDisplayed={9}
                          onChange={this.handlePageChange.bind(this)}
                          itemClassPrev='previous'
                          itemClassNext='next'
                          itemClassFirst='first'
                          itemClassLast='last'
                          hideDisabled={true}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        {showModal &&
          <UserForm
            showModal={showModal}
            setShowModal={this.closeModal}
            formType={formType}
            regionWithLocations={regionWithLocations}
            teachingRegionWithLocations={teachingRegionWithLocations}
            roles={roles}
            user={userForm}
            currentUser={user}
            business={business}
            time_zones={time_zones}
            skills={skills}
            addUser={this.addUser}
            editUser={this.editUser}
            showFormOther={showFormOther}
            showFormOtherTeaching={showFormOtherTeaching}
            currentUserPermissions={permissions}
            userSyncSkills={userSyncSkills || []}
            dataReady={dataReady}
            closeAndReload={this.closeAndReload}
            showAlert={this.showAlert}
            certificationCategories={certificationCategories}
          />
        }
        <Transition.Root show={sendAllModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
						<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>

							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6 mt-20">
									<div className="absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
											onClick={(e) => this.closeModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										{/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div> */}
										<div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left w-full">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 border-b pb-2">
                        {sendAllModalType === 'Users_Activation' &&
                          'Users Activation'
                        }
                        {sendAllModalType === 'Email_Confirmation' &&
                          'Email Confirmation'
                        }
                        {['User_Activate', 'User_Deactivate'].includes(sendAllModalType) &&
                          'User Activation'
                        }
                        {sendAllModalType === 'Delete_User' &&
                          'Delete User'
                        }
                        {sendAllModalType === 'change_password' &&
                          'Change User Password'
                        }
                        {sendAllModalType === 'resend_verification_email' &&
                          'Resend Verification Email'
                        }
											</Dialog.Title>
											<div className="mt-4">
                        {sendAllModalType === 'Users_Activation' &&
                          <p className="text-sm text-gray-500">
                          Do you want to send activation emails to {inactiveUsers} Instructors that have not activated their accounts?
                          </p>
                        }
                        {sendAllModalType === 'Email_Confirmation' &&
                          <React.Fragment>
                            {userActivation_sent_at !== "" &&
                              <p className="text-sm text-gray-500">Do you want to send another Activation Email to {emailUser.name}</p>
                            }
                            {userActivation_sent_at === "" &&
                              <p className="text-sm text-gray-500">Do you want to send an Activation Email to {emailUser.name}</p>
                            }
                          </React.Fragment>
                        }
                        {sendAllModalType === 'User_Deactivate' &&
                          <p className="text-sm text-gray-500">
                            Please click confirm to deactivate {activateUser.full_name}'s account. By confirming, {activateUser.full_name} will no longer have access to NetGym until activated again.
                          </p>
                        }
                        {sendAllModalType === 'User_Activate' &&
                          <p className="text-sm text-gray-500">
                            Do you want to activate {activateUser.full_name} ?
                          </p>
                        }
                        {sendAllModalType === 'Delete_User' &&
                          <p className="text-sm text-gray-500">Are you sure you want to delete {deleteUser.name} ?</p>
                        }
                        {sendAllModalType === 'change_password' &&
                          <React.Fragment>
                            <div className="mb-2">
                              <div className='text-center items-center mb-3'>
                                <p className='text-gray-700 w-full ite'>Change {passwordUser.name}'s Password</p>
                              </div>
                              <div className='flex flex-wrap gap-x-4 mb-2 items-center'>
                                <p className='text-sm font-medium text-gray-500 text-left w-36'>New Password</p>
                                <div className="mt-1 sm:mt-0 w-full sm:w-64">
                                  <div className="relative flex w-full sm:w-64">
                                    <input type= {showPassword ? "text" : "password"} name="newPass" onChange={(e)=>this.handleChange(e)} placeholder="Enter New Password" className='h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' />
                                    <div className='absolute right-0 pr-2 py-2.5'>
                                      {/* Clicking this will show password */}
                                      {!showPassword &&
                                        <React.Fragment>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-5 w-5" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.setState({showPassword: true})}>
                                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                            <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                          </svg>
                                        </React.Fragment>
                                      }
                                      {/* Clicking this will hide password */}
                                      {showPassword &&
                                        <React.Fragment>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-5 w-5" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.setState({showPassword: false})}>
                                            <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                                            <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                                          </svg>
                                        </React.Fragment>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='flex gap-4 mb-2 items-center'>
                                <div className='text-gray-700 w-36'></div>
                                <div className="relative">
                                  {(newPass !== "" && newPass?.split("")?.length < 6) &&
                                    <span className="error text-xs">*Password should be minimum 6 characters long.</span>
                                  }
                                  {submitted && !newPass && (
                                    <span className="error text-xs">*New Password is required</span>
                                  )}
                                </div>
                              </div>
                              <div className='flex flex-wrap gap-x-4 mb-2 items-center'>
                                <p className='text-sm font-medium text-gray-500 text-left w-36'>Confirm Password</p>
                                <div className="mt-1 sm:mt-0 w-full sm:w-64">
                                  <div className="relative flex w-full sm:w-64">
                                    <input type={showConfirmPassword ? "text" : "password"} name="confirmNewPass" onChange={(e)=>this.handleChange(e)} placeholder="Confirm New Password" className='h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500' />
                                    <div className='absolute right-0 pr-2 py-2.5'>
                                      {/* Clicking this will show confirm password */}
                                      {!showConfirmPassword &&
                                        <React.Fragment>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-5 w-5" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.setState({showConfirmPassword: true})}>
                                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                            <path fillRule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                                          </svg>
                                        </React.Fragment>
                                      }
                                      {/* Clicking this will hide confirm password */}
                                      {showConfirmPassword &&
                                        <React.Fragment>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-5 w-5" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => this.setState({showConfirmPassword: false})}>
                                            <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd" />
                                            <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                                          </svg>
                                        </React.Fragment>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='flex gap-4 mb-2 items-center'>
                                <div className='text-gray-700 w-36'></div>
                                <div className="relative">
                                  {(confirmNewPass !== "" && confirmNewPass?.split("")?.length < 6) &&
                                    <span className="error text-xs">*Password should be minimum 6 characters long.</span>
                                  }
                                  {submitted && !confirmNewPass && (
                                    <span className="error text-xs">*Password Confirmation is required</span>
                                  )}
                                  {submitted && notMatching && (
                                    <span className="error text-xs">*Passwords do not match</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        }
                        {sendAllModalType === 'resend_verification_email' &&
                          <p className="text-sm text-gray-500">Would you like to resend the “Verify New Email Address” notification to {resendEmailUser.email}?</p>
                        }
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    {sendAllModalType === 'Users_Activation' &&
                      <React.Fragment>
                        <button
                          type="button"
                          className={`cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm ${disabledSendAllBtn ? 'button-disabled bg-gray-400' : 'bg-dark-blue'}`}
                          onClick={(e) => this.processSendAll(e)}
                          disabled={disabledSendAllBtn}
                        >
                          <div className='text-white text-base flex items-center gap-2.5 relative px-1'>
                            {disabledSendAllBtn &&
                              <div className="flex items-center -left-3.5 justify-center absolute">
                                <svg className="animate-spin h-3.5 w-3.5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                              </div>
                            }
                            Yes
                          </div>
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={(e) => this.closeModal(e)}
                        >
                          Cancel
                        </button>
                      </React.Fragment>
                    }
                    {sendAllModalType === 'Email_Confirmation' &&
                      <button
                        type="button"
                        className={`cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm ${disabledEmailConfirmBtn ? 'button-disabled bg-gray-400' : 'bg-dark-blue'}`}
                        onClick={(e) => this.processSend(e)}
                        disabled={disabledEmailConfirmBtn}
                      >
                        <div className='text-white text-base flex items-center gap-2.5 relative px-1'>
                          {disabledEmailConfirmBtn &&
                            <div className="flex items-center -left-3.5 justify-center absolute">
                              <svg className="animate-spin h-3.5 w-3.5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                            </div>
                          }
                          Yes
                        </div>
                      </button>
                    }
                    {['User_Activate', 'User_Deactivate'].includes(sendAllModalType) &&
                      <button
                        type="button"
                        className={`cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm ${disabledActivationBtn ? 'button-disabled bg-gray-400' : 'bg-dark-blue'}`}
                        onClick={(e) => this.processAcitvate(e, sendAllModalType === 'User_Deactivate' ? false : true)}
                        disabled={disabledActivationBtn}
                      >
                        <div className='text-white text-base flex items-center gap-2.5 relative px-1'>
                          {disabledActivationBtn &&
                            <div className="flex items-center -left-3.5 justify-center absolute">
                              <svg className="animate-spin h-3.5 w-3.5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                            </div>
                          }
                          Confirm
                        </div>
                      </button>
                    }
                    {sendAllModalType === 'Delete_User' &&
                      <button
                        type="button"
                        className={`cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm ${disabledDeleteBtn ? 'button-disabled bg-gray-400' : 'bg-dark-blue'}`}
                        onClick={(e) => this.processDelete(e)}
                        disabled={disabledDeleteBtn}
                      >
                        <div className='text-white text-base flex items-center gap-2.5 relative px-1'>
                          {disabledDeleteBtn &&
                            <div className="flex items-center -left-3.5 justify-center absolute">
                              <svg className="animate-spin h-3.5 w-3.5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                            </div>
                          }
                          Delete User
                        </div>
                      </button>
                    }
                    {sendAllModalType === 'change_password' &&
                      <button
                        type="button"
                        className="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={(e) => this.handleSubmit(e)}
                      >
                        Update Password
                      </button>
                    }
                    {sendAllModalType === 'resend_verification_email' &&
                      <React.Fragment>
                        <button
                          type="button"
                          className={`cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm ${disabledEmailReVerificationBtn ? 'button-disabled bg-gray-400' : 'bg-dark-blue'}`}
                          onClick={(e) => this.processSendEmailVerification(e)}
                          disabled={disabledEmailReVerificationBtn}
                        >
                          <div className='text-white text-base flex items-center gap-2.5 relative px-1'>
                            {disabledEmailReVerificationBtn &&
                              <div className="flex items-center -left-3.5 justify-center absolute">
                                <svg className="animate-spin h-3.5 w-3.5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                              </div>
                            }
                            Yes
                          </div>
                        </button>
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={(e) => this.closeModal(e)}
                        >
                          No
                        </button>
                      </React.Fragment>
                    }
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
        <Transition.Root show={syncModal} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
						<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-4xl sm:w-full w-full sm:p-6 min-h-515 mt-20`}>
									<div className="absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
											onClick={(e) => this.closeModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										<div className="text-center sm:text-left w-full">
											<Dialog.Title as="h4" className="text-lg leading-6 font-medium text-gray-900 border-b">
                        {syncType === 'mbo' &&
                          <div className='flex'>
                            <p className='text-sm py-3.5'>MBO Users Preview</p>
                            <a className="powered-by-mindbody-logo flex items-center ml-2 mb-1" href="https://company.mindbodyonline.com/" target="_blank">
                              <img className="w-28" src="/assets/powered-by-mindbody-new.png" />
                            </a>
                          </div>
                        }
                        {syncType === 'marinatek' &&
                          <div className='flex'>
                            <p className='text-sm py-3.5'>Mariana Tek Users Preview</p>
                          </div>
                        }
											</Dialog.Title>
											<div className="mt-5">
                        {syncType === 'mbo' && !downloadModal &&
                          <div className='flex'>
                            {site_ids.length > 1 &&
                              <div className="relative filters_box  w-full lg:w-64">
                                <Menu as="div" className="relative block text-left">
                                  <div>
                                    <Menu.Button className="inline-flex items-center justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                      {selectedSiteId === '' ?
                                        'Select MBO Site'
                                      :
                                        (user.assigned_mbo_sites.map((detail, i) =>
                                          <div key={i}>
                                            {detail?.site_id == selectedSiteId &&
                                              <p className='text-gray-700'>
                                                {detail?.site_name}
                                              </p>
                                            }
                                          </div>
                                        ))
                                      }
                                      <ChevronDownIcon className="ml-auto w-4 mr-0.5 text-gray-400" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="p-2 max-h-60 overflow-y-auto">
                                        {user.assigned_mbo_sites.map((detail, i) =>
                                          <div className="py-1" key = {i}>
                                            <Menu.Item>
                                              {({ active }) => (
                                                <label className="flex items-center text-gray-900 text-sm mb-2">
                                                  <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => this.selectMboSite(e)} value={detail?.site_id} checked={selectedSiteId == detail.site_id}/>
                                                  {`${detail.site_name} (${detail.site_id})`}
                                                </label>

                                              )}
                                            </Menu.Item>
                                          </div>
                                        )}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            }
                          </div>
                        }
                        {syncType === 'mbo' && downloadModal &&
                          <div className='flex flex-col'>
                            {site_ids.length > 1 &&
                              <div className="relative filters_box  w-full lg:w-64">
                                <Menu as="div" className="relative block text-left">
                                  <div>
                                    <Menu.Button className="inline-flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                      {selectedSiteId === '' ?
                                        'Select MBO Site'
                                      :
                                        (user.assigned_mbo_sites.map((detail, i) =>
                                          <div key={i}>
                                            {detail?.site_id == selectedSiteId &&
                                              <p className='text-gray-700'>
                                                {detail?.site_name}
                                              </p>
                                            }
                                          </div>
                                        ))
                                      }
                                      <ChevronDownIcon className="ml-auto w-4 mr-0.5 text-gray-400" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="p-2 max-h-60 overflow-y-auto">
                                        {user.assigned_mbo_sites.map((detail, i) =>
                                          <div className="py-1" key = {i}>
                                            <Menu.Item>
                                              {({ active }) => (
                                                <label className="flex items-center text-gray-900 text-sm mb-2">
                                                  <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => this.selectMboSiteId(e)} value={detail?.site_id} checked={selectedSiteId == detail.site_id}/>
                                                  {`${detail.site_name} (${detail.site_id})`}
                                                </label>

                                              )}
                                            </Menu.Item>
                                          </div>
                                        )}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            }
                            <div className="mt-4 text-center">
                              <button className={`cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm`} onClick={(e) => this.downloadMob(e)} >
                                Download
                              </button>
                            </div>
                          </div>
                        }
                        <div>
                          {abcClassesLoading &&
                            <div className="sync-abc-preview-loading text-center">
                                <div className="loading-gif flex items-center justify-center flex-col my-4">
                                <p className="mb-1">{window.getSettingSlogan()}</p>
                                <img className='w-8 inline-block' src="/assets/business/loading.gif" />
                                </div>
                            </div>
                          }
                          {!abcClassesLoading &&
                            <div className="flex flex-col mt-3">
                              {remote_classes.length > 0 &&
                                <React.Fragment>
                                  {syncType === 'marinatek' &&
                                    <div className="custom-checkbox flex d-flex mb-3 mt-3 mr-1">
                                      <label className="custom-check">
                                        <input type="checkbox"
                                          checked={selectedActive}
                                          onChange = {(e) => this.selectActive(e)}
                                        />
                                        <span className= "checkmark green top-0"></span>
                                      </label>
                                      <div className='ml-2'>Active</div>
                                    </div>
                                  }
                                  {syncType === 'mbo' &&
                                    <div  className = "flex flex-wrap gap-3 my-3">
                                      <div className="custom-checkbox flex items-center">
                                        <label className="custom-check mr-1">
                                          <input type="checkbox"
                                            checked={mboStaffFilter}
                                            onChange = {(e) => this.mboSelectFilters(e, "staff")}
                                          />
                                          <span className= "checkmark top-0"></span>
                                        </label>
                                        <div className="text-sm">Non-Staff Members</div>
                                      </div>
                                      <div className="custom-checkbox flex items-center ml-50">
                                        <label className="custom-check mr-1">
                                          <input type="checkbox"
                                            checked={mboEmailFilter}
                                            onChange = {(e) => this.mboSelectFilters(e, "email")}
                                          />
                                          <span className= "checkmark top-0"></span>
                                        </label>
                                        <div className="text-sm">Email Present</div>
                                      </div>
                                    </div>
                                  }
                                  <div className="flex-grow overflow-auto max-h-64 md:max-h-96 ">
                                    <table className="relative w-full border">
                                      <thead className='bg-white'>
                                        <tr className='bg-white'>
                                          <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white">
                                            <div>
                                              {allSelected &&
                                                <img className='w-5' onClick={(e) => this.checkAll(e, false)} src="/assets/icon_select_all.png" />
                                              }
                                              {!allSelected &&
                                                <img className='w-5' onClick={(e) => this.checkAll(e, true)} src="/assets/icon_select_blank.png" />
                                              }
                                            </div>
                                          </th>
                                          {syncType === 'mbo' &&
                                            <React.Fragment>
                                              <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">MBO ID</th>
                                              <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">MBO Site ID</th>
                                            </React.Fragment>
                                          }
                                          {syncType === 'marinatek' &&
                                            <React.Fragment>
                                              <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">Marianatek ID</th>
                                              <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">Marianatek Profile ID</th>
                                            </React.Fragment>
                                          }
                                          <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">First Name</th>
                                          <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white whitespace-nowrap">Last Name</th>
                                          <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white">Email</th>
                                          {syncType === 'marinatek' &&
                                            <th className="sticky -top-1 px-2 py-3 font-semibold text-sm bg-white">Active</th>
                                          }
                                        </tr>
                                      </thead>
                                      <tbody className="divide-y">
                                        {remote_classes.map((cls, i) =>
                                          <tr key={i}>
                                            <td className="px-2 py-4 text-center text-sm">
                                              <div>
                                                <label>
                                                  <input type="checkbox"
                                                    checked={this.objectChecked(cls)}
                                                    onChange = {(e) => this.toggleRequestClass(e, cls)}
                                                  />
                                                </label>
                                              </div>
                                            </td>
                                            {syncType === 'mbo' &&
                                              <React.Fragment>
                                                <td className="px-2 py-4 text-sm">{cls.mindbody_id}</td>
                                                <td className="px-2 py-4 text-sm">{this.state.selectedSiteId}</td>
                                              </React.Fragment>
                                            }
                                            {syncType === 'marinatek' &&
                                              <React.Fragment>
                                                <td className="px-2 py-4 text-sm">{cls.employee_id}</td>
                                                <td className="px-2 py-4 text-sm">{cls.marianatek_profile_id}</td>
                                              </React.Fragment>
                                            }
                                            <td className="px-2 py-4 text-sm">{cls.first_name}</td>
                                            <td className="px-2 py-4 text-sm">{cls.last_name}</td>
                                            <td className="px-2 py-4 text-sm">{cls.email}</td>
                                            {syncType === 'marinatek' &&
                                              <td className="px-2 py-4 text-sm">{cls.active ? 'true' : 'false'}</td>
                                            }
                                          </tr>
                                        )}
                                      </tbody>

                                    </table>
                                  </div>
                                  <div className="custom-pagination text-center mt-3">
                                    {(this.state.syncTotal > 200 && syncType === 'mbo') &&
                                      <Pagination
                                        activePage={this.state.syncActivePage}
                                        itemsCountPerPage={200}
                                        totalItemsCount={this.state.syncTotal}
                                        pageRangeDisplayed={9}
                                        onChange={this.handleSyncPageChange.bind(this)}
                                        itemClassPrev='previous'
                                        itemClassNext='next'
                                        itemClassFirst='first'
                                        itemClassLast='last'
                                        hideDisabled={true}
                                      />
                                    }
                                  </div>
                                </React.Fragment>
                              }
                            </div>
                          }
                        </div>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 flex flex-row-reverse gap-3">
                    {remote_classes.length > 0 &&
                      <React.Fragment>
                        {(syncType === 'mbo' && user?.role_display === "Netgym Employee") &&
                          <button
                            type="button"
                            className="cursor-pointer inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={(e) => this.processDownload(e)}
                          >
                            Download
                          </button>
                        }
                        <button
                          type="button"
                          className="cursor-pointer inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={(e) => this.processSync(e)}
                        >
                          {syncing &&
                            <React.Fragment>
                              <div className="flex items-center justify-center">
                                <svg className="animate-spin -ml-1 mr-1.5 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                <p className="text-center">{window.getSaveSyncSlogan()}</p>
                              </div>
                            </React.Fragment>
                          }
                          {!syncing &&
                            <React.Fragment>
                              Sync Data
                            </React.Fragment>
                          }
                        </button>
                      </React.Fragment>
                    }
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
        <Transition.Root show={showUpload} as={Fragment}>
					<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6 min-h-96">
									<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											onClick={(e) => this.closeModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										<div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
											<Dialog.Title as="h4" className="text-lg leading-6 font-medium text-gray-900 border-b">
                        <div className='flex'>
                          <p className='text-sm py-3.5'>Users Preview</p>
                        </div>
											</Dialog.Title>
											<div className="mt-5">
                        { uploadNewUsers?.length > 0 &&
                          <div>
                            <p className="text-center">New Users</p>
                            <div className="flex flex-col mt-3">
                              <div className="flex-grow overflow-auto">
                                <table className="relative w-full border">
                                  <thead>
                                    <tr>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        First Name
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Last Name
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Employee Id
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Email
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Role
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Home Location
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Skills
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Phone
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Home Club
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        City
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        State
                                      </th>
                                      {business.mbo_integration &&
                                        <React.Fragment>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Mindbody ID</th>
                                        </React.Fragment>
                                      }
                                      {business.marianatek_integration &&
                                        <React.Fragment>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Marianatek ID</th>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Marianatek Profile ID</th>
                                        </React.Fragment>
                                      }
                                      {business.abc_integration &&
                                        <React.Fragment>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">ABC ID</th>
                                        </React.Fragment>
                                      }
                                      {business.clubready_integration &&
                                        <React.Fragment>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Clubready ID</th>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Club ID</th>
                                        </React.Fragment>
                                      }
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y">
                                    {uploadNewUsers.map((cls, i) =>
                                      <tr key={i}>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.first_name}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.last_name}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.employee_id}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.email}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.role}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.location}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.skills}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.phone}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.home_club}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.City}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.State}
                                        </td>
                                        {business.mbo_integration &&
                                          <React.Fragment>
                                            <td className="px-2 py-4 text-center text-sm">{cls.mindbody_id}</td>
                                          </React.Fragment>
                                        }
                                        {business.marianatek_integration &&
                                          <React.Fragment>
                                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                                            <td className="px-2 py-4 text-center text-sm">{cls.marianatek_profile_id}</td>
                                          </React.Fragment>
                                        }
                                        {business.abc_integration &&
                                          <React.Fragment>
                                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                                          </React.Fragment>
                                        }
                                        {business.clubready_integration &&
                                          <React.Fragment>
                                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                                          </React.Fragment>
                                        }
                                      </tr>
                                    )}
                                  </tbody>

                                </table>
                              </div>
                            </div>
                          </div>
                        }
                        <hr className="mt-5 mb-3" />

                        {uploadOldUsers?.length > 0 &&
                          <div>
                            <p className="text-center">These Users are already existing</p>
                            <div className="flex flex-col mt-3">
                              <div className="flex-grow overflow-auto">
                                <table className="relative w-full border">
                                  <thead>
                                    <tr>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        First Name
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Last Name
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Employee Id
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Email
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Role
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Home Location
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Skills
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Phone
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        Home Club
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        City
                                      </th>
                                      <th className="sticky top-0 px-2 py-3 font-semibold text-sm">
                                        State
                                      </th>
                                      {business.mbo_integration &&
                                        <React.Fragment>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Mindbody ID</th>
                                        </React.Fragment>
                                      }
                                      {business.marianatek_integration &&
                                        <React.Fragment>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Marianatek ID</th>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Marianatek Profile ID</th>
                                        </React.Fragment>
                                      }
                                      {business.abc_integration &&
                                        <React.Fragment>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">ABC ID</th>
                                        </React.Fragment>
                                      }
                                      {business.clubready_integration &&
                                        <React.Fragment>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Clubready ID</th>
                                          <th className="sticky top-0 px-2 py-3 font-semibold text-sm">Club ID</th>
                                        </React.Fragment>
                                      }
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y">
                                    {uploadOldUsers.map((cls, i) =>
                                      <tr key={i}>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.first_name}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.last_name}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.employee_id}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.email}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.role}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.location}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.skills}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.phone}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.home_club}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.City}
                                        </td>
                                        <td className="px-2 py-4 text-center text-sm">
                                          {cls.State}
                                        </td>
                                        {business.mbo_integration &&
                                          <React.Fragment>
                                            <td className="px-2 py-4 text-center text-sm">{cls.mindbody_id}</td>
                                          </React.Fragment>
                                        }
                                        {business.marianatek_integration &&
                                          <React.Fragment>
                                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                                            <td className="px-2 py-4 text-center text-sm">{cls.marianatek_profile_id}</td>
                                          </React.Fragment>
                                        }
                                        {business.abc_integration &&
                                          <React.Fragment>
                                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                                          </React.Fragment>
                                        }
                                        {business.clubready_integration &&
                                          <React.Fragment>
                                            <td className="px-2 py-4 text-center text-sm">{cls.external_id}</td>
                                          </React.Fragment>
                                        }
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        }
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-blue text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dark-blue sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={(e) => this.saveUpload(e)}
                    >
                      Save
                    </button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
      </React.Fragment>
    )
  }
}

import React, { Fragment, useState } from 'react';
import CustomPopup from '../../../assets/customPopup/customPopup';
import ActivationEmailComponent from '../../activationEmailComponent/activationEmailComponent';
import { getSudomain } from '../../../../../../helpers'
import { sendConfirmationAll } from './../../../helpers'
import Cookies from 'js-cookie';
import { use } from 'react';

export default function SendActivationEmailComponent(props){
  const { selectedPeople, showAlert, reloadDataAfterSendActivationEmail, setTheState, usersIds } = props
  const [ showConfirmationPopup, setShowConfirmationPopup ] = useState(false)
  const [ disabledSendAllBtn, setDisabledSendAllBtn ] = useState(false)

  const checkButtonDisabled = () => {
    return (selectedPeople?.length > 0 && selectedPeople.every(elem => usersIds.includes(elem)))
  }

  const handleSendActivationEmail = (e) => {
    setShowConfirmationPopup(true)
  }

  const closeConfirmationPopup = (data) => {
    setShowConfirmationPopup(data)
  }

  const processSendActivationEmail = (e) => {
    e.preventDefault()
    setDisabledSendAllBtn(true)

    sendConfirmationAll(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), selectedPeople?.length, selectedPeople).then(result => {
      if(result.status === 200){
        showAlert(true, 'success', result.success)
        setShowConfirmationPopup(false)
        setTheState('selectedPeopleForMassActions', [])
        reloadDataAfterSendActivationEmail()
      }else{
        showAlert(true, 'danger', result.error)
        setDisabledSendAllBtn(false)
      }
      window.setTimeout(()=>{
        showAlert(false, '', '')
      },3000)
    })
  }

  return(
    <Fragment>
      {showConfirmationPopup &&
        <CustomPopup show={showConfirmationPopup} close={closeConfirmationPopup} title={'Users Activation'}>
          <ActivationEmailComponent selectedPeople={selectedPeople} disabledSendAllBtn={disabledSendAllBtn} closeConfirmationPopup={closeConfirmationPopup} processSendActivationEmail={processSendActivationEmail}/>
        </CustomPopup>
      }
      <div>
        <button className={`bg-dark-blue text-white py-1.5 px-4 rounded-full gap-2 text-sm flex items-center border border-white ${!checkButtonDisabled() ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-800'}`} onClick={(e) => handleSendActivationEmail(e)} disabled={!checkButtonDisabled()}>{`Send Activation Email${(selectedPeople?.length > 0) ? ' to '+selectedPeople?.length+(selectedPeople?.length == 1 ? ' User' : ' Users') : ''}`}</button>
      </div>
    </Fragment>
  )
}
import React, { Fragment } from "react";
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

export default function YearViewComponent({currentYear, selectedYear, setSelectedYear}) {
  const possibleYears = [parseInt(currentYear)-1, parseInt(currentYear), parseInt(currentYear)+1]
  return(
    <Fragment>
      <div className="relative filters_box w-full lg:w-36">
        <Menu as="div" className="relative block text-left">
          <div>
            <Menu.Button className="h-10 flex justify-center w-full rounded border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              Year View
              <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5 mt-0.5" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="max-h-60 overflow-y-auto">
                <div className="py-1">
                  {possibleYears.map((year, i) => 
                    <div key={i} className={`${selectedYear == year ? 'bg-gray-200' : ''} hover:bg-gray-100`}>
                      <Menu.Item>
                        {({ active }) => (
                          <a className={`p-2 flex items-center text-gray-900 text-sm cursor-pointer`} onClick={(e) => setSelectedYear(year)}>
                            {year}
                          </a> 
                        )}
                      </Menu.Item>
                    </div>
                  )}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </Fragment>
  )
}
import React, { Fragment, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { AngleIcon } from "../../../../subRequests/assets";

export default function RequestDetailsComponent(props){
  const { eventData, handleShowInstructorDetails } = props
  const [ openAccordionKey, setOpenAccordionKey ] = useState('0')

  return(
    <Fragment>
      <Accordion className="bg-white" defaultActiveKey={'0'}>
        <Card className="border-0">
          <Accordion.Toggle
            eventKey="0"
            className="bg-white flex items-center gap-2 mt-2 w-full"
            onClick={() => setOpenAccordionKey(openAccordionKey === '0' ? '' : '0')}
          >
            <div className="text-base font-semibold">
              {"Request Details"}
            </div>
            <div>
              <AngleIcon classNames={`w-4 h-4 transform ${openAccordionKey == '0' ? 'rotate-180' : ''}`} />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="p-0">
              <div className="mt-2">
                <div className="flex items-center gap-2 mb-2">
                  <div className="text-sm font-semibold">
                    Created On:
                  </div>
                  <div className="text-sm font-medium text-neutral-600">
                    {eventData?.created}
                  </div>
                </div>
                <div className="flex items-center gap-2 mb-2">
                  <div className="text-sm font-semibold">
                    Created By:
                  </div>
                  <button
                    className="text-sm font-medium custom-blue-200"
                    onClick={() =>
                      handleShowInstructorDetails(eventData?.user)
                    }
                  >
                    {eventData?.user?.full_name}
                  </button>
                </div>
                {eventData?.reopened && Object.keys(eventData?.reopened_by).length > 0 && eventData?.reopened_at !== '' &&
                  <Fragment>
                    <div className="flex items-center gap-2 mb-2">
                      <div className="text-sm font-semibold">
                        Reopened On:
                      </div>
                      <div className="text-sm font-medium text-neutral-600">
                        {eventData?.reopened_at}
                      </div>
                    </div>
                    <div className="flex items-center gap-2 mb-2">
                      <div className="text-sm font-semibold">
                        Reopened By:
                      </div>
                      <button
                        className="text-sm font-medium custom-blue-200"
                        onClick={() =>
                          handleShowInstructorDetails(eventData?.reopened_by)
                        }
                      >
                        {eventData?.reopened_by?.full_name}
                      </button>
                    </div>
                  </Fragment>
                }
                {eventData?.cancelled && eventData?.cancelled_by && eventData?.cancelled_by !== '' &&
                  <Fragment>
                    <div className="flex items-center gap-2 mb-2">
                      <div className="text-sm font-semibold">
                        Cancelled By:
                      </div>
                      <div className="text-sm font-medium text-neutral-600">
                        {eventData?.cancelled_by}
                      </div>
                    </div>
                    {eventData?.cancel_reason && eventData?.cancel_reason !== '' &&
                      <div className="flex items-center gap-2 mb-2">
                        <div className="text-sm font-semibold">
                          Cancel Reason:
                        </div>
                        <div className="text-sm font-medium text-neutral-600">
                          {eventData?.cancel_reason}
                        </div>
                      </div>
                    }
                  </Fragment>
                }
                {((eventData?.status_content
                  .toLowerCase()
                  .includes("approved") || eventData?.status_content == 'Response Needed (Re-opened)') && eventData?.approved_by !== '') && (
                  <div className="flex items-center gap-2 mb-2">
                    <div className="text-sm font-semibold">
                      Approved By:
                    </div>
                    <div className="text-sm font-medium text-neutral-600">
                      {eventData?.approved_by}{" "}
                      {eventData?.approved_at
                        ? `, ${eventData?.approved_at}`
                        : ""}
                    </div>
                  </div>
                )}
                {eventData?.deleted && eventData?.deleted_by && eventData?.deleted_by !== '' &&
                  <Fragment>
                    <div className="flex items-center gap-2 mb-2">
                      <div className="text-sm font-semibold">
                        Deleted By:
                      </div>
                      <div className="text-sm font-medium text-neutral-600">
                        {eventData?.deleted_by}
                      </div>
                    </div>
                    {eventData?.delete_reason && eventData?.delete_reason !== '' &&
                      <div className="flex items-center gap-2 mb-2">
                        <div className="text-sm font-semibold">
                          Deleted Reason:
                        </div>
                        <div className="text-sm font-medium text-neutral-600">
                          {eventData?.delete_reason}
                        </div>
                      </div>
                    }
                  </Fragment>
                }
                {eventData?.show_reason !== '' &&
                  <div className="flex gap-2 mb-2">
                    <div className="text-sm font-semibold">Reason:</div>
                    <div className="text-sm font-medium text-neutral-600">
                      {eventData?.show_reason}
                    </div>
                  </div>
                }
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Fragment>
  )
}
import React, { Fragment, useState } from "react";
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import { formatTimestampToDate } from '../../helpers';
import { useAnalytics } from '../../../utils/analytics';
import { SendIcon } from '../../assets';
import LoadingIcon from '../../../subRequests/assets/loadingIcon';

export default function SubmitButton(props) {
  const { certification, handleShowAlert, handleRedirect, submitComplete, pageType } = props
  const { track } = useAnalytics();
  const [ submitBtnDisabled, setSubmitBtnDisabled ] = useState(false)

  const checkDisabledCondition = () => {
    return(pageType == 'view' || submitBtnDisabled || certification?.key_url == '' || certification?.name === '' || (certification?.category?.expiration_date_required && certification?.expiration_date == ''))
  }

  const handleSubmitCert = (e) => {
    e.preventDefault();
    let url = `/api/v3/documentations/update_status`
    let params = {
      id: certification?.id,
      name: certification?.name,
      expiration_date: certification?.expiration_date == '' ? '' : formatTimestampToDate(certification?.expiration_date),
    }
    setSubmitBtnDisabled(true)
    axiosRequest(url, 'POST', params, 'data').then((response) => {
      if(response.status === 200){
        handleShowAlert(true, response?.message, 'success')
        track('certification-submitted');
        setTimeout(() => {
          handleShowAlert(false, '', '')
          submitComplete('view', response?.certification)
          setSubmitBtnDisabled(false)
        }, 3000)
      }else{
        handleShowAlert(true, response?.error, 'danger')
        setSubmitBtnDisabled(false)
        setTimeout(() => {
          handleShowAlert(false, '', '')
        }, 3000)
      }
    })
    .catch((error) => {
      handleShowAlert(true, error, 'danger')
      setSubmitBtnDisabled(false)
      setTimeout(() => {
        handleShowAlert(false, '', '')
      }, 3000)
    })
  }

  return(
    <Fragment>
      <button className={`flex bg-dark-blue border border-gray-900 sm:text-base text-sm text-white inline-block px-5 py-2 border shadow rounded-lg relative gap-2 items-center ${(checkDisabledCondition()) ? 'cursor-not-allowed opacity-50' : 'hover:bg-gray-800'}`} onClick={(e) => handleSubmitCert(e)} disabled={checkDisabledCondition()}>
        {submitBtnDisabled &&
          <div className="absolute top-0 bottom-0 left-1.5 flex items-center justify-center">
            <LoadingIcon classNames={'animate-spin h-4 w-4 text-white'}/>
          </div>
        }
        <SendIcon classNames={'text-white h-4 w-4'}/>
        <div className='text-white text-base'>Submit</div>
      </button>
    </Fragment>
  )
}
import React, { Fragment } from "react";

export default function BreadCrumbs(props) {

  return(
    <Fragment>
      <div className="t-breadcrumbs mb-5">
        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center flex-wrap">
            <li>
              <div className="flex items-center">
                <a className="md:text-xl text-sm font-medium text-gray-500 hover:text-gray-700">Documentation</a>
              </div>
            </li>
            <li>
              <div className="flex items-start">
                <svg className="flex-shrink-0 h-5 w-5 text-gray-400 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
                <a className="md:text-xl text-sm font-medium text-gray-700 hover:text-gray-700 break-all" aria-current="page">{'Submit  Documentation'}</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
    </Fragment>
  )
}
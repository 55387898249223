import React, { Fragment } from "react";
import BreadCrumbs from './breadCrumbs';
import TopButtons from './topButtons';
import SubmitButton from './submitButton';
import DiscardButton from './discardButton';
import RenewButton from './renewButton';

export default function HeaderComponent(props) {
  const { loaded, pageType, certification, handleShowAlert, handleRedirect, handleDiscard, viewChange, submitComplete } = props
  return (
    <Fragment>
      <BreadCrumbs />
      <div className="flex justify-between mb-5 flex-wrap">
        <TopButtons name={'Back'}/>
        {loaded ?
          <div className="flex item-center gap-2 flex-wrap">
            {(!['view', 'add'].includes(pageType)) && <DiscardButton onClick={(e) => handleDiscard()}/>}
            {pageType === 'view' && <RenewButton status={certification?.certification_status} viewChange={viewChange}/>}
            {pageType === 'add' && <TopButtons name={'Discard Changes'}/>}
            <SubmitButton
              certification={certification}
              handleShowAlert={handleShowAlert}
              handleRedirect={handleRedirect}
              submitComplete={submitComplete}
              pageType={pageType}
            />
          </div>
          :
          <div></div>
        }
      </div>
    </Fragment>
  )
}
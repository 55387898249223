import React, { Fragment, useState } from "react";
import { ApproveButtonIcon, DeclineButtonIcon } from './../../../../assets'
import AutoApproveConfirmationComponent from './../../cardViewComponents/cardComponent/availableEventsButtonsComponent/autoApproveConfirmationComponent';
import ReactTooltip from "react-tooltip";
import { axiosRequest } from './../../../../helpers/helpers';

export default function AcceptDeclineButtonsComponent(props) {
  const { event, business, handleAlert, handleUpdateEvent, type, calendarEvent, handleUpdateCalendarEvent } = props
  const [ showAutoApproveModal, setShowAutoApproveModal ] = useState(false)
  const [ disableActionButtons, setDisableActionButtons ] = useState(false)

  const handlePerformAccept = (action) => {
    if (action == 'maybe') return
    if(action == 'accept' && event?.auto_approve_first_accepted){
      setShowAutoApproveModal(true)
    }else{
      handleProcessUpdate(action)
    }
  }

  const handleProcessUpdate = (action) => {
    let url = `/api/v2/sub_requests/update_status`
    let params = {
      status: action,
      event_id: event?.id,
      inst_id: '',
      gql_event_details: true,
      preview: true,
      calendar_event: calendarEvent,
      bucket_type: 'available'
    }
    setDisableActionButtons(true)
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(Object.keys(result).includes('status') && result?.status == 200){
        handleAlert(true, 'Status Updated', 'success');
      }else{
        handleAlert(true, result?.error, 'danger');
      }
      setDisableActionButtons(false)
      setTimeout(() => {
        handleAlert(false, '', ''); 
        handleUpdateEvent(result?.event);
        if(calendarEvent) handleUpdateCalendarEvent(result?.calendar_event, 'updated');
      }, 3000)
    })
  }

  return(
    <Fragment>
      {showAutoApproveModal &&
        <AutoApproveConfirmationComponent showAutoApproveModal={showAutoApproveModal} setShowAutoApproveModal={setShowAutoApproveModal} handleProcessUpdate={handleProcessUpdate} />
      }
      {type == 'list' &&
        <Fragment>
          <button className={`flex relative z-20 justify-center items-center border border-pending-event rounded-full min-h-8 min-w-8 ${event?.user_status == 'Accepted' ? 'text-white bg-pending-event' : 'custom-blue-200'} ${(disableActionButtons) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handlePerformAccept('accept')} data-tip data-for={`accept_button_`+event?.id.toString()} disabled={disableActionButtons || event?.user_status == 'Accepted'}>
            <ApproveButtonIcon classNames={`${event?.user_status == 'Accepted' ? 'text-white' : 'custom-blue-200'} wc-14 hc-14`}/>
            <ReactTooltip className='max-w-xs whitespace-nowrap' id={`accept_button_`+event?.id.toString()} place="top" effect="solid">
              {event?.user_status == 'Accepted' ? 'Accepted' : 'Accept'}
            </ReactTooltip>
          </button>
          <button className={`flex relative z-20 justify-center items-center border border-red-600 rounded-full min-h-8 min-w-8 ${event?.user_status == 'Declined' ? 'text-white bg-red-600' : 'text-red-600'} ${(disableActionButtons) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handlePerformAccept('decline')} data-tip data-for={`decline_button_`+event?.id.toString()} disabled={disableActionButtons || event?.user_status == 'Declined'}>
            <DeclineButtonIcon classNames={`${event?.user_status == 'Declined' ? 'text-white' : 'text-red-600'} wc-14 hc-14`}/>
            <ReactTooltip className='max-w-xs whitespace-nowrap' id={`decline_button_`+event?.id.toString()} place="top" effect="solid">
              {event?.user_status == 'Declined' ? 'Declined' : 'Decline'}
            </ReactTooltip>
          </button>
        </Fragment>
      }
      {type == 'preview' &&
        <Fragment>
          <button className={`flex items-center justify-center border border-pending-event rounded-2xl font-semibold w-full text-xs h-8 ${event?.user_status == 'Accepted' ? 'text-white bg-pending-event' : 'custom-blue-200'} ${(disableActionButtons) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handlePerformAccept('accept')} disabled={disableActionButtons || event?.user_status == 'Accepted'}>
            <ApproveButtonIcon classNames={`${event?.user_status == 'Accepted' ? 'text-white' : 'custom-blue-200'} w-5 h-5`}/>
            {event?.user_status == 'Accepted' ? 'Accepted' : 'Accept'}
          </button>
          <button className={`flex items-center justify-center border border-red-600 rounded-2xl font-semibold w-full text-xs h-8 ${event?.user_status == 'Declined' ? 'text-white bg-red-600' : 'text-red-600'} ${(disableActionButtons) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handlePerformAccept('decline')} disabled={disableActionButtons || event?.user_status == 'Declined'}>
            <DeclineButtonIcon classNames={`${event?.user_status == 'Declined' ? 'text-white' : 'text-red-600'} w-5 h-5`}/>
            {event?.user_status == 'Declined' ? 'Declined' : 'Decline'}
          </button>
        </Fragment>
      }
    </Fragment>
  )
}
import React, { Fragment } from "react";
import { Switch } from '@headlessui/react'

export default function SwitchCommonComponent({id, value, name, onChange, disabledValue}) {
  return (
    <Fragment>
      <div className='inline-flex items-center align-middle'>
        <Switch
          id={id}
          checked={value}
          name={name}
          onChange={(e) => onChange(e, name)}
          className={`inline ${value ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none ${disabledValue ? 'cursor-not-allowed opacity-50' : ''}`}
          disabled={disabledValue}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${value ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
          />
        </Switch>
        <label className={`inline ml-2 text-sm ${disabledValue ? 'text-gray-400' : ''}`} htmlFor={id}>
          {value ? 'Yes' : 'No'}
        </label>
      </div>
    </Fragment>
  )
}
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import { getSudomain, setTitle } from './../../../components/helpers'
import { getAllPermissions, setAllPermissions } from './helpers'
import TooltipComponent from '../account/tooltipComponent';

export default class PermissionsForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      show: false,
      alert_type: '',
      alert_message: '',
      loaded: false,
      permissions: {},
      role: '',    
      disablePage: !['Netgym Employee', 'Account Owner'].includes(this.props.user?.role_display) || false,
      disableAndLoading: false
    }
  }

  getName = (role) => {
    let name = ""
    switch(role){
      case 'account_owner':
        name = "account owners"
        break;
      case 'business_admin':
        name = "business admins"
        break;
      case 'regional_admin':
        name = 'regional admins'
        break;
      case 'fitness_manager':
        name = 'fitness managers'
        break;
      case 'instructor':
        name = 'instructors'
        break;
      case 'ops':
        name = 'ops'
        break;
    }
    return name
  }

  getHeading = (role) => {
    let name = ""
    switch(role){
      case 'account_owner':
        name = "Account Owner"
        break;
      case 'business_admin':
        name = "Business Admin"
        break;
      case 'regional_admin':
        name = 'Regional Admin'
        break;
      case 'fitness_manager':
        name = 'Fitness Manager'
        break;
      case 'instructor':
        name = 'Instructor'
        break;
      case 'ops':
        name = 'Ops'
        break;
    }
    return name
  }

  componentDidMount = () => {
    let url = window.location.href.split("/")
    let role = url.pop()
    if(this.props.pageLoaded){
      this.getPermissions(role)
    }
    setTitle('permissions')
	}

  getPermissions = (role) =>{
    getAllPermissions(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), role).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          permissions: result.permissions,
          loaded: true,
          role: role
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  handleChange = (e, entity, action_type) => {
    if(entity === "business_users"){
      if(action_type === "edit" && e.target.checked === true){ 
        this.setPermissions(e, entity, action_type)
        window.setTimeout(1000)
        this.setPermissions(e, entity, "read")
      }
      else if(action_type === "write" && e.target.checked === true){
        this.setPermissions(e, entity, "read")
        window.setTimeout(1000)
        this.setPermissions(e, entity, "edit")
        window.setTimeout(2000)
        this.setPermissions(e, entity, action_type)
        window.setTimeout(3000)
      }
      else{
        this.setPermissions(e, entity, action_type)
      }
    }
    else if(entity === "business_categories"){
      if(action_type === "write"){
        this.setPermissions(e, entity, "read")
        this.setPermissions(e, entity, action_type)
      }else if(action_type === "read"){
        this.setPermissions(e, entity, action_type)
      }
    }
    else if(entity === 'business_classes'){
      if(action_type === "write"){
        this.setPermissions(e, entity, "read")
        this.setPermissions(e, entity, action_type)
      }else if(action_type === "read"){
        this.setPermissions(e, entity, action_type)
      }
    }
    else if(entity === 'business_regions'){
      if(action_type === "write"){
        this.setPermissions(e, entity, "read")
        this.setPermissions(e, entity, action_type)
      }else if(action_type === "read"){
        this.setPermissions(e, entity, action_type)
      }
    }
    else if(entity === 'business_locations'){
      if(action_type === "write"){
        this.setPermissions(e, entity, "read")
        this.setPermissions(e, entity, action_type)
      }else if(action_type === "read"){
        this.setPermissions(e, entity, action_type)
      }
    }
    else if(entity === "documentations"){
      if(action_type === "write"){
        this.setPermissions(e, entity, "read")
        this.setPermissions(e, entity, action_type)
      }else if(action_type === "read"){
        this.setPermissions(e, entity, action_type)
      }
    }
    else{
      this.setPermissions(e, entity, action_type)
    }
  }

  setPermissions = (e, entity, action_type) =>{
    this.setState({disablePage: true, disableAndLoading: true})
    setAllPermissions(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.role, entity, action_type, e.target.checked ).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          permissions: result.permissions,
          alert_message: result.success,
          show: true,
          alert_type: 'success',
          disablePage: false,
          disableAndLoading: false
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          disablePage: false,
          disableAndLoading: false
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })
  }

  render() {
    const { permissions, show, alert_message, alert_type, loaded, role, disablePage, disableAndLoading } = this.state
    const { business, loadingMessage } = this.props
    
    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        <div className='w-full min-h-screen bg-gray-50'>
          <div className='h-full'>
            {!loaded && 
              <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                <p className="mb-1 text-center px-5">{loadingMessage}</p>
                <img className = "w-9" src="/assets/business/loading.gif" />
              </div>
            }
            {loaded &&
              <div className='max-w-8xl mx-auto h-full'>
                <div className='flex px-2'> 
                  <div>
                    <Link to={`/settings-v4/permissions`} className='border rounded-md border-gray-300 hover:bg-gray-100 shadow-sm text-gray-700 text-sm py-2 px-4 relative h-9 block cursor-pointer'>
                      <div className='flex gap-2 items-center text-center justify-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                        </svg>
                        Back
                      </div>
                    </Link>
                  </div>
                </div>
                <div className='mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4'>
                  <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
                    <div className='bg-white shadow border p-5 overflow-hidden border-b border-gray-200 sm:rounded-lg relative'>
                      {disableAndLoading && 
                        <div className="fixed msg-loading flex flex-col items-center w-full justify-center h-screen left-0 right-0 top-36">
                          <img className = "w-9" src="/assets/business/loading.gif" />
                        </div>
                      }
                      <div className='flex items-center gap-2 mb-5'>
                        <h3 className='text-lg font-medium'>{this.getHeading(role)} Default Permissions</h3>
                        <TooltipComponent componentId={'account-info-about-section-tooltip-message'} classNames={'w-4 h-4'} tooltipMessage={'This section can be edited by the Account Owner(s).'}/>
                      </div>
                      <div className='SubRequest-list mb-3'>
                        <span className='mb-1 block font-medium font-sm'>Sub Request</span>
                        <div className='relative flex items-start mb-1'>
                          <div className='flex items-center h-5'>
                            <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck0" defaultChecked={permissions.sub_request?.write} onClick={(e) => this.handleChange(e, 'sub_request', 'write')} disabled={role === "instructor" || disablePage}/>
                          </div>
                          <div className='ml-3 text-sm'>
                            <label htmlFor="exampleCheck0" className="font-medium text-gray-700">
                              Allow {this.getName(role)} to create, edit, and delete sub requests
                            </label>
                          </div>
                        </div>
                        {role !== "instructor" &&
                          <div className='relative flex items-start mb-1'>
                            <div className='flex items-center h-5'>
                              <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck1" defaultChecked={permissions.sub_request?.past_write} onClick={(e) => this.handleChange(e, 'sub_request', 'past_write')} disabled={disablePage}/>
                            </div>
                            <div className='ml-3 text-sm'>
                              <label htmlFor="exampleCheck1" className="font-medium text-gray-700">
                                Allow {this.getName(role)} to create, edit, and delete PAST sub requests
                              </label>
                            </div>
                          </div>
                        }
                        <div className='relative flex items-start mb-1'>
                          <div className='flex items-center h-5'>
                            <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck2" checked={permissions.sub_request?.read} onChange={(e) => this.handleChange(e, 'sub_request', 'read')} disabled={role === "instructor" || disablePage}/>
                          </div>
                          <div className='ml-3 text-sm'>
                            <label htmlFor="exampleCheck2" className="font-medium text-gray-700">
                              Allow {this.getName(role)} to view sub requests
                            </label>
                          </div>
                        </div>
                        <div className='relative flex items-start mb-1'>
                          <div className='flex items-center h-5'>
                            <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck3" defaultChecked={permissions.sub_request?.accept} onClick={(e) => this.handleChange(e, 'sub_request', 'accept')} disabled={role === "instructor" || disablePage}/>
                          </div>
                          <div className='ml-3 text-sm'>
                            <label htmlFor="exampleCheck3" className="font-medium text-gray-700">
                              Allow {this.getName(role)} to accept sub requests
                            </label>
                          </div>
                        </div>
                        {role !== "instructor" &&
                          <div className='relative flex items-start mb-1'>
                            <div className='flex items-center h-5'>
                              <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck4" defaultChecked={permissions.sub_request?.confirm} onClick={(e) => this.handleChange(e, 'sub_request', 'confirm')} disabled={disablePage}/>
                            </div>
                            <div className='ml-3 text-sm'>
                              <label htmlFor="exampleCheck4" className="font-medium text-gray-700">
                                Allow {this.getName(role)} to approve sub requests
                              </label>
                            </div>
                          </div>
                        }
                      </div>
                      {role !== "instructor" &&
                        <React.Fragment>
                          <div className='Users-list mb-3'>
                            <span className='mb-1 block font-medium font-sm'>User</span>
                            <div className='relative flex items-start mb-1'>
                              <div className='flex items-center h-5'>
                                <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck5" checked={permissions.business_users?.write} onChange={(e) => this.handleChange(e, 'business_users', 'write')}  disabled={disablePage}/>
                              </div>
                              <div className='ml-3 text-sm'>
                                <label htmlFor="exampleCheck5" className="font-medium text-gray-700">
                                  Allow {this.getName(role)} to create and delete users
                                </label>
                              </div>
                            </div>
                            <div className='relative flex items-start mb-1'>
                              <div className='flex items-center h-5'>
                                <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck6" checked={permissions.business_users?.edit} onChange={(e) => this.handleChange(e, 'business_users', 'edit')} disabled={permissions.business_users?.write || disablePage}/>
                              </div>
                              <div className='ml-3 text-sm'>
                                <label htmlFor="exampleCheck6" className="font-medium text-gray-700">
                                  Allow {this.getName(role)} to edit users
                                </label>
                              </div>
                            </div>
                            <div className='relative flex items-start mb-1'>
                              <div className='flex items-center h-5'>
                                <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck7" checked={permissions.business_users?.read} onChange={(e) => this.handleChange(e, 'business_users', 'read')} disabled={permissions.business_users?.edit || disablePage}/>
                              </div>
                              <div className='ml-3 text-sm'>
                                <label htmlFor="exampleCheck7" className="font-medium text-gray-700">
                                  Allow {this.getName(role)} to view users
                                </label>
                              </div>
                            </div>
                            <div className='relative flex items-start mb-1'>
                              <div className='flex items-center h-5'>
                                <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck8" checked={permissions.business_users?.update_password} onChange={(e) => this.handleChange(e, 'business_users', 'update_password')} disabled={disablePage}/>
                              </div>
                              <div className='ml-3 text-sm'>
                                <label htmlFor="exampleCheck8" className="font-medium text-gray-700">
                                  Allow {this.getName(role)} to change user password
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className='Skills-list mb-3'>
                            <span className='mb-1 block font-medium font-sm'>Skills</span>
                            <div className='relative flex items-start mb-1'>
                              <div className='flex items-center h-5'>
                                <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck9" checked={permissions.business_categories?.write} onChange={(e) => this.handleChange(e, 'business_categories', 'write')} disabled={disablePage}/>
                              </div>
                              <div className='ml-3 text-sm'>
                                <label htmlFor="exampleCheck9" className="font-medium text-gray-700">
                                  Allow {this.getName(role)} to create, edit, and delete skills
                                </label>
                              </div>
                            </div>
                            <div className='relative flex items-start mb-1'>
                              <div className='flex items-center h-5'>
                                <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck10" checked={permissions.business_categories?.read} onChange={(e) => this.handleChange(e, 'business_categories', 'read')} disabled={disablePage}/>
                              </div>
                              <div className='ml-3 text-sm'>
                                <label htmlFor="exampleCheck10" className="font-medium text-gray-700">
                                  Allow {this.getName(role)} to view skills
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className='Classes-list mb-3'>
                            <span className='mb-1 block font-medium font-sm'>Classes</span>
                            <div className='relative flex items-start mb-1'>
                              <div className='flex items-center h-5'>
                                <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck11" checked={permissions.business_classes?.write} onChange={(e) => this.handleChange(e, 'business_classes', 'write')} disabled={disablePage}/>
                              </div>
                              <div className='ml-3 text-sm'>
                                <label htmlFor="exampleCheck11" className="font-medium text-gray-700">
                                  Allow {this.getName(role)} to create, edit, and delete classes
                                </label>
                              </div>
                            </div>
                            <div className='relative flex items-start mb-1'>
                              <div className='flex items-center h-5'>
                                <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck12" checked={permissions.business_classes?.read} onChange={(e) => this.handleChange(e, 'business_classes', 'read')} disabled={disablePage}/>
                              </div>
                              <div className='ml-3 text-sm'>
                                <label htmlFor="exampleCheck12" className="font-medium text-gray-700">
                                  Allow {this.getName(role)} to view classes
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className='Regions-list mb-3'>
                            <span className='mb-1 block font-medium font-sm'>Regions</span>
                            <div className='relative flex items-start mb-1'>
                              <div className='flex items-center h-5'>
                                <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck13" checked={permissions.business_regions?.write} onChange={(e) => this.handleChange(e, 'business_regions', 'write')} disabled={disablePage}/>
                              </div>
                              <div className='ml-3 text-sm'>
                                <label htmlFor="exampleCheck13" className="font-medium text-gray-700">
                                  Allow {this.getName(role)} to create, edit, and delete regions
                                </label>
                              </div>
                            </div>
                            <div className='relative flex items-start mb-1'>
                              <div className='flex items-center h-5'>
                                <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck14" checked={permissions.business_regions?.read} onChange={(e) => this.handleChange(e, 'business_regions', 'read')} disabled={disablePage}/>
                              </div>
                              <div className='ml-3 text-sm'>
                                <label htmlFor="exampleCheck14" className="font-medium text-gray-700">
                                  Allow {this.getName(role)} to view regions
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className='Locations-list mb-3'>
                            <span className='mb-1 block font-medium font-sm'>Locations</span>
                            <div className='relative flex items-start mb-1'>
                              <div className='flex items-center h-5'>
                                <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck15" checked={permissions.business_locations?.write} onChange={(e) => this.handleChange(e, 'business_locations', 'write')} disabled={disablePage}/>
                              </div>
                              <div className='ml-3 text-sm'>
                                <label htmlFor="exampleCheck15" className="font-medium text-gray-700">
                                Allow {this.getName(role)} to create, edit, and delete locations
                                </label>
                              </div>
                            </div>
                            <div className='relative flex items-start mb-1'>
                              <div className='flex items-center h-5'>
                                <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck16" checked={permissions.business_locations?.read} onChange={(e) => this.handleChange(e, 'business_locations', 'read')} disabled={disablePage}/>
                              </div>
                              <div className='ml-3 text-sm'>
                                <label htmlFor="exampleCheck16" className="font-medium text-gray-700">
                                  Allow {this.getName(role)} to view locations
                                </label>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      }
                      {business?.new_messenger &&
                        <div className='Messenger-list mb-3'>
                          <span className='mb-1 block font-medium font-sm'>Messenger</span>
                          {role !== "instructor" &&
                            <React.Fragment>
                              <div className='relative flex items-start mb-1'>
                                <div className='flex items-center h-5'>
                                  <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck17-1" defaultChecked={permissions.business_messages?.write} onClick={(e) => this.handleChange(e, 'business_messages', 'write')} disabled={disablePage}/>
                                </div>
                                <div className='ml-3 text-sm'>
                                  <label htmlFor="exampleCheck17-1" className="font-medium text-gray-700">
                                    Allow {this.getName(role)} to create Posts
                                  </label>
                                </div>
                              </div>
                              <div className='relative flex items-start mb-1'>
                                <div className='flex items-center h-5'>
                                  <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck17-2" defaultChecked={permissions.business_messages?.groups} onClick={(e) => this.handleChange(e, 'business_messages', 'groups')} disabled={disablePage}/>
                                </div>
                                <div className='ml-3 text-sm'>
                                  <label htmlFor="exampleCheck17-2" className="font-medium text-gray-700">
                                    Allow {this.getName(role)} to create groups
                                  </label>
                                </div>
                              </div>
                              <div className='relative flex items-start mb-1'>
                                <div className='flex items-center h-5'>
                                  <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck17-3" defaultChecked={permissions.business_messages?.direct_messages} onClick={(e) => this.handleChange(e, 'business_messages', 'direct_messages')} disabled={disablePage}/>
                                </div>
                                <div className='ml-3 text-sm'>
                                  <label htmlFor="exampleCheck17-3" className="font-medium text-gray-700">
                                    Allow {this.getName(role)} to create direct messages
                                  </label>
                                </div>
                              </div>
                            </React.Fragment>
                          }
                          {role === "instructor" &&
                            <React.Fragment>
                              <div className='relative flex items-start mb-1'>
                                <div className='flex items-center h-5'>
                                  <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck17-11" defaultChecked={permissions.business_messages?.direct_messages_to_managers} onClick={(e) => this.handleChange(e, 'business_messages', 'direct_messages_to_managers')} disabled={disablePage}/>
                                </div>
                                <div className='ml-3 text-sm'>
                                  <label htmlFor="exampleCheck17-11" className="font-medium text-gray-700">
                                    Allow {this.getName(role)} to send direct messages to Business Admins, Regional Admins, and Fitness Managers
                                  </label>
                                </div>
                              </div>
                              <div className='relative flex items-start mb-1'>
                                <div className='flex items-center h-5'>
                                  <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck17-12" defaultChecked={permissions.business_messages?.direct_messages_to_instructors} onClick={(e) => this.handleChange(e, 'business_messages', 'direct_messages_to_instructors')} disabled={disablePage}/>
                                </div>
                                <div className='ml-3 text-sm'>
                                  <label htmlFor="exampleCheck17-12" className="font-medium text-gray-700">
                                    Allow {this.getName(role)} to send direct messages to other instructors
                                  </label>
                                </div>
                              </div>
                            </React.Fragment>
                          }
                        </div>
                      }
                      {(business?.resource_hub && role !== "instructor" ) &&
                        <div className='ResourceHub-list mb-3'>
                          <span className='mb-1 block font-medium font-sm'>Resource hub</span>
                          <div className='relative flex items-start mb-1'>
                            <div className='flex items-center h-5'>
                              <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="exampleCheck18" defaultChecked={permissions.business_resources?.write} onClick={(e) => this.handleChange(e, 'business_resources', 'write')} disabled={disablePage}/>
                            </div>
                            <div className='ml-3 text-sm'>
                              <label htmlFor="exampleCheck18" className="font-medium text-gray-700">
                                Allow {this.getName(role)} to create, edit, and delete resources
                              </label>
                            </div>
                          </div>
                        </div>
                      }
                      {(business?.documentations_feature && role !== "instructor" ) &&
                        <div className='Certifications-list mb-3'>
                          <span className='mb-1 block font-medium font-sm'>Documentation</span>
                          <div className='relative flex items-start mb-1'>
                            <div className='flex items-center h-5'>
                              <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="certifications-1" checked={permissions?.documentations?.write} onChange={(e) => this.handleChange(e, 'documentations', 'write')} disabled={disablePage}/>
                            </div>
                            <div className='ml-3 text-sm'>
                              <label htmlFor="exampleCheck9" className="font-medium text-gray-700">
                                Allow {this.getName(role)} to create, edit, and delete Documentation
                              </label>
                            </div>
                          </div>
                          <div className='relative flex items-start mb-1'>
                            <div className='flex items-center h-5'>
                              <input type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" id="certifications-2" checked={permissions?.documentations?.read} onChange={(e) => this.handleChange(e, 'documentations', 'read')} disabled={permissions?.documentations?.write || disablePage}/>
                            </div>
                            <div className='ml-3 text-sm'>
                              <label htmlFor="exampleCheck10" className="font-medium text-gray-700">
                                Allow {this.getName(role)} to view Documentation
                              </label>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

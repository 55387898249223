import React, { Fragment, useState, useEffect } from "react";
import { useParams, Redirect } from 'react-router-dom';
import DropZone from './dropZone';
import CertificationCard from './certificationCard'
import AlertPopup from '../../../messenger/pages/chatComponents/assets/alertPopup';
import { LoadingSpinner } from '../../helpers';
import { axiosGetRequest } from '../../../subRequests/helpers/helpers';
import HistoryComponent from './historyComponent';
import HeaderComponent from './headerComponent';

export default function SubmitCertification(props) {
  const { path, parentLoaded } = props
  const { id } = useParams();
  const [ pageType, setPageType ] = useState('view')
  const [ certification, setCertification ] = useState({
    id: id,
    name: '',
    expiration_date: '',
    business_user_id: '',
    documentation_category_id: '',
    key_url: '',
    category: {name: '', expiration_date_required: false},
    status: '',
    submitted_at: '',
    submitted_by: '',
    certification_status: '',
    expiring_soon: false
  })
  const [ certificationCopy, setCertificationCopy ] = useState(certification)
  const [ loaded, setLoaded ] = useState(false)
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')
  const [ redirectUrl, setRedirectUrl ] = useState('')
  const [ redirect, setRedirect ] = useState(false)
  const [ formEditable, setFormEditable ] = useState(false)
  const [ firstTime, setFirstTime ] = useState(true)

  useEffect(() => {
    if(parentLoaded && firstTime){
      setFirstTime(false)
      getPageType()
    }
  }, [parentLoaded]);

  const getPageType = () => {
    let params = new URLSearchParams(window.location.search)
    let type = (params.has('page-type') && params.get('page-type') !== '') ? params.get('page-type') : ''
    if(type === '' || ![ 'renew', 'edit', 'view', 'add' ].includes(type)){
      let pathName = window.location.pathname.replace(/^\/+/, "")
      window.history.replaceState(`path:${pathName}?page-type=view`, '', `/${pathName}?page-type=view`)
      setPageType('view')
      setFormEditable(false)
      fetchCertDetails('view')
    }else{
      setPageType(type)
      setFormEditable(['renew', 'edit', 'add'].includes(type))
      fetchCertDetails(type)
    }
  }

  const fetchCertDetails = (type) => {
    let url = `/api/v3/documentations/${id}`
    axiosGetRequest(url).then(result => {
      if(result.status === 200){
        let expiration_date = result?.results?.expiration_date
        setCertification({
          ...result?.results,
          key_url: (type == 'renew' ? '' : result?.results?.key_url),
          expiration_date: ((expiration_date && expiration_date !== '') ? new Date(expiration_date) : '')
        })
        setCertificationCopy({
          ...result?.results,
          expiration_date: ((expiration_date && expiration_date !== '') ? new Date(expiration_date) : '')
        })
        setLoaded(true)
      }else{
        setAlertMessage(result.error)
        setAlertType('danger')
        setShowAlert(true)
        setTimeout(()=>{
          setShowAlert(false)
          handleRedirect()
        },3000)
      }
    })
  }

  const handleShowAlert = (show, message, type) => {
    setAlertMessage(message)
    setAlertType(type)
    setShowAlert(show)
  }

  const handleRedirect = () => {
    setRedirectUrl(location?.pathname?.split('/submit-documentation')[0])
    setRedirect(true)
  }

  const handleDiscard = () => {
    viewChange('view')
    handlePageSwitch('view')
  }

  const viewChange = (type, reload=false) => {
    let pathName = window.location.pathname.replace(/^\/+/, "")
    window.history.replaceState(`path:${pathName}?page-type=${type}`, '', `/${pathName}?page-type=${type}`)
    setPageType(type)
    setFormEditable((type !== 'view'))
    if(reload){
      handlePageSwitch(type)
    }
  }

  const handlePageSwitch = (type) => {
    setCertification({
      ...certificationCopy,
      key_url: (type == 'renew' ? '' : certificationCopy?.key_url)
    })
  }

  const submitComplete = (type, data) => {
    viewChange(type)
    updateCertification(type, data)
  }

  const updateCertification = (type, data) => {
    let expiration_date = data?.expiration_date
    let newData = {
      ...data,
      expiration_date: ((expiration_date && expiration_date !== '') ? new Date(expiration_date) : '')
    }
    setCertification({
      ...newData,
      key_url: (type == 'renew' ? '' : newData?.key_url)
    })
    setCertificationCopy(newData)
  }
  console.log('Certification', certification)
  console.log('CertificationCopy', certificationCopy)
  return(
    <Fragment>
      {redirect &&
        <Redirect to={redirectUrl}/>
      }
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={(data) => handleShowAlert(data, '', '')} />
      }
      <HeaderComponent
        loaded={loaded}
        pageType={pageType}
        certification={certification}
        handleShowAlert={handleShowAlert}
        handleRedirect={handleRedirect}
        handleDiscard={handleDiscard}
        viewChange={viewChange}
        submitComplete={submitComplete}
      />
      {parentLoaded &&
        <Fragment>
          {!loaded &&
            <LoadingSpinner />
          }
          {loaded &&
            <div className="flex lg:flex-row flex-col gap-5">
              <div className="w-full lg:w-3/5">
                <DropZone
                  certification={certification}
                  setCertification={setCertification}
                  handleShowAlert={handleShowAlert}
                  pageType={pageType}
                  setCertificationCopy={setCertificationCopy}
                />
              </div>
              <div className="w-full lg:w-2/5">
                <CertificationCard
                  certification={certification}
                  setCertification={setCertification}
                  handleShowAlert={handleShowAlert}
                  handleRedirect={handleRedirect}
                  formEditable={formEditable}
                  pageType={pageType}
                />
                <HistoryComponent />
              </div>
            </div>
          }
        </Fragment>
      }
      {!parentLoaded &&
        <LoadingSpinner />
      }
    </Fragment>
  )
}
import React, { Component, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { setTitle } from '../../helpers';
import CertificationHeader from '../components/header';
import InstructorCertifications from '../components/instructorCertifications';
import Categories from '../components/categories';
import CertificationNavbar from '../components/navbar';
import { LoadingSpinner, instructorCertificationFilterSaved, allStatuses } from '../helpers';
import SubmitCertification from '../components/submitCertification'

class CertificationsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      firstTime: true,
      redirect: false,
      redirectUrl: '',
      savedInstructors: [],
      savedStatus: allStatuses || [],
      savedCategories: [],
      checkStateFirstTime: true,
      selectedLocations: [],
      selectedRegions: []
    };
  }

  componentDidMount = () => {
    setTitle('documentation');
    this.props.setActive('Documentation');
    this.checkPermissions();
    this.getFiltersValues();
    if (this.props.pageLoaded && this.state.firstTime) {
      this.setState({ firstTime: false, loaded: true });
    }
  }

  checkPermissions = () => {
    const { user, permissions, path } = this.props;
    if(Object.keys(user).length !== 0 && !user?.documentations_feature){
      this.setState({ redirect: true, redirectUrl: '/my-requests' }); 
      return;
    }
    if(Object.keys(user).length !== 0 && Object.keys(permissions).length !== 0){
      if(user?.role_display !== 'Netgym Employee' && !['submit-documentation'].includes(path) && ((user?.role_display !== 'Fitness Instructor' && !permissions?.documentations?.read) || (user?.role_display == 'Fitness Instructor'))){
        this.setState({ redirect: true, redirectUrl: '/my-requests' }); 
      }
    }
  }

  getPath = () => {
    const { path } = this.props;
    if(path == 'documentation') {
      return 'instructor-documentation';
    }
    return path;
  }

  getFiltersValues = () => {
    const { user } = this.props;
    if(Object.keys(user).length !== 0 && this.state.checkStateFirstTime){
      if(instructorCertificationFilterSaved(user?.id)){
        let lastFilters = JSON.parse(localStorage.getItem('instructorCertificationFilter'));
        this.setState({
          savedInstructors: lastFilters.instructors || [],
          savedStatus: lastFilters.statuses || allStatuses,
          savedCategories: lastFilters.categories || [],
          selectedLocations: lastFilters.locations || [],
          selectedRegions: lastFilters.regions || [],
          checkStateFirstTime: false
        })
      }else{
        localStorage.removeItem('instructorCertificationFilter');
        this.setState({checkStateFirstTime: false})
      }
    }
  }

  renderSwitch = () => {
    switch(this.getPath()) {
      case 'instructor-documentation':
        return <InstructorCertifications {...this.props} savedInstructors={this.state.savedInstructors} savedStatus={this.state.savedStatus} savedCategories={this.state.savedCategories} selectedFilterLocations={this.state.selectedLocations} selectedFilterRegions={this.state.selectedRegions}/>
      case 'categories':
        return <Categories {...this.props} />
      case 'submit-documentation':
        return <SubmitCertification {...this.props} />
    }
  }

  render() {
    const { loaded, redirect, redirectUrl } = this.state;
    const { user, permissions, path } = this.props;

    return (
      <Fragment>
        {redirect && <Redirect to={redirectUrl} />}
        <div className={`w-full pt-20 md:pt-8 min-h-screen font-open-sans bg-gray-custom-50 px-8 pb-8`}>
          {path !== 'submit-documentation' &&
            <Fragment>
              <CertificationHeader path={this.getPath()}/>
              {loaded &&
                <Fragment>
                  <CertificationNavbar user={user} path={this.getPath()} permissions={permissions}/>
                  {this.renderSwitch()}
                </Fragment>
              }
              {!loaded &&
                <LoadingSpinner />
              }
            </Fragment>
          }
          {path === 'submit-documentation' &&
            <Fragment>
              <SubmitCertification {...this.props} path={this.getPath()} parentLoaded={loaded}/>
            </Fragment>
          }
        </div>
      </Fragment>
    )
  }
}

export default withRouter(CertificationsLayout);
import React, { Fragment } from "react";
import { EditButtonIcon, DeleteButtonIcon, RenewButtonIcon, ViewButtonIcon, AddButtonIcon } from "../../assets"
import ReactTooltip from "react-tooltip";
import LoadingIcon from '../../../subRequests/assets/loadingIcon';

export default function ActionButton(props) {
  const { setAction, data, type, tooltipId, tooltipMessage, disabledButton, pageType='documentation' } = props

  const renderSwitch = () => {
    if(disabledButton) {
      return <LoadingIcon classNames={'animate-spin h-4 w-4 text-black'}/>
    }
    switch(type) {
      case 'edit':
        return <EditButtonIcon classNames={`shrink-0 ${pageType == 'categories' ? 'old-icon text-gray-700 h-4 w-4' : 'text-white h-14 w-14'}`}/>
      case 'delete':
        return <DeleteButtonIcon classNames={'text-gray-700 h-4 w-4'} />
      case 'renew':
        return <RenewButtonIcon classNames={'text-white h-14 w-14 shrink-0'}/>
      case 'view':
        return <ViewButtonIcon classNames={'text-white h-14 w-14 shrink-0'}/>
      case 'add':
        return <AddButtonIcon classNames={'text-white h-14 w-14 shrink-0'}/>
    }
  }

  return(
    <Fragment>
      <button className={`${pageType == 'documentation' ? 'bg-gray-white w-38 h-8 rounded-2xl border border-gray-600' : 'bg-gray-100 w-8 h-8 rounded'} flex items-center justify-center ${disabledButton ? 'cursor-not-allowed opacity-70' : `${pageType == 'documentation' ? 'hover:bg-gray-100' : 'hover:bg-gray-200'}`}`} onClick={(e) => setAction(e, data)} data-tip data-for={tooltipId} disabled={disabledButton}>
        <div className={`${disabledButton ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}>
          {renderSwitch()}
        </div>
        <ReactTooltip className='max-w-xs whitespace-nowrap' id={tooltipId} place="top" effect="solid">
          {tooltipMessage}
        </ReactTooltip>
      </button>
    </Fragment>
  )
}
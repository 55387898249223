import React, { Fragment } from "react";

export default function HistoryCard({certHistory}) {

  return(
    <Fragment>
      <div>
        <div className="pb-2 text-xs font-semibold text-cool-gray-500">
          1 AUG, 2024 @  11:05 AM
        </div>
        <div className="pl-3">
          <div className="flex items-center">
            <div  className='w-3 h-3 rounded-full bg-gray-400 shrink-0'>
            </div>
            <div className="pl-5 font-semibold text-base">Uploaded File Name</div>
          </div>
          <div className="border-l border-gray-200 pl-4 ml-1.5 flex flex-col gap-y-1.5 pt-1.5 pb-10">
            <div className="text-base text-gray-500 flex items-center font-medium pl-2.5">
              Name: James Insurance
            </div>
            <div className="text-base text-gray-500 flex items-center font-medium pl-2.5">
              Expiration Date: -
            </div>
            <div className="text-base flex items-center font-medium pl-2.5 pt-1 gap-2">
              <div className="w-5 h-5 bg-gray-300 rounded-full">
                <img className="w-5 h-5 rounded-full" src={'/assets/business/user/avatar01.png'} />
              </div>
              <div className="font-semibold fs-15">
                by James Collins
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
import React, { Component, Fragment } from 'react'
import { setTitle } from './../../helpers'
import TooltipComponent from '../../settings-v4/account/tooltipComponent';
import { axiosGetRequest, axiosRequest } from '../../subRequests/helpers/helpers';
import { getRenderedSubPolicies, removeSkillsExceptAtIndex, timeframesToSave } from './helpers';
import SubPolicyComponent from './subPolicyComponent';
import AddTimeFrameComponent from './components/addTimeFrameComponent';
import AlertPopup from '../../messenger/pages/chatComponents/assets/alertPopup';

export default class SubPolicy extends Component {
  constructor(){
    super();
    this.state = {
      alertMessage: '',
      alertType: '',
      show: false,
      loaded: false,
      disableBtn: false,
      subbingTimeFrames: [],
      skillList: [],
      deletedTimeFrames: [],
    }
  }

  componentDidMount = () => {
    setTitle('account_info')
    this.getSubPolicyData()
  }

  getSubPolicyData = () => {
    let url = '/api/v3/sub_policy_data'
    axiosGetRequest(url).then((result) => {
      if(result.status === 200){
        let timeFrames = getRenderedSubPolicies(result?.subbing_timeframes)
        this.setState({
          subbingTimeFrames: timeFrames,
          skillList: result?.skills,
          loaded: true,
          disableBtn: false,
          deletedTimeFrames: []
        })
      }else{
        this.setState({
          alertMessage: result.error,
          alertType: 'danger',
          show: true
        })
      }
    })
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  handleUpdateSubPolicy = (e) => {
    const { deletedTimeFrames, subbingTimeFrames } = this.state
    this.setState({disableBtn: true})
    let params = {
      deleted_time_frames: deletedTimeFrames,
      subbing_timeframes: timeframesToSave(subbingTimeFrames),
    }
    let url = '/api/v3/sub_policy_update'
    axiosRequest(url, 'PATCH', params, 'data').then((result) => {
      if(result.status === 200){
        this.setState({
          alertMessage: result.message,
          alertType: 'success',
          show: true,
        })
        setTimeout(() => {
          this.setState({
            show: false,
            disableBtn: false,
            deletedTimeFrames: []
          })
          this.getSubPolicyData()
        }, 1500);
      }else{
        this.setState({
          alertMessage: result.error,
          alertType: 'danger',
          show: true
        })
        setTimeout(() => { 
          this.setState({
            show: false,
            disableBtn: false,
            deletedTimeFrames: []
          })
          this.getSubPolicyData()
        }, 3000);
      }
    })
  }

  setSubbingTimeFrames = (subbingTimeFrames) => {
    this.setState({
      subbingTimeFrames: subbingTimeFrames
    })
  }

  handleDeleteTimeFrame = (i) => {
    let timeFrameId = this.state.subbingTimeFrames[i]?.id
    if (timeFrameId && timeFrameId !== '') this.setState({deletedTimeFrames: [...this.state.deletedTimeFrames, timeFrameId]})
    this.setState({
      subbingTimeFrames: this.state.subbingTimeFrames.filter((item, index) => index !== i)
    })
  }

  closeAlert = (data) => {
    this.setState({show: data})
  }

  updateSubbingTimeFrames = (i, subbingTimeFrame) => {
    this.setState({subbingTimeFrames: [...this.state.subbingTimeFrames.slice(0, i), subbingTimeFrame, ...this.state.subbingTimeFrames.slice(i + 1)]})
  }

  updateSkillOnTimeFrames = (i, skills) => {
    let skillsData = removeSkillsExceptAtIndex(this.state.subbingTimeFrames, i, skills)
    this.setState({subbingTimeFrames: skillsData})
  }

  updateButtonDisabledCondition = () => {
    if(this.state.subbingTimeFrames?.some(s => s?.skillIds?.length == 0) || this.state.subbingTimeFrames?.length == 0) {
      return true
    }else{
      return false
    }
  }

  render() {
    const { alertMessage, alertType, show, loaded, disableBtn, subbingTimeFrames, skillList } = this.state
    const { user, business, loadingMessage, disabledPage } = this.props

    return (
      <Fragment>
        {show &&
          <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={this.closeAlert} />
        }
        {!loaded && 
          <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
            <p className="mb-1 text-center px-5">{loadingMessage}</p>
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        }
        {loaded &&
          <div className='bg-white rounded-md shadow mb-10 px-6 pb-10'>
            <header className='pt-8 pb-12 flex items-center gap-2'>
              <h1 className="text-2xl font-semibold leading-tight text-gray-900">Sub Request Timeframe</h1>
              <TooltipComponent componentId={'sub-request-timeframe-message'} classNames={'w-4 h-4'} tooltipMessage={'This section can only be edited by the Account Owner.'}/>
            </header>
            <div className='flex flex-col gap-4'>
              {subbingTimeFrames.map((timeFrame, i) => (
                <div key={i} className={`"flex items-center ${i !== 0 ? 'border-t border-gray-300 pt-6' : ''}`}>
                  <SubPolicyComponent timeFrame={timeFrame} i={i} disabledPage={disabledPage} handleDeleteTimeFrame={this.handleDeleteTimeFrame} updateSubbingTimeFrames={this.updateSubbingTimeFrames} skillList={skillList} updateSkillOnTimeFrames={this.updateSkillOnTimeFrames}/>
                </div>
              ))}
            </div>
            <AddTimeFrameComponent subbingTimeFrames={subbingTimeFrames} setSubbingTimeFrames={this.setSubbingTimeFrames} disabled={disableBtn || disabledPage}/>
            <div className='mt-4 flex justify-center'>
              <button className={`bg-dark-blue text-white inline-block px-6 py-2 border shadow rounded-md ${(disableBtn || disabledPage || this.updateButtonDisabledCondition()) ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`} onClick={(e) => this.handleUpdateSubPolicy(e)} disabled={disableBtn || disabledPage || this.updateButtonDisabledCondition()}>
                <div className="flex items-center justify-center gap-1 relative">
                  {disableBtn && 
                    <div className="flex items-center -left-5 justify-center absolute">
                      <svg className="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>
                  }
                  <div className='text-white text-base'>{`Update Timeframe${subbingTimeFrames?.length > 0 ? 's' : ''}`}</div>
                </div>
              </button>
            </div>
          </div>
        }
      </Fragment>
    )
  }
}

import React, { Fragment, useState, useEffect } from "react";
import AddCategory from './addCategory';
import AlertPopup from '../../../messenger/pages/chatComponents/assets/alertPopup';
import { axiosGetRequest } from '../../../subRequests/helpers/helpers';
import CategoryTable from './categoryTable';
import DeleteCategory from './deleteCategory';
import { categoryInitialState } from '../../helpers';
import CreateCategoryForm from './createCategoryForm';

export default function Categories(props) {
  const { user, permissions } = props
  const [ categories, setCategories ] = useState([])
  const [ dataLoaded, setDataLoaded ] = useState(false)
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')
  const pageSize = 30
  const [ page, setPage ] = useState(1)
  const [ totalCount, setTotalCount ] = useState(1)
  const [ changeCategory, setChangeCategory ] = useState({})
  const [ showDeleteCategory, setShowDeleteCategory ] = useState(false)
  const [ showCreateCategory, setShowCreateCategory ] = useState(false)

  useEffect(() => {
    fethCategories(1)
  }, []);

  const fethCategories = (page) => {
    let url = `/api/v3/documentation_categories?page_size=${pageSize}&page=${page}`
    axiosGetRequest(url).then(result => {
      if(result.status === 200){
        setCategories(result.certification_categories)
        setTotalCount(result.total)
        setDataLoaded(true)
      }else{
        setDataLoaded(true)
        setAlertMessage(result.error)
        setAlertType('danger')
        setShowAlert(true)
        setTimeout(()=>{
          setShowAlert(false)
        },3000)
      }
    })
  }

  const closeAlert = (data) => {
    setShowAlert(data)
  }

  const setPageNumber = (page) => {
    setPage(page)
    setDataLoaded(false)
    fethCategories(page)
  }

  const handleDeleteCategory = (e, category) => {
    setChangeCategory(category)
    setShowDeleteCategory(true)
  }

  const closeDeleteCategory = () => {
    setShowDeleteCategory(false)
    setChangeCategory({})
  }

  const handleAlert = (show, message, type) => {
    setShowAlert(show)
    setAlertMessage(message)
    setAlertType(type)
  }

  const refetchTheCategories = (newPage = null) => {
    setPageNumber(newPage ? newPage : page)
  }

  const closeCreateCategory = (data) => {
    setShowCreateCategory(data)
    setChangeCategory({})
  }

  const handleAddEditCategory = (e, category) => {
    setChangeCategory(category)
    setShowCreateCategory(true)
  }

  return(
    <Fragment>
      {showCreateCategory && 
        <CreateCategoryForm categoryDate={changeCategory} showCreateCategory={showCreateCategory} closeCreateCategory={closeCreateCategory} handleAlert={handleAlert} refetchTheCategories={refetchTheCategories} user={user}/>
      }
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      {showDeleteCategory &&
        <DeleteCategory showDeleteCategory={showDeleteCategory} changeCategory={changeCategory} closeDeleteCategory={closeDeleteCategory} handleAlert={handleAlert} setPageNumber={setPageNumber}/>
      }
      <div>
        {(permissions?.documentations?.write || user?.role_display === 'Netgym Employee') && <AddCategory setAddNewCategory={(e) => handleAddEditCategory(e, categoryInitialState())}/>}
        <CategoryTable 
          user={user}
          permissions={permissions}
          categories={categories}
          dataLoaded={dataLoaded}
          page={page}
          totalCount={totalCount}
          pageSize={pageSize}
          setPageNumber={setPageNumber}
          handleDeleteCategory={handleDeleteCategory}
          handleEditCategory={handleAddEditCategory}
        />
      </div>
    </Fragment>
  )
}
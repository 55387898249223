import React from 'react'
import AvailabilityEdit from './pages/edit'
import AvailabilitySearch from './pages/search'
import Availability from './pages'
import ResourseHub from './../resource_hub/index';
import Header from './header'
import Sidebar from './sidebar'
import Directory from '../directory'
import Footer from './footer'
import Dropzone from './../resource_hub/addfile';
import Publish from './../resource_hub/publish';
import ViewResourceDetail from './../resource_hub/viewResourceDetail';
import ViewFolderDetail from './../resource_hub/viewFolderDetail';
import Messenger from './../messenger';
import CreatePostForm from '../messenger/pages/createPostForm';
import ShowPost from '../messenger/pages/showPost';
import SettingsLayout from './../../components/settings-v4/layout'
import ConfigureLayout from './../configure/layout'
import Notifications from '../notifications';
import MyProfileLayout from '../myProfile/layout'
import AnalyticsLayout from '../analytics';
import SubRequestsLayout from '../subRequests/layout/index';
import SubRequestDetailsLayout from './../subRequestDetails/layout';
import { removeSubRequestFromLocalStorage } from '../subRequestCreate/helpers';
import AddUserForm from '../settings-v4/users/addUserForm';
import SubRequestCreateLayout from '../subRequestCreate/layout';
import UserContext from '../userComponents/userContext';
import { getSudomain, fetchAccounts, } from '../helpers';
import Cookies from 'js-cookie';
import AttendanceLayout from './../attendance/layout';
import CertificationsLayout from '../certifications/layout';

const navigation = [
  { name: 'Sub Board', href: '/my-requests', icon: "/assets/icon_sb_sub_board.png", current: false, alt_name: 'Sub Board', old_prop: false, drop_down: true },
  { name: 'Attendance', href: '#', icon: "/assets/icon_sb_attandance.png", current: false, alt_name: 'Attendance', old_prop: false, drop_down: false },
  { name: 'My Profile', href: '/my-profile', icon: "/assets/icon_sb_my_profile.png", current: false, alt_name: 'My Profile', old_prop: false, drop_down: false },
  { name: 'Resource Hub', href: '/resource_hub', icon: "/assets/icon_sb_resource.png", current: false, alt_name: 'Resource Hub', old_prop: false, drop_down: false },
  { name: 'Availability', href: '/availability', icon: "/assets/icon_sb_availability.png", current: false, alt_name: 'Availability', old_prop: false, drop_down: false },
  { name: 'Directory', href: '/directory', icon: "/assets/icon_sb_directory.png", current: false, alt_name: 'Directory', old_prop: false, drop_down: false },
  { name: 'Sub Schedule', href: '#', icon: "/assets/icon_sb_calendar.png", current: false, alt_name: 'Sub Schedule', old_prop: false, drop_down: false },
  { name: 'Insights', href: '#', icon: "/assets/icon_sb_insights.png", current: false, alt_name: 'Insights', old_prop: false, drop_down: false },
  { name: 'Messages', href: '/messenger', icon: "/assets/icon_sb_message.png", current: false, alt_name: 'Messages', old_prop: false, drop_down: false },
  { name: 'Settings', href: '/settings-v4', icon: "/assets/icon_sb_settings.png", current: false, alt_name: 'Settings', old_prop: false, drop_down: false },
  { name: 'Configure', href: '/configure', icon: "/assets/icon_sb_configure.png", current: false, alt_name: 'Configure', old_prop: false, drop_down: false },
  { name: 'Refer & Earn', href: '#', icon: "/assets/icon_sb_refer.png", current: false, alt_name: 'Refer & Earn', old_prop: true, drop_down: false },
  { name: 'Notification Logs', href: '/notification-audits', icon: "/assets/notification_log_icon.png", current: false, alt_name: 'Notification Logs', old_prop: false, drop_down: false },
  { name: 'Documentation', href: '/documentation', icon: "/assets/certifications-icon.svg", current: false, alt_name: 'Documentation', old_prop: false, drop_down: false }
]


export default class TailwindLayout extends React.Component {
  static contextType = UserContext;
  constructor(props){
    super(props);
    this.state = {
      sidebarOpen: false,
      insightsOpen: false,
      navigation: navigation,
      user: this.props.user,
      business: this.props.business,
      accounts: this.props.accounts,
      firstTime: true
    }
  }

  componentDidMount = () => {
    if(Object.keys(this.props.user).length !== 0){
      this.handleUpdateUser(this.props.user)
    }
    if(Object.keys(this.context.user).length !== 0){
      this.updateNavigation()
    }
    if(this.props.pageLoaded){
      if(this.state.firstTime){
        this.fetch()
        this.setState({
          firstTime: false
        })
      }
    }
  }

  fetch = () => {
    if(!this.context.fetchedAccounts){
      fetchAccounts(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())
      .then(response => response.json())
      .then(result => {
        if(result.status == 200){
          this.context.setFetchedAccounts(true)
          this.context.setAccounts(result.business_users)
          this.setState({
            accounts: result.business_users
          })
        }
      })
    }else{
      this.setState({
        accounts: this.context.accounts
      })
    }
  }

  updateNavigation = () => {
    let { navigation, business } = this.state
    let { user } = this.context
    let attendance = navigation.findIndex((obj => obj.name === 'Attendance'));
    navigation[attendance].count = user?.unread_attendance_count

    let msg = navigation.findIndex((obj => obj.name === 'Messages'));
    navigation[msg].count = user?.unread_count

    if(Object.keys(business).length !== 0){
      if(!business?.new_messenger){
        navigation[msg].old_prop = true
      }
    }

    this.setState({
      navigation: navigation
    })
  }

  getName(role){
    let name = ""
    switch(role){
      case 'business_admin':
      case 'account_owner':
        name = "admin"
        break;
      case 'regional_admin':
        name = 'regional_admin'
        break;
      case 'fitness_manager':
        name = 'fitness_manager'
        break;
      case 'instructor':
        name = 'instructor'
        break;
      case 'ops':
        name = 'ops'
        break;
    }
    return name
  }

  snakeCase = (string) => {
    return string?.replace(/\d+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join('_');
  }

  setSidebarOpen = () => {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen
    })
  }

  setInsightsOpen = () => {
    this.setState({
      insightsOpen: !this.state.insightsOpen
    })
  }

  setActive = (name) => {
    this.setState(prevState => ({
      navigation: prevState.navigation.map(
        obj => (obj.alt_name === name ? Object.assign(obj, { current: true }) : Object.assign(obj, { current: false }))
      )
    }));
  }

  setCurrent = (name, shallHide) => {
    if(shallHide){
      this.setState(prevState => ({
        navigation: prevState.navigation.map(
          obj => (obj.alt_name === 'Availability' ? Object.assign(obj, { current: false }) : obj)
        )
      }));
    }
    this.setState(prevState => ({
      navigation: prevState.navigation.map(
        obj => (obj.alt_name === name ? Object.assign(obj, { current: true }) : obj)
      )
    }));
  }

  handleCallUserApiToUpdate = () => {
    this.context.callUserAPI()
  }

  handleUpdateUser = (data) => {
    this.context.updateUserData(data)
  }

  renderSwitch(){
    const curent = this
    if (!['create-sub-request', 'create-sub-request-sub-select', 'create-sub-request-show'].includes(this.props.path)) removeSubRequestFromLocalStorage()
    switch(this.props.path){
      case 'index':
        return <Availability user = {curent.context.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} pageLoaded = {curent.props.pageLoaded} setCurrent={this.setActive}/>
      case 'edit':
        return <AvailabilityEdit user = {curent.context.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} pageLoaded = {curent.props.pageLoaded} setCurrent={this.setActive}/>
      case 'search':
        return <AvailabilitySearch setInsightsOpen={this.setInsightsOpen} user = {curent.context.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setCurrent} pageLoaded = {curent.props.pageLoaded} />
      case 'resource_hub':
        return <ResourseHub user = {curent.context.user} business = {curent.props.business} permissions={curent.props.permissions} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} pageLoaded = {curent.props.pageLoaded}/>
      case 'add-file':
        return <Dropzone user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} pageLoaded = {curent.props.pageLoaded}/>
      case 'publish':
        return <Publish user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} pageLoaded = {curent.props.pageLoaded}/>
      case 'view-resource-detail':
        return <ViewResourceDetail user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} pageLoaded = {curent.props.pageLoaded}/>
      case 'view-folder-detail':
        return <ViewFolderDetail user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} pageLoaded = {curent.props.pageLoaded}/>
      case 'create-post':
        return <CreatePostForm user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} pageLoaded={curent.props.pageLoaded}/>
      case 'show-post':
        return <ShowPost user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} path = {this.props.path} pageLoaded={curent.props.pageLoaded}/>
      case 'messenger': case 'compliance': case 'announcement': case 'event': case 'permanent_class_opening': case 'archive': case 'chat-room':
        return <Messenger user = {curent.context.user} permissions={curent.props.permissions} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} path = {this.props.path} pageLoaded={curent.props.pageLoaded}/>
      case 'directory':
        return <Directory user = {curent.context.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} pageLoaded = {curent.props.pageLoaded}/>
      case 'users':
        return <SettingsLayout user = {curent.context.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} permissions={curent.props.permissions} path={this.props.path} pageLoaded={this.props.pageLoaded} setLoaded={this.handleCallUserApiToUpdate}/>
      case 'add-users':
        return <AddUserForm user = {curent.context.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} permissions={curent.props.permissions}/>
      case 'locations': case 'classes': case 'regions': case 'skills': case 'permissions': case 'permissionsForm': case 'account':
        return <SettingsLayout user = {curent.context.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} permissions={curent.props.permissions} path={this.props.path} pageLoaded={this.props.pageLoaded}/>
      case 'netgym-settings': case 'mbo-details': case 'abc-details': case 'mariana-details': case 'crunch-details': case 'fisikal-details': case 'club-ready-details':
        return <ConfigureLayout user = {curent.context.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} permissions={curent.props.permissions} path={this.props.path} pageLoaded={this.props.pageLoaded}/>
      case 'notification-audits':
        return <Notifications user = {curent.context.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} pageLoaded={this.props.pageLoaded}/>
      case 'my-profile': case 'information': case 'notifications': case 'my-documentation':
        return <MyProfileLayout user = {curent.context.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} pageLoaded={this.props.pageLoaded} handleUpdateUser={this.handleUpdateUser} getCurrentUser={this.handleCallUserApiToUpdate} path={this.props.path}/>
      case 'analytics':
        return <AnalyticsLayout user = {curent.context.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setActive={this.setActive} pageLoaded={this.props.pageLoaded} setInsightsOpen={this.setInsightsOpen} setCurrent={this.setCurrent} />
      case 'all-requests': case 'my-requests':
        return <SubRequestsLayout user = {curent.context.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setActive={this.setActive} pageLoaded={this.props.pageLoaded} setInsightsOpen={this.setInsightsOpen} setCurrent={this.setCurrent} path={this.props.path} permissions={curent.props.permissions}/>
      case 'sub-request-details':
        return <SubRequestDetailsLayout user = {curent.context.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setActive={this.setActive} pageLoaded={this.props.pageLoaded} setInsightsOpen={this.setInsightsOpen} setCurrent={this.setCurrent} path={this.props.path} permissions={curent.props.permissions}/>
      case 'create-sub-request': case 'create-sub-request-sub-select': case 'create-sub-request-show': case 'edit-sub-request':
        return <SubRequestCreateLayout user={this.context.user} business={curent.props.business} loadingMessage={curent.props.loadingMessage} setActive={this.setActive} pageLoaded={this.props.pageLoaded} path={this.props.path} permissions={curent.props.permissions}/>
      case 'attendance': case 'my-classes': case 'reports':
        return <AttendanceLayout user = {curent.props.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setCurrent={this.setActive} pageLoaded={this.props.pageLoaded} setInsightsOpen={this.setInsightsOpen} path={this.props.path}/>
      case 'documentation': case 'instructor-documentation': case 'my-documentation': case 'categories': case 'submit-documentation':
        return <CertificationsLayout user = {curent.props.user} business = {curent.props.business} loadingMessage={curent.props.loadingMessage} setActive={this.setActive} pageLoaded={this.props.pageLoaded} path={this.props.path} permissions={curent.props.permissions} setCurrent={this.setCurrent}/>
    }
  }

  render() {
    const { user, business, accounts } = this.state

    return (
      <React.Fragment>
        <div>
          <Header sidebarOpen = {this.state.sidebarOpen} setSidebarOpen={this.setSidebarOpen} user={this.context.user} permissions = {this.props.permissions} business={this.props.business} pageLoaded={this.props.pageLoaded} accounts={accounts} />
          <div className={`flex overflow-hidden ${['all-requests', 'my-requests', 'sub-request-details', 'create-sub-request', 'create-sub-request-sub-select', 'create-sub-request-show', 'attendance', 'edit-sub-request'].includes(this.props.path) ? 'bg-gray-custom-50' : 'bg-gray-100'} ${location.pathname.split('/')[1] === 'availability' && location.pathname.split('/')[2] === 'search' ? 'layout-height' : ''}`}>
            <Sidebar insightsOpen={this.state.insightsOpen} setInsightsOpen={this.setInsightsOpen} sidebarOpen = {this.state.sidebarOpen} setSidebarOpen={this.setSidebarOpen} user={this.context.user} permissions = {this.props.permissions} business={this.props.business} navigation={this.state.navigation} setCurrent={this.setCurrent} accounts={accounts}/>
            {this.renderSwitch()}
          </div>
          <Footer />
        </div>

      </React.Fragment>
    )
  }
}
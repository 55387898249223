import React, { Fragment } from "react";

export default function NumberInputComponent({name, value, min, max, onChange, onKeyDown, onBlur, disabled}) {
  return(
    <Fragment>
      <input
        type="number"
        className={`inline mr-3 mt-1 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 block w-20 h-9 sm:text-sm border-gray-300 rounded-md text-center ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}
        name={name}
        value={value}
        min={min}
        max={max}
        onChange={(e) => onChange(e)}
        onKeyDown={(e) => onKeyDown(e)}
        onBlur={(e) => onBlur(e)}
        disabled={disabled}
      />
    </Fragment>
  )
}
import React, { Fragment, useState } from "react";
import { SendIcon } from "./../../../../subRequests/assets";
import { axiosGetRequest } from '../../../../subRequests/helpers/helpers';
import ReactTooltip from "react-tooltip";

export default function ResendInviteAllButtonComponent(props){
  const { eventData, updateEventData, handleAlert, eventPreview, classNames } = props
  const [ disableResendButton, setDisableResendButton ] = useState(false)

  const handleResendInvite = () => {
    if((eventData?.pending_subs?.length == 0 || (eventData?.pending_subs?.length > 0 && eventData?.resent_invite)) || disableResendButton){
      return
    }
    setDisableResendButton(true)
    let url = `/api/v2/resend_invites?id=${eventData?.id}&inst_id=&gql_event_details=true&preview=${eventPreview}`
    axiosGetRequest(url).then((result) => {
      if(Object.keys(result).includes('success')){
        handleAlert(true, result?.success, 'success');
        setTimeout(() => { 
          updateEventData(result?.event);
        }, 3000)
      }else{
        setDisableResendButton(false);
        handleAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleAlert(false, '', ''); 
      }, 3000)
    })
  }
  
  return(
    <Fragment>
      {!eventPreview && 
        <button className={`hidden lg:flex items-center absolute gap-2 cursor-pointer right-12 topc-22 ${((eventData?.pending_subs?.length == 0 || (eventData?.pending_subs?.length > 0 && eventData?.resent_invite)) || disableResendButton || eventData?.deleted) ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={(eventData?.pending_subs?.length == 0 || (eventData?.pending_subs?.length > 0 && eventData?.resent_invite)) || disableResendButton || eventData?.deleted} onClick={() => handleResendInvite()} data-tip data-for={`${eventData?.id}_resend_invite_all_tooltip`}>
          <SendIcon classNames={'w-4 h-4 transform text-yellow-500 -rotate-45 mb-0.5 ml-0.5'}/>
          <div className="text-sm font-semibold text-yellow-500">Resend invite to all</div>
        </button>
      }
      <button className={`flex ${eventPreview ? (classNames == 'scroll-added' ? 'right-6 top-1' : 'right-2 top-1') : 'lg:hidden right-0 top-2'} cursor-pointer absolute items-center justify-center rounded-full h-6 w-6 gap-2 border-1-c border-yellow-500 text-yellow-500 ${((eventData?.pending_subs?.length == 0 || (eventData?.pending_subs?.length > 0 && eventData?.resent_invite)) || disableResendButton || eventData?.deleted) ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={(eventData?.pending_subs?.length == 0 || (eventData?.pending_subs?.length > 0 && eventData?.resent_invite)) || disableResendButton || eventData?.deleted} onClick={() => handleResendInvite()} data-tip data-for={`${eventData?.id}_resend_invite_all_tooltip`}>
        <SendIcon classNames={'w-3.5 h-3.5 transform text-yellow-500 -rotate-45 mb-0.5 ml-0.5'}/>
      </button>
      {(eventData?.resent_invite && eventData?.resent_invite_left && eventData?.resent_invite_left !== '') &&
        <ReactTooltip 
          className='max-w-xs text-center' 
          id={`${eventData?.id}_resend_invite_all_tooltip`} 
          place={eventPreview ? "left" : "top"} 
          effect="solid"
        >
          You may send another invite in {eventData?.resent_invite_left}
        </ReactTooltip>
      }
    </Fragment>
  )
}
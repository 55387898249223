import React, { Fragment } from "react";
import ApproveButtonIcon from './../../../assets/approveButtonIcon'
import DeclineButtonIcon from './../../../assets/declineButtonIcon'
import { performMGRAction } from './../../../helpers/helpers'

export default function MgrEventButtonsComponent(props){
  const { user, eventId, handleSetAlert, handleCloseAndReloadData, type, calendarEvent, handleUpdateCalendarEvent, deletedEvent } = props

  const handlePerformAction = (action) => {
    let url = `/api/v3/business_events/${eventId}/manager_response`
    let params = { 
      fm_id: user?.id, 
      status: action,
      preview: type !== 'detail',
      calendar_event: calendarEvent
    }
    performMGRAction(url, params).then((result) => {
      if(Object.keys(result).includes('status') && result?.status === 200){
        handleSetAlert(true, 'Status Updated', 'success')
        if(result?.event?.length == 0){
          if(type !== 'detail'){
            handleCloseAndReloadData('deleted')
            if(calendarEvent) handleUpdateCalendarEvent('', `deleted_${eventId}`)
          }else{
            window.location.href = '/'
          }
        }else{
          handleCloseAndReloadData(result?.event)
          if(calendarEvent) handleUpdateCalendarEvent(result?.calendar_event, 'updated')
        }
      }else{
        handleSetAlert(true, result?.error, 'danger')
      }
      setTimeout((
        handleSetAlert(false, '', '')
      ), 3000)
    })
  }

  return (
    <Fragment>
      <button className={`flex items-center justify-center border border-green-600 text-green-600 rounded-2xl font-semibold w-full ${type == 'list' ? 'tc-11' : type === 'preview' ? 'text-xs h-8' :'text-sm text-sm h-11 gap-2.5'} ${deletedEvent ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handlePerformAction(true)} disabled={deletedEvent}>
        <ApproveButtonIcon classNames={`${type == 'list' ? 'w-3.5 h-3.5' : type === 'preview' ? 'w-5 h-5' :'w-4 h-4'}`}/>
        Approve
      </button>
      <button className={`flex items-center justify-center border border-red-600 text-red-600 rounded-2xl font-semibold w-full ${type == 'list' ? 'tc-11' : type === 'preview' ? 'text-xs h-8' : 'text-sm text-sm h-11 gap-2.5'} ${deletedEvent ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handlePerformAction(false)} disabled={deletedEvent}>
        <DeclineButtonIcon classNames={`text-red-600 ${type == 'list' ? 'w-3.5 h-3.5' : type === 'preview' ? 'w-5 h-5' :'w-4 h-4'}`}/>
        Decline
      </button>
    </Fragment>
  )
}